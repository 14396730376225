.distribute-to-npo-page {
    .npo-search-bar-component.admin-form-custom-1 {
        .search-admin-inner-icon {
            padding-right: 10px;
            border: 1px solid #cdd6dd;
            border-left: none
        }
        .border-right-none {
            border-right: none;
        }
    }
    .available-points {
        font-size: 32px;
        font-weight: 400;
        margin-left: 20px;
    }
    .point-search-bar_container {
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 3vh;
        padding-bottom: 3vh;
    }
    .available-points_cover {
        margin-right: 5vw;
    }
    .npo_categoy_cover {
        margin-right: 6vw;
    }
}
.mt-30px {
    margin-top: 30px;
}
.max-height-325 {
    max-height: 325px;
}

@media (min-width: 1237px) and (max-width: 1345px) {
    .distribute-to-npo-page {
        .available-points_cover {
            margin-right: 20px;
        }
        .npo_categoy_cover {
            margin-right: 20px;
        }
    }
}

@media all and (max-width: 1056px) {
    .distribute-to-npo-page {
        .npo_categoy_cover {
            width: 100%;
        }
    }
}