.select-npo_and_company-page {
  padding-top: calc(64px - 46px);

  .form-search-npo {
    @media all and (min-width: 1400px) and (max-width: 1500px) {
      .col-xxl-ct-8 {
        width: 66.66666667%;
      }

      .col-xxl-ct-4 {
        width: 33.33333333%;
      }
    }

    .mt-mobile-form {
      @media all and (min-width: 575px) and (max-width: 767px) {
        margin-top: 20px;
      }
    }
  }

  .card-galaxy-body {
		.avatar {
			.favorite-top-125 {
				top: 125px;
			}
		}

    .item-sub-title {
      line-height: inherit;
    }
	}
}

.confirm-create-project, .confirm-edit-project {
  padding-top: 48px;
  padding-bottom: 80px;

  .confirm-project__note {
		color: #FF0000;
    margin-bottom: 30px;

    @media all and (max-width: 767px) {
      margin-bottom: 16px;
    }
	}

  @media (max-width: 767px) {
    padding-bottom: 48px;
  }
}