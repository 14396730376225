// container-mw-1500
.client-container {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  padding-right: $container-1500--px;
  padding-left: $container-1500--px;
}

@media (min-width: 1500px) {
  .client-container {
    max-width: 1500px;
    padding: 0;
  }
}
@media (min-width: 1025px) and (max-width: 1564px) {
  .client-container {
    padding-left: 32px;
    padding-right: 32px;
  }
}

// end container-mw-1500


// container-mw-1256
.client-cpanel-container {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  padding-right: $container-1500--px;
  padding-left: $container-1500--px;
}

@media (min-width: 1500px) {
  .client-cpanel-container {
    max-width: 1256px;
  }
}

@media (max-width: 1500px) {
  .client-cpanel-container {
    max-width: 900px;
  }
}

@media (min-width: 1025px) and (max-width: 1620px) {
  .client-cpanel-container {
    padding-left: 32px;
    padding-right: 32px;
  }
}

@media (min-width: 1620px) {
  .client-cpanel-container {
    padding: 0;
  }
}

// end container-mw-1256