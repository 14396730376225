.admin-btn-green {
  background-color: #54DBC2;
  border-color: #54DBC2;
  border-radius: 50rem;
  font-size: 16px;
  font-weight: bold;
  color: white;
  padding: 11px 21px;
  position: relative;
  text-align: center;
  transition: all .3s;

  svg,
  i {
    margin-right: 8px;
  }

  &.btn:active {
    background-color: #54DBC2;
    border-color: #54DBC2;
    color: white;
    opacity: 0.7;
  }

  &:hover {
    background-color: #2DE1C0;
    border-color: #54DBC2;
    color: white;
  }

  &:disabled {
    color: white;
    background-color: #C8C8C8;
    border-color: #C8C8C8 !important;
    pointer-events: none;
  }

  &.btn-login {
    padding-left: 72px;
    padding-right: 72px;
  }
}

.admin-btn-light {
  background-color: #C8C8C8;
  border-color: #C8C8C8;
  border-radius: 50rem;
  font-size: 16px;
  font-weight: bold;
  color: white;
  padding: 11px 21px;
  position: relative;
  text-align: center;
  transition: all .3s;
}

.admin-btn-pink {
  background-color: #f9f2fb;
  border-radius: 50rem;
  font-size: 16px;
  color: #000;
  padding: 5px 20px;
  position: relative;
  text-align: center;
  transition: all .3s;
}