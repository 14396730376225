.invitation-page {
    padding: 0 45px 0 15px;
    width: 100%;

    @media all and (max-width: 575px) {
        padding: 0px;
        .box-style-1__border {
            &::before{
                position: unset;
                content: none;
                width: 100%;
                height: 100%;
                border-radius: 8px;
                background: unset;
                right: 0px;
                bottom: 0px;
            }
            &::after {
                position: unset;
                content: none;
                width: 100%;
                height: 100%;
                border-radius: 8px;
                background: unset;
                right: 0px;
                bottom: 0px;
            }
        }
    }

    .invitation-content {
        .title-guide {
            font-size: 24px;
            font-weight: bold;
            color: #242424;
            .text-price {
                color : #e93333;
                font-size: 32px;
            }
            @media all and (max-width: 768px) {
                font-size: 18px;
                font-weight: normal;
                .text-price {
                    font-size: 24px;
                }
            }
        }
        .guide-item {
            margin-top: 40px;
            &__first {
                padding-right: 20px;
                @media all and (max-width: 993px) {
                    padding-right: 0px;
                    margin-bottom: 0px;;
                }
            }
            .box-style-1__border {
                padding: 25px 20px 25px 20px;
                .guide-text {
                    margin-left: 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    height: 100%;
                    p {
                        &:first-child {
                            color: #31CDB0;
                            font-size: 18px;
                            font-weight: bold;
                            margin-top: 1rem;
                        }
                        @media all and (max-width: 575px) {
                            &:first-child {
                                font-size: 16px;
                            }
                            &:last-child {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
            .guide-box {
                padding: 0px 10px 0px 10px;
                .shape-guide {
                    height: 99px;
                    width: 99px;
                    @media all and (max-width: 768px) {
                        height: 66px;
                        width: 66px;
                    }
                }
            }
            @media all and (max-width: 768px) {
                margin-top: 25px;
            }
        }

        .code-content {
            margin-top: 45px;
            background-color: #F4F9F8;
            border-radius: 10px;
            p {
                margin-bottom: 0px;
            }
            
            .group-content {
                padding: 25px 25px 25px 25px;
                .show-code {
                    padding-left: 20px;
                    padding-right: 20px;
                    padding-top: 5px;
                    width: 329px;
                    height: 65px;
                    background-color: #ffffff;
                    border: 1px solid #919191;
                    border-radius: 5px;
                    cursor: pointer;
                }
                .btn-copy_code {
                    background-color: #ffffff;
                    font-size: 14px;
                    border: none;
                    color: #919191;
                }
                .input-code {
                    font-size: 34px;
                    font-weight: bold;
                    color: #D869DB;
                }
                .label-code {
                    font-size: 24px;
                    font-weight: bold;
                    color: #2C3331;
                    margin-right: 40px;
                }
                .label-sns {
                    margin-right: 40px;
                }

                .group-icon-sns {
                    .icon-sns {
                        margin-right: 20px;

                        &:last-child {
                            display: none;

                            @media all and (max-width: 1200px) {
                                display: unset;
                            }
                        }
                    }
                }
                
                @media all and (max-width: 768px) {
                    padding: 0px 10px 0px 10px;
                    width: 100%;
                    .show-code {
                        width: 100% !important;
                        padding-left: 0px;
                        padding-right: 0px;
                    }
                    .label-code {
                        margin-top: 25px;
                        margin-bottom: 25px;
                        margin-right: 0px;
                        text-align: center;
                        @media all and (max-width: 575px) {
                            font-size: 18px;
                        }
                    }
                    .label-sns {
                        margin-top: 25px;
                        margin-bottom: 25px;
                        margin-right: 0px;
                        text-align: center;
                    }
                    .group-icon-sns {
                        justify-content: center;
                        margin-bottom: 25px;
                    }
                    .input-code {
                        margin-left: 20px;
                    }
                }
            }
        }

        .invitation-description {
            margin-top: 45px;
            .title {
                color: #4F4F4F;
                font-weight: bold;
                font-size: 24px;
                margin-bottom: 20px;
                border-bottom: 2px solid #C8C8C8;
            }
            .note {
                color: #242424;
            }

            @media all and (max-width: 575px) {
                .title {
                    font-size: 18px;
                }
                .note {
                    font-size: 14px;
                }
            }
        }
    }
   
}