.pagination-style {
  float: right;
  text-align: right;

  .page-item.active a {
    background: #004bb1;
  }
}

.initial-auth__page {
  padding-top: 64px;
  padding-bottom: 104px;


  @media (min-width: 320px) and (max-width: 767px) {
    padding-top: 20px;
    padding-bottom: 60px;
  }

  .auth__card {
    width: 623px;
    padding-top: 48px;
    padding-bottom: 64px;
    padding-left: 16px;
    padding-right: 16px;

    @media (min-width: 320px) and (max-width: 767px) {
      width: 100%;
    }

    .auth__title {
      margin-bottom: 48px;
      text-align: center;
      font-size: 32px;

      @media (min-width: 320px) and (max-width: 767px) {
				margin-bottom: 30px;
        font-size: 26px;
      }
    }
  }

  .alertMessage {
    font-size: 11px;
  }

  .sns-button-list {
    width: 425px;
    margin-bottom: 29px;

    @media (max-width: 623px) {
      width: 100%;
    }
  }

  .continueTwitterBtn,
  .continueFacebookBtn,
  .continueInstaBtn,
  .continueGoogleBtn {
    box-shadow: 0px 3px 6px #307D6E3D;
    border-radius: 8px;
    text-align: center;
    font-weight: 600;
    color: #2C3331;
    padding: 12px 45px;
    position: relative;
    border: 1px solid #ffff;
    font-size: 18px;
    text-decoration: none;
    font-family: 'Noto Sans JP';

    @media all and (max-width: 991px) {
      font-size: 16px;
    }

    img {
      position: absolute;
      width: 35px;
      height: 35px;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
    }

    &:hover {
      font-weight: 800;
    }

    &:active {
      font-weight: 800;
      color: #CCCCCC;
      border-color: white;
      opacity: 0.6;
    }

    &.disabled {
      background-color: #C8C8C8;
      border-color: #C8C8C8;
      color: white;
    }
  }

  .divider {
    -webkit-box-align: center;
    -webkit-box-pack: justify;
    max-width: 425px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;

    @media (max-width: 623px) {
      width: 100%;
    }

    .divider-line {
      margin: 25px 0;
      height: 2px;
      border: none;
      flex: 1 1 0%;
      opacity: 1;
    }

    .divider-line:first-child {
      background-color: #CDF1EA;
      margin-right: 15px;
    }

    .divider-line:last-child {
      background-color: #F1DEF4;
      margin-left: 15px;
    }

    .trfeet {
      background-color: rgb(255, 255, 255);
      color: #919191;
      line-height: 18px;
      padding: 0px 10px;

      @media all and (max-width: 991px) {
        font-size: 14px;
      }
    }
  }

  .auth-form {
    text-align: center;
    margin: auto;
    width: 425px;

    @media (max-width: 623px) {
      width: 100%;
    }

    .form-group {
      margin-bottom: 32px;
    }

    label {
      color: #919191;
    }

    .form-control {
      width: 100%;
      height: 50px;
      border: 1px solid #919191;
      border-radius: 8px;

      &::-webkit-input-placeholder {
        color: #c8c8c8;
      }

      &:-ms-input-placeholder {
        color: #c8c8c8;
      }

      &::placeholder {
        color: #c8c8c8;
      }

      @media all and (max-width: 991px) {
        font-size: 14px;

        &::-webkit-input-placeholder {
          font-size: 14px;
        }

        &:-ms-input-placeholder {
          font-size: 14px;
        }

        &::placeholder {
          font-size: 14px;
        }
      }

      &:focus {
        box-shadow: none;
        border-color: #51D6BC;
      }

      &.is-invalid {
        border-color: #D869DB;
        background-color: #F1DEF4;
        box-shadow: none;
        background-image: none;
      }
    }

    .input-group-password {
      .form-control {
        width: auto;
        border-right: none;
      }

      .input-group-text {
        background-color: white;
        border-color: #919191;
        
        &:hover,
        &:active {
          color: #51D6BC;
        }
      }

      .form-control:focus ~ .input-group-text {
        border-color: #51D6BC;
      }

      .form-control.is-invalid ~ .input-group-text {
        border-color: #D869DB;
        background-color: #F1DEF4;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }

    .invalid-feedback {
      color: #D869DB;
      text-align: left;
      position: relative;
      padding-left: 16px;

      &:before {
        content: "";
        position: absolute;
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill=%27%23D869DB%27 class="bi bi-x-circle-fill" viewBox="0 0 16 16"> <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/> </svg>');
        width: 15px;
        height: 15px;
        left: 0;
        top: 2px;
      }
    }
  }

  .forget-password-link {
    margin-top: 26px;
    color: #c8c8c8;
    font-weight: bold;
    display: block;
    transition: all .3s;

    &:hover {
      color: #484848;
    }
  }
}

.signup-confirm-page {
  padding-bottom: 104px;
  @media (max-width: 767px) {
    .title-form {
      font-size: 22px;
    }
  }
}

.signup-complete-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - (112px + 113px + 113px));
  
  .registration-compelete-email-card {
    width: 1000px;
    max-width: 100%;
  }

  .signup-npo-complete__title {
    font-size: 32px;
    margin-bottom: 20px;
  }

  .wrap-text {
    margin-bottom: 50px;
    font-size: 16px;
  }
  .btn-line {
    background: #00B900;
    color: #fff;
    font-size: 32px;
    width: 400px;
    max-width: 100%;
    &:active, &:hover {
      background: #00B900;
      color: #fff;
    }
  }
  @media (max-width: 767px) {
    .signup-npo-complete__title {
      font-size: 24px;
    }
    .btn-line {
      font-size: 24px;
    }
  }
}

.client-user-detail {
  .influencer-info__container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 40px;
    padding-bottom: 42px;
    position: relative;
    padding-left: 8px;
    padding-right: 8px;

    @media all and (min-width: 1500px) {
      max-width: 896px;
      .col-ct-4 {
        width: 36%;
      }
      .col-ct-8 {
        width: 64%;
      }
    }

    @media all and (max-width: 1500px) {
      padding-left: 8px;
      padding-right: 8px;
    }

    @media (min-width: 320px) and (max-width: 767px) {
      padding-top: 28px;
      padding-bottom: 24px;
    }
  }

  .go-back-page__box {
    position: absolute;
    left: 0;
    transform: translate(-100%, 0);
    z-index: 99;

    @media all and (min-width: 768px) and (max-width: 1500px) {
      position: inherit;
    }
    @media (min-width: 320px) and (max-width: 1500px) {
      transform: translate(0, 0);
    }

    .go-back__btn {
      position: relative;
      font-size: 16px;
      color: #2c3331;
      font-weight: 700;
      padding-left: 45px;
      transition: all 0.3s;
      text-decoration: none;

      &::before {
        background-image: $icon-carret;
        background-repeat: no-repeat;
        background-size: 100%;
        content: '';
        position: absolute;
        left: 0;
        top: -15%;
        width: 42px;
        height: 42px;
        transform: rotate(180deg);
      }
    }
  }

  .rating-stars {
    svg {
      width: 22px;
      height: 22px;
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .teddybear-rating {
    img {
      width: 30px;
      margin-right: 8px;
      
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .tiktok-info__username {
    font: normal normal normal 16px/12px Noto Sans CJK JP;
  }

  .influencer-info__box {
    position: relative;
    & > .row > div {
      @media (max-width: 767px) {
        &:first-child {
          padding-right: 0;
        }
        &:last-child {
          padding-left: 0;
        }
      }
    }

    .influencer-info__item {
      border-top: 1px solid #C8C8C8;
      padding: 16px 0;
      display: flex;

      @media (min-width: 320px) and (max-width: 767px) {
        flex-direction: column;
      }

      .influencer-info__name {
        width: 140px;

        @media (min-width: 320px) and (max-width: 767px) {
          width: 100%;
          margin-bottom: 8px;
          color: #919191;
        }
      }

      .influencer-info__value {
        width: calc(100% - 140px);

        @media (min-width: 320px) and (max-width: 767px) {
          width: 100%;
          display: flex;
          flex-flow: wrap;
        }
      }
    }
  }

  .influencer-pr-box {
    margin-bottom: 28px;
    border-top: 1px solid #C8C8C8;
    border-bottom: 1px solid #C8C8C8;
    padding: 16px 4px;

    .influencer-label {
      width: 100px;

      @media (min-width: 320px) and (max-width: 767px) {
        width: 100%;
        margin-bottom: 8px;
        color: #919191;
      }
    }

    .influencer-value {
      width: calc(100% - 100px);

      @media (min-width: 320px) and (max-width: 767px) {
        width: 100%;
      }
    }
  }

  .influencer-reward-amount-box {
    background-color: #f4f9f8;
    border-radius: 8px;
    margin-bottom: 28px;
    padding: 16px 4px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    @media (min-width: 320px) and (max-width: 767px) {
      flex-direction: column;
      margin-bottom: 0;
      padding: 15px 0 15px;
    }

    .amount__label {
      font-size: 18px;
      margin-right: 50px;

      @media (min-width: 320px) and (max-width: 767px) {
        margin-right: 0;
        font-size: 14px;
      }
    }

    .amount__value {
      font-size: 32px;

      @media (min-width: 320px) and (max-width: 767px) {
        font-size: 28px;
        line-height: 40px;
      }
    }

    small {
      font-size: 22px;
      margin-left: 15px;

      @media (max-width: 767px) {
        margin-left: 3px;
        font-size: 14px;
      }
    }
  }

  .tiktok-info__box {
    @media (min-width: 320px) and (max-width: 767px) {
      margin-bottom: 0;
    }

    .shortcut-item {
      height: 140px;
      background: #F9F2FB 0% 0% no-repeat padding-box;
      border-radius: 8px;
      color: #2C3331;

      .item-number {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }

  .btn-influencer-select {
    padding: 20px 0;
    width: 100%;
    font-size: 22px;

    @media (min-width: 320px) and (max-width: 767px) {
      position: fixed;
      z-index: 99;
      left: 50%;
      bottom: 32px;
      width: 287px;
      transform: translate(-50%, 0);
    }
  }

  .item-avatar {
    max-width: 300px;
    max-height: 300px;

    @media all and (min-width: 1501px) {
      span {
        button {
          img {
            width: 80px;
            height: 80px;
          }
        }
      }
    }

    @media (min-width: 320px) and (max-width: 1500px) {
      max-width: 182px;
      max-height: 182px;
    }

    @media all and (min-width: 769px) {
      width: 100%;
      height: 100%;
    }

    @media (max-width: 768px) {
      width: 182px;
      height: 182px;
    }

    img.rounded-circle {
      width: 100%;
      height: 100%;
    }
  }
  .influencer-info__flex-reverse {
    .note {
      font-size: 14px;
    }
  }
}

.user-rank-area {
  width: fit-content;
  height: 50px;
  line-height: 50px;
  &.ml-40{
    margin-left: 40px;
  }
  span {
    display: inline-block;
    vertical-align: middle;
  }
  .icon {
    text-align: left;
    z-index: 0;
    position: relative;
    .icon-svg.user-rank-svg {
      width: 57px;
      height: 42px;
    }
  }   
  .verify-color {
    border-radius: 20px;
    border: 2px solid;
    padding: 2px 10px;
    border-color: #f1def4;
    background-color: #f9f2fb;
    color: #e861e1;
    font-weight: bold;
    font-family: 'Noto Sans JP';
    margin-left: -10px;
    margin-top: -10px;
    position: relative;
    line-height: 18px;
    font-size: 14px;
  }
  .teddybear-rating {
    display: inline-block;
    margin-left: 25px;
    img {
      width: 30px;
      margin-right: 8px;
      margin-top: -15px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.register-link {
	margin-top: 26px;
	color: #c8c8c8;
	font-weight: bold;
	display: block;
	transition: all .3s;

	&:hover {
		color: #484848;
	}
}