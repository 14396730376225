.project-detail {
  .light-pink {
    background: #f9f2fb 0% 0% no-repeat padding-box;
    height: 100%;

    .flex-fill:first-child {
      width: 50%;
    }
  }

  .hashtags {
    span {
      border-radius: 20px;
      margin: 0 10px 10px 0;
      border: 1px solid #cdd6dd;
    }
  }
}