.client-tabs {
  &.nav-tabs {
    --bs-nav-tabs-border-width: 0;
    padding-bottom: 10px;
    border-bottom: 4px solid #e4e4e4;
    .nav-item {
      margin-right: 100px;
      font-family: 'Noto Sans JP';
      
      .nav-link {
        font-size: 16px;
        letter-spacing: 0px;
        color: #4f4f4f;
        padding: 0;
        margin-right: 47px;
				@media all and (max-width: 768px) {
          margin-right: 30px;
        }
        @media all and (max-width: 575px) {
          margin-right: 25px;
        }
        // @media all and (max-width: 320px) {
        //   margin-right: 15px;
        // }
        opacity: 0.5;
        transition: all .3s;
        &:hover{
          opacity: 1;
        }
        &.active {
          position: relative;
          opacity: 1;
          &:after {
            position: absolute;
            content: '';
            left: 0;
            right: 0;
            bottom: -14px;
            height: 4px;
            background: transparent linear-gradient(68deg, #54dbc2 0%, #5ebfe7 50%, #d869db 100%) 0% 0% no-repeat
              padding-box;
          }
        }
        p {
          margin-bottom: 0;
          color: #54dbc2;
          font-size: 18px;
          @media all and (max-width: 320px) {
            font-size: 14px !important;
          }
          .number {
            color: #fff;
            border-radius: 50%;
            background-color: #54dbc2;
            font-weight: bold;
            margin-left: 5px;
            line-height: 23px;
            width: 24px;
            height: 24px;
            display: inline-block;
          }
        }
      }
    }
    @media all and (max-width: 1439px) {
      .nav-item {
        margin-right: 35px;
      }
    }
    @media all and (max-width: 991px) {
      .nav-item {
        margin-right: 0;
      }
    }
    @media all and (max-width: 767px) {
      .nav-item {     
        .nav-link {
          p {
            font-size: 16px;
          }
        }
      }
    }
		@media all and (max-width: 425px) {
			display: flex;
			justify-content: space-between;
			.nav-item {
				.nav-link{
					margin-right: 0px;
				}
			}
		}
  }
}
