.identity-verify-page {
	.content__wrapper {
		.title-sub {
			color: #242424;
		}

    .group-content {
			.bg-content-finish {
				padding: 30px;
			}
		}
	}
	.form-style-1 {
		.form-label {
			color: #242424;
		}

    .form-check.form-check-inline {
      label {
        color: #242424;
        font-size: 16px;
      }
    }
	}

  @media all and (min-width: 1500px) {
    .form__wrapper {
      width: 896px;
    }
  }
}

.amazing-accordion {
	background: transparent;
	border: none;
	.amazing-accordion-item {
		background: transparent;
		border: none;
		border-bottom: 1px solid #C8C8C8;

    .amazing-accordion-header {
			.accordion-button {
				font-size: 22px;
				color: #2C3331;
				font-weight: 700;
        background: transparent;
        border: none;
        box-shadow: none;
        padding-left: 42px;

        &::before {
          content: "";
          width: 30px;
          height: 30px;
          position: absolute;
          left: 0;
          background-image: url("../../../../assets//svg/chevron-circle-down.svg");
        }

        &::after {
          background: transparent;
        }

        &:focus {
          border: none;
          box-shadow: none;
          background: transparent;
          color: #2C3331;
        }

        &:not(.collapsed) {
          &::before {
            content: "";
            width: 30px;
            height: 30px;
            position: absolute;
            left: 0;
            background-image: url("../../../../assets//svg/chevron-circle-up.svg");
          }
        }
			}
		}

    .amazing-accordion-body {
      padding-top: 0;
      font-size: 22px;
      color: #2C3331;
      font-weight: 400;
      padding-left: 42px;

      ul {
        padding-left: 0;
        list-style-type: none;
      }

      .bg-content-accordion {
        color: #242424;
        font-size: 16px;
        padding: 12px;
        background: #F9F2FB 0% 0% no-repeat padding-box;
        border-radius: 8px;
      }
    }

		&:last-child {
			border-bottom: none;
		}
	}
}

.amazing-multiple-upload {
  padding-top: 65px;
  padding-bottom: 75px;

  .drap-text {
    margin-top: 56px;
    margin-bottom: 75px;

    p {
      margin-bottom: 25px;
    }

    p.text-top {
      font-size: 18px;
    }

    p.divider, p.text-bottom {
      font-size: 14px;
    }

    button {
      font-size: 1rem;
      font-weight: 500;
      padding: 10px 20px;
      background-color: #54dbc2;
      margin-bottom: 25px;
      border: 1px solid #54dbc2;
      min-width: 240px;

      &:active {
        background-color: #54dbc2;
        border: 1px solid #54dbc2;
      }

      .paperclip {
        font-size: 24px;
        transform: rotate(35deg);
      }
    }
  }

  .attached-files-block {
    display: inline-flex;
    flex-flow: row wrap;
    list-style: none;
    margin-top: 15px;
    margin-bottom: 15px;

    .justify-content-between {
      position: relative;
      display: flex;
      justify-content: center;
      background-color: #F4F9F8;
      width: 200px;
      height: 200px;
      text-align: center;
    }
    .attached-file-cover {
      max-width: 200px;
      margin-right: 16px;
      .cover-image-view {
        max-width: 100%;
        max-height: 100%;
        margin: 0 auto;
      }
      .delete-btn {
        position: absolute;
        float: right;
        top: -8px;
        right: -10px;
      }
    }
    .attach-file-name {
      font-size: 14px;
      color: #7B7B7B;
      text-decoration-line: underline;
      margin-bottom: 0;
      word-break: break-all;
    }
  }

  @media all and (max-width: 576px) {
    padding-top: 40px;
    padding-bottom: 40px;

    #label-file-upload,
    #label-multiple-upload {
      background: #fff;
      border: none;
      .drap-text {
        width: 100%;
        margin-top: 0;
        margin-bottom: 0;
        .text-top {
          display: none;
        }
        .divider {
          display: none;
        }
        button {
          margin-bottom: 13px;
          width: 100%;
          padding-top: 9px;
          padding-bottom: 9px;
          min-width: auto;
        }
        .text-bottom {
          font-size: 13px;
          color: #919191;
        }
      }
    }

    .attached-files-block{
      .attached-file-cover {
        max-width: 103px;
        margin-right: 4px;
        margin-top: 5px;
        margin-bottom: 5px;
        .justify-content-between {
          margin-right: 0;
          width: 103px;
          height: 103px;
        }
      }
    }
  }
}

.amazing-table-style-1 {
	table {
		color: #919191;

    tr {
      border-bottom: 1px solid #C8C8C8;

      &:last-child {
				border-bottom: transparent;
			}
    }
		td {
			padding: 14px 40px;
      
      &:nth-child(2n) {
        color: #2C3331;
      }

      @media all and (min-width: 768px) {
        &:nth-child(1) {
          width: 310px;
        }
      }

      @media all and (max-width: 414px) {
        display: block;
        padding: 10px 40px;
        border: none;

        &:nth-child(2n) {
          padding-top: 0;
        }
      }
		}
	}
}

.btn-w-287 {
  min-width: 287px;
  min-height: 50px;

  @media all and (max-width: 567px) {
    min-width: auto;
    width: 100%;
  }
}

.text-first-line {
  &::first-line {
		color: #242424;
		font-size: 22px;
		font-weight: 700;
	}
}

.text-color-green {
  color: #54DBC2;
}
a.text-color-green {
	&:hover {
		color: #54DBC2;
	}
}