.influencer-identity-modal {
  .modal-body {
    padding-top: 0;
    .header-title {
      font-size: 22px;
      line-height: 30px;
    }
    .info {
      p {
        display: inline-block;
        .label {
          min-width: 100px;
          display: inline-block;
          color: #919191;
        }
        .content {
          margin-right: 20px;
        }
      }
      &:nth-child(3) {
        margin: 0 0 25px 0;
        border-bottom: 1px solid #707070;
        padding-bottom: 10px;
      }
    }
    .hyperlink{
      font-weight: normal;
    }
    .form-update {
      button {
        font-size: 16px;
        line-height: 30px;
        color: #ffffff;
        padding: 10px 45px;
      }
    }
  }
}
