.main-menu-wrapper {
  background-color: #F4F9F88C;
  padding-bottom: 8px;
	backdrop-filter: blur(30px);
	-webkit-backdrop-filter: blur(30px);

  &.nav-logged {
    .menu-navbar {
      .navbar-nav {
        @media (max-width: 1199px) {
          padding-bottom: 70px;
        }
      }
    }
  }

  .main-menu__client {
    min-height: 112px;

    @media all and (max-width: 1024px) {
      min-height: 80px;
    }
  }

  .news-header > .client-container {
    justify-content: center !important;
  }

  .client-container {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between;

    @media (min-width: 1724px) {
      min-width: 1700px;
    }
  }

  @media all and (max-width: 1199px) {
    padding-top: 10px;
  }

  .navbar-brand {
    margin-left: 0;
    margin-right: 15px;
    padding: 0;

    .messages-mobile {
      position: relative;
      &.badge {
        &:after {
          content: "";
          position: absolute;
          width: 10px;
          height: 10px;
          background: red;
          border-radius: 50%;
          right: 5px;
          top: 6px;
        }
      }
    }

    @media all and (min-width: 1200px) {
      .messages-mobile {
        display: none;
      }
    }

    @media all and (max-width: 1200px) {
      margin-left: 0;
    }
  }

  .navbar-toggler {
    background: transparent linear-gradient(45deg, #54DBC2 0%, #5EBFE7 50%, #D869DB 100%) 0% 0% no-repeat padding-box;
    box-shadow: none;
    border-radius: 8px;
    width: 38px;
    height: 38px;
    padding: 0;

    .navbar-toggler-icon {
      color: #fff;
      background-image: url('../../../../assets/svg/close-ic.svg');

      &::before {
        content: none;
      }

      &::after {
        content: none;
      }
    }

    &.collapsed {
      .navbar-toggler-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");

      }
    }

    &:hover {
      background: transparent linear-gradient(45deg, #2DE1C0 0%, #32B7EF 50%, #E843EC 100%) 0% 0% no-repeat padding-box;
    }

    &:active {
      opacity: 0.8;
    }
  }

  .navbar-brand__logo {
    margin-bottom: 5px;
  }

  .title-page {
    text-align: left;
    font-size: 12px;
    color: #C8C8C8;

    @media all and (max-width: 1299px) {
      font-size: 10px;
    }
  }

  .menu-navbar {
    position: relative;

    .nav-menu-link {
      position: relative;
      padding-left: 24px;
      font-size: 16px;
      color: #2C3331;
      font-weight: 700;
      padding-right: 40px;
      padding-left: 24px;
      transition: all .3s;

      @media (max-width: 1400px) {
        font-size: 15px;
      }

      @media (max-width: 1329px) {
        padding-right: 10px;
      }

      &::before {
        background-image: $icon-carret;
        background-repeat: no-repeat;
        background-size: 100%;
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        width: 24px;
        height: 24px;
        transform: translate(0, -50%);
      }

      &:hover {
        font-weight: 900;
        &::before {
          background-image: $icon-carret-hover;
        }
      }

      &:active {
        font-weight: 900;
        color: #CCCCCC;
        &::before {
          background-image: $icon-carret-active;
        }
      }

      
      &.disabled {
        font-weight: 900;
        color: #C8C8C8;
        &::before {
          background-image: $icon-carret-disable;
        }
      }
    }

    .nav-sidebar-link {
      display: none;
    }

    // Link Sign In
    .signin-link {
      position: relative;
      background-color: white;
      border-radius: 8px;
      padding: 19px 42px;
      color: #54DBC2;
      font-size: 18px;
      font-weight: 700;
      box-shadow: 0 3px 6px rgb(0 0 0 / 35%);
      margin-right: 8px;

      @media (max-width: 1400px) {
        font-size: 17px;
      }

      &::after {
        content: "";
        width: calc(100% - 8px);
        height: calc(100% - 8px);
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        border-radius: 8px;
        padding: 2px;
        background: transparent linear-gradient(70deg, #54DBC2 0%, #5EBFE7 50%, #D869DB 100%) 0% 0% no-repeat padding-box;
        -webkit-mask:
          linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
      }

      &::before {
        content: '';
        position: absolute;
        border-radius: 8px;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        padding: 6px;
        background-color: white;
        -webkit-mask:
          linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
      }

      &:hover {
        text-decoration: none;
        color: #54DBC2;
        background-color: white;
        &::before {
          background-color: #F4F9F8;
        }
      }

      &:active {
        color: #54DBC2;
        background-color: white;
        border-color: white;
        opacity: 0.8;
      }

      &.disabled {
        color: white;
        background-color: #C8C8C8;
        border-color: #C8C8C8;
        pointer-events: none;
        &::after {
          background: transparent linear-gradient(70deg, white 0%, white 50%, white 100%) 0% 0% no-repeat padding-box;
        }
        &::before {
          content: none;
        }
      }

			@media (max-width: 768px) {
				margin-right: 0px;
			}
    }

    // End Link Sign In
    .registration-link {
      text-align: center;
      font-weight: 700;
      font-size: 18px;
      color: #FFFFFF;
      background: transparent linear-gradient(68deg, #54DBC2 0%, #5EBFE7 50%, #D869DB 100%) 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 8px #307D6E3D;
      border-radius: 8px;
      padding: 19px 42px;

      &:hover {
        background: transparent linear-gradient(68deg, #2DE1C0 0%, #32B7EF 50%, #E843EC 100%) 0% 0% no-repeat padding-box;
        color: white;
      }

      &:active {
        color: white;
        opacity: 0.7;
      }

      &.disabled {
        color: white;
        background: #C8C8C8;
        border-color: #C8C8C8;
        pointer-events: none;
        opacity: 1;
      }
    }
    .messages {
      position: relative;
      &.badge {
        &:after {
          content: "";
          position: absolute;
          width: 10px;
          height: 10px;
          background: red;
          border-radius: 50%;
          right: 3px;
          top: 3px;
        }
      }
    }
    .userinfo-dropdown {
      padding-top: 8px;
      padding-bottom: 8px;
      .nav-link {
        display: flex;
        align-items: center;

        .userinfo-name {
          margin-left: 8px;
          color: $color-green-500;
        }

        &:hover {
          text-decoration: none;
        }

        &::after {
          content: none;
        }
      }

      &.nav-item.dropdown:hover .dropdown-menu {
        display: block;
      }

      .dropdown-menu {
        margin-top: 0;
        border-radius: 0;
        border-color: #54DBC2;
        border-radius: 8px;
        padding-top: 12px;
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
          background-color: white;
          border-top: 1px solid #54DBC2;
          border-left: 1px solid #54DBC2;
          width: 16px;
          height: 16px;
        }

        .dropdown-item {
          &:active {
              background-color: #54DBC2;
          }
        }
      }
    }
    .my-point {
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #484848;
        font-weight: 700;
      }
    }
  }


  // Repository
  @media (max-width: 1199px) {
    min-height: auto;

    .client-container {
      padding: 0;
    }
    .news-header{
      .client-container{
        padding-right: 16px;
        padding-left: 16px;
      }
    }

    .navbar-brand-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding-left: 32px;
      padding-right: 32px;
      margin-right: 0;
    }

    .menu-navbar {
      margin-top: 9px;
      height: calc(100vh - 81px);
      overflow-x: auto;

      &::before {
        content: "";
        position: absolute;
        background: linear-gradient(130deg, #54dbc2, #5ebfe7 52.21%, #d869db);
        height: 1px;
        left: 0;
        top: 0;
        width: 100%;
      }

      .navbar-nav-scroll {
        max-height: 100%;
      }

      .navbar-nav {
        display: block;
      }

      .nav-menu-link {
        padding: 24px 0;
        padding-left: calc($container-1500--px + 24px);
        padding-right: $container-1500--px;

        &::before {
          left: $container-1500--px;
        }

        &::after {
          content: '';
          position: absolute;
          background: linear-gradient(130deg, #54dbc2, #5ebfe7 52.21%, #d869db);
          height: 1px;
          left: 0;
          bottom: 0;
          width: 100%;
          transform: none;
        }

        &:hover {
          background-color: #E9F5F2;
        }

        &:active {
          background-color: #E9F5F2;
          color: #2C3331;
          opacity: 1;
        }
      }

      .nav-sidebar-link {
        padding: 24px 0;
        padding-left: $container-1500--px;
        padding-right: $container-1500--px;
        position: relative;
        color: #2C3331;
        line-height: 26px;
        font-size: 16px;
        font-weight: 700;
        display: flex;
        &.my-projects-list {
          position: relative;
          span {
            display: flex;
            position: absolute;
            top: 20px;
            left: 185px;
            font-size: 12px;
            font-weight: 400;
            width: 20px;
            height: 20px;
            background: red;
            border-radius: 50%;
            justify-content: center;
            align-items: center;
            color: #fff;
          }
        }

        &.my-project-create {
          align-items: center;
          svg {
            margin-right: 10px;
          }
        }

        .nav-icon {
          margin-right: 8px;
        }

        &::after {
          content: '';
          position: absolute;
          background: linear-gradient(130deg, #54dbc2, #5ebfe7 52.21%, #d869db);
          height: 1px;
          left: 0;
          bottom: 0;
          width: 100%;
          transform: none;
        }
        &:hover,
        &.active {
          background-color: #E9F5F2;
        }
        &.siderbar-user-point {
          div {
            width: 100%;
            background: #fff;
            padding: 10px 5px;
            border-radius: 20px;
          }
        }
      }


      .nav-action-group {
        text-align: center;
        padding-left: 44px;
        padding-right: 44px;
        padding-bottom: 30px;

				@media all and (min-width: 769px) {
					justify-content: center;
				}
				@media all and (max-width: 768px) {
					flex-direction: column;
				}
      }

      .signin-link,
      .registration-link {
        padding-left: 106px;
        padding-right: 106px;
        margin-top: 24px;

        @media all and (max-width: 374px) {
          padding-left: 30px;
          padding-right: 30px;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .navbar-brand-box {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}