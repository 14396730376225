.review-index-page {
  .page-title {
    min-height: auto;
    margin-bottom: 32px;
  }
}

.review-user-info {
  .content-display-right {
    .item-title {
      margin-top: 10px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .item-datatime {
			margin-top: 10px;
		}
  }

  @media all and (min-width: 576px) {
    .rounded-circle {
      margin-right: 40px;
    }

    .content-display-right {
      .item-title {
        margin-top: 65px;
        -webkit-line-clamp: 1;
      }

      .item-datatime {
        margin-top: 45px;
        .datatime-post-completion {
          padding-right: 40px;
        }
      }
    }
  }
  
  @media all and (max-width: 575px) {
    .mobile-center {
      text-align: center;
    }

    .content-display-right {
      .item-title {
        text-align: center;
      }
    }
  }
}

.review-list {
  .review-description {
    font-size: 16px;
    color: #919191;
  }
  .font-bold {
    font-weight: 700;
  }
  .star-list {
    ul {
      li {
        &:nth-child(2n) {
          margin-right: 10px;
        }
      }
    }
  }
}

.star__container-box {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  list-style: none;

  .star__item {
    padding-bottom: 0;
    position: relative;
    width: 22px;
    height: 42px;

    &::after {
      position: absolute;
      width: 20px;
      height: 20px;
      bottom: 0;
      transform: translate(0, 100%);
      left: 0;
      background-color: white;
      display: none;
    }

    @for $i from 1 through 10 {
      &:nth-child(#{$i})::after {
        content: calc($i / 2);
      }
    }

    &:hover {
      &::after {
        display: block;
      }
    }

    &:nth-child(2n + 1) {
      .star__btn {
        background-image: url("../../../../assets/svg/star_icon/star_noactive__left.svg");
      }
    }

    &:nth-child(2n) {
      .star__btn {
        background-image: url("../../../../assets/svg/star_icon/star_noactive__right.svg");
      }
    }
    .star__btn {
      border: none;
      background-color: transparent;
      width: 22px;
      height: 42px;
      background-size: cover;
      background-position: center;
      padding: 0;
    }
  }
}