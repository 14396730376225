.admin-header {
  background-color: white;
  min-height: $h-header;
  border-bottom: 2px solid #98a6b5;

  .navbar-toggler {
    background: transparent linear-gradient(45deg, #54dbc2 0%, #5ebfe7 50%, #d869db 100%) 0% 0% no-repeat padding-box;
    box-shadow: none;
    border-radius: 8px;
    width: auto;
    height: 52px;
    text-align: center;

    .navbar-toggler-icon {
      color: #fff;
      background-image: url('../../../../assets/svg/close-ic.svg');

      &::before {
        content: none;
      }

      &::after {
        content: none;
      }
    }

    &.collapsed {
      .navbar-toggler-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
      }
    }

    &:hover {
      background: transparent linear-gradient(45deg, #2de1c0 0%, #32b7ef 50%, #e843ec 100%) 0% 0% no-repeat padding-box;
    }

    &:active {
      opacity: 0.8;
    }
  }
  .userinfo-dropdown {
    .nav-link {
      display: flex;
      &::after {
        content: none;
      }
      .userinfo-name {
        margin-left: 10px;
      }
    }
    &.nav-item.dropdown .dropdown-menu {
      margin: 0;
    }
    &.nav-item.dropdown:hover .dropdown-menu {
      display: block;
    }
    &.nav-item.dropdown .dropdown-menu {
      margin: 0;
    }
  }
}
