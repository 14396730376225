.user-list-inner {
  .admin-btn-green {
    border-color: #54dbc2;
    margin-top: -48px;
    &:hover {
      border-color: #54dbc2;
    }
  }
  .action-column{
    position: relative;
    z-index: 100;
  }
}
.user-request-modal {
  .modal-header {
    border-bottom: 0;
  }
  .modal-body {
    padding: 55px 40px;
    .form-select, 
    textarea:not(.is-invalid){
			border-color: #919191;
		}
    .form-select:focus,
    textarea:focus{
      box-shadow: none;
      border-color: #919191;
    }
    textarea::placeholder{
      color: #cdd6dd;
    }
  }
}
.admin-cancellation-modal {
  .modal-header {
    border-bottom: 0;
  }
  .modal-body {
    padding: 0 40px 55px; 
    @media (max-width: 400px) {
      padding: 0 0 55px; 
		}
    textarea:focus{
      box-shadow: none;
      border-color: #919191;
    }
    textarea::placeholder{
      color: #cdd6dd;
    }
  }
}