.admin-sidebar {
  background-color: #F4F9F8;
  position: fixed;
  left: 0;
  height: calc(100% - 70px);
  width: $w-sidebar;
  margin-top: $h-header;
  bottom: 0;
  z-index: 99;
  padding-top: 70px;
  padding-bottom: 16px;
  align-items: flex-start;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccd3d1;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #c0c3c2;
  }

  @media (max-width: 1199px) {
    left: -$w-sidebar;
    transition: left 0.2s ease-out;

    &.collapse:not(.show) {
      display: block;
    }

    &.show {
      left: 0;
    }
  }

  .sidebar-nav {
    flex-direction: column;

    .sidebar-nav__link {
      display: block;
      text-decoration: none;
      font-size: 18px;
      color: #2C3331;
      padding: 11px 40px;
      transition: all .3s;

      &.active {
        background-color: #CDF1EA;
      }

      &:hover {
        background-color: #CDF1EA;
      }
    }
  }

  .subsidebar-nav {
    flex-direction: column;

    .subsidebar-nav__link {
      display: block;
      font-size: 15px;
      text-decoration: none;
      color: #2C3331;
      padding: 10px 40px;
      padding-left: 72px;
      transition: all .3s;

      &:hover {
        background-color: #CDF1EA;
      }

      &.active {
        background-color: #CDF1EA;
      }
    }
  }
}