.btn--blue {
  background-color: $color-blue-600;
  border-color: $color-blue-600;
  color: white;

  &:hover {
    background-color: $color-blue-500;
  }
}

.btn-outline--blue {
  border-color: $color-blue-600;
  color: $color-blue-600;

  &:hover {
    color: white;
    background-color: $color-blue-600;
    border-color: $color-blue-600;
  }
}

.common-btn {
  font-weight: 700;
  background: transparent linear-gradient(68deg, #54DBC2 0%, #5EBFE7 50%, #D869DB 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 8px #307d6e3d;
  border-radius: 8px;
  border: none;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
  position: relative;
  font-size: 18px;
  padding: 19px 14px;
  transition: all .3s;

  svg,
  i {
    margin-right: 8px;
  }

  &.btn:active {
    color: white;
    opacity: 0.7;
  }

  &:hover {
    background: transparent linear-gradient(68deg, #2DE1C0 0%, #32B7EF 50%, #E843EC 100%) 0% 0% no-repeat padding-box;
    color: white;
  }

  &:disabled {
    color: white;
    background: #C8C8C8;
    border-color: #C8C8C8;
    pointer-events: none;
    opacity: 1;
  }

  &.disabled {
    color: white;
    background: #C8C8C8;
    border-color: #C8C8C8;
    pointer-events: none;
    opacity: 1;
  }  

  &.btn-auth,
  &.btn-submit-project,
  &.btn-submit-leave-guild,
  &.btn-delete-draft {
    width: 287px;
    min-height: 50px;
    padding: 10px 14px;
    text-decoration: none;

    &:active,
    &:focus-visible {
      color: white;
    }

    @media all and (max-width: 425px) {
      width: 100% !important;
    }
  }

  &.btn-submit-nda {
    min-height: 100px;
    min-width: 396px;
    font-size: 22px;

    &:active,
    &:focus-visible {
      color: white;
    }

    @media all and (max-width: 767px) {
      min-width: auto;
      font-size: 16px;
      min-height: 50px;
      &.btn-submit-leave-guild {
        min-width: 70%;
    }
    }
  }

  &.btn-apply-project {
    min-height: 100px;
    min-width: 396px;
    font-size: 32px;

    &:active,
    &:focus-visible {
      color: white;
    }

    @media all and (max-width: 767px) {
      min-width: 93%;
      font-size: 16px;
      min-height: 50px;
    }
  }
  &.btn-send {
    padding: 12px 5px 11px 5px;
    @media all and (max-width: 767px) {
      font-size: 16px;
      min-height: 50px;
    }
  }

  &.error-btn {
    padding: 12px 80px;
  }
  &.btn__reward-change {
	font-size: 20px;
	width: 287px;
	max-height: 50px;
    min-height: 50px;
    padding: 10px 14px;
  }

  &.btn-applied {
	width: 100% !important;
	padding: 19px 5px;
	@media all and (max-width: 1079px) and (min-width: 991px) {
		font-size: 22px;
	}

	@media all and (max-width: 525px){
		font-size: 16px;
	}
  }
}

.common-outline-btn {
  font-weight: 700;
  color: #54DBC2;
  position: relative;
  background-color:white;
  border-radius: 8px;
  border: none;
  box-shadow: 0px 3px 8px #307D6E3D;
  font-size: 18px;
  padding: 19px 14px;
  transition: all .3s;

  &::after {
    content: "";
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 8px;
    padding: 2px;
    background: transparent linear-gradient(70deg, #54DBC2 0%, #5EBFE7 50%, #D869DB 100%) 0% 0% no-repeat padding-box;
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;

  }
  
  &::before {
    content: '';
    position: absolute;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 6px;
    background-color: white;
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  svg,
  i {
    margin-right: 8px;
  }

  &.btn:active {
    color: #54DBC2;
    background-color: white;
    border-color: white;
    opacity: 0.6;
  }

  &:hover {
    color: #54DBC2;
    background-color: white;
    &::before {
      background-color: #F4F9F8;
    }

    &::after {
      background: transparent linear-gradient(70deg, #2DE1C0 0%, #32B7EF 50%, #E843EC 100%) 0% 0% no-repeat padding-box;
    }
  }

  &:disabled {
    color: white;
    background-color: #C8C8C8;
    border-color: #C8C8C8;
    pointer-events: none;
    &::after {
      background: transparent linear-gradient(70deg, white 0%, white 50%, white 100%) 0% 0% no-repeat padding-box;
    }
    &::before {
      content: none;
    }
  }
  &.disabled {
    color: white;
    background-color: #C8C8C8;
    border-color: #C8C8C8;
    pointer-events: none;
    &::after {
      background: transparent linear-gradient(70deg, white 0%, white 50%, white 100%) 0% 0% no-repeat padding-box;
    }
    &::before {
      content: none;
    }
  }

  &.btn-redirect {
    width: 287px;
    min-height: 50px;
    padding: 10px 14px;
    text-decoration: none;

    &:active,
    &:focus-visible {
      color: #54DBC2;
    }

    @media all and (max-width: 425px) {
      width: 100%;
    }
  }

}

.btn-green {
  font-size: 18px;
  font-weight: 700;
  border-radius: 8px;
  background-color: #54DBC2;
  color: white;
  padding: 10.5px 17px;
  transition: all .3s;
  white-space: nowrap;

  svg,
  i {
    margin-right: 8px;
  }

  &.btn:active {
    background-color: #54DBC2;
    border-color: #54DBC2;
    color: white;
    opacity: 0.7;
  }

  &:hover, &.btn:focus-visible {
    background-color: #2DE1C0;
    border-color: #2DE1C0;
    color: white;
  }

  &:disabled {
    color: white;
    background-color: #C8C8C8;
    border-color: #C8C8C8;
    pointer-events: none;
    opacity: 1;
  }
  &.btn-amazing-disabled {
    color: white;
    background-color: #C8C8C8;
    border-color: #C8C8C8;    
    opacity: 1;
  }
}

.btn-outline-green {
  font-size: 18px;
  font-weight: 700;
  border-radius: 8px;
  border: 3px solid #54DBC2;
  color: #54DBC2;
  background-color: white;
  padding: calc(11.5px - 3px) calc(17px - 3px);
  transition: all .3s;
  white-space: nowrap;

  svg,
  i {
    margin-right: 8px;    
  }

  &.btn:active {
    background-color: #2DE1C0;
    color: white;
    border-color: #54DBC2;
  }

  &:hover {
    background-color: #CDF1EA;
    color: #54DBC2;
    border-color: #54DBC2;
  }

  &:disabled {
    color: white;
    background-color: #C8C8C8;
    border-color: #C8C8C8;
    pointer-events: none;
    opacity: 1;
  }
}

.btn-outline-gray {
  font-size: 16px;
  border-radius: 8px;
  border: 2px solid #C8C8C8;
  background-color: white;
  color: #54DBC2;
  padding: 11px 29px;
  transition: all .3s;

  svg,
  i {
    margin-right: 8px;
  }

  &.btn:active {
    background-color: #54DBC2;
    color: white;
    border-color: #54DBC2;
    font-weight: bold;
  }

  &:hover {
    background-color: white;
    color: #54DBC2;
    border-color: #54DBC2;
  }

  &:disabled {
    color: white;
    background-color: #C8C8C8;
    border-color: #C8C8C8;
    pointer-events: none;
    opacity: 1;
  }
}

.btn-amazing-primary {
  white-space: nowrap;
  font-weight: bold;
  background: transparent linear-gradient(80deg, #54dbc2 0%, #5ebfe7 50%, #d869db 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 8px #307d6e3d;
  border-radius: 8px;
  border: none;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
  position: relative;
  font-size: 18px;
  min-height: 50px;
  min-width: 287px;

  svg,
  i {
    margin-right: 8px;
  }

  &:hover {
    color: white;
  }  

  &:disabled {
    color: white;   
    pointer-events: none;
    opacity: var(--bs-btn-disabled-opacity);
  }
}

.btn-mobile-with-max {
  @media all and (max-width: 540px) {
    width: 100% !important;
  }
}

.circle-count {
  background: #62dec7;
  color: #fff;
  font-size: 20px;
  border-radius: 50px;
  font-weight: 700;
  margin-left: 5px;

  &.circle-count-s24 {
    padding: 0px 7px;
  }

  &.circle-count-s32 {
    padding: 3px 10px;
    @media all and (max-width: 630px) {
      padding: 0px 7px;
    }
  }
}

.btn-close-offcanvas-amazing {
  font-size: 16px;  
  padding-right: 0px;
  color: #919191;
  border: 0px;
  background-color: transparent;
  @media all and (max-width: 768px) {
    padding:0px !important;  
  }

  &:hover {
    color: #919191;
    text-decoration: none;
  }
}
