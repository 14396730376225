.admin-form-control {
  border-color: #919191;
  padding: 15px 24px;

  &:focus {
    box-shadow: none;
    border-color: #54dbc2;
  }

  &.is-invalid:focus {
    box-shadow: none;
  }
}

.admin-login-wrapper {
  .input-group-password {
    input {
      border-color: #919191;
      border-right: none;
      padding: 15px 24px;
  
      &:focus {
        box-shadow: none;
        border-color: #54dbc2;
      }
    }
  
    .input-group-text {
      background-color: white;
      border-color: #919191;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
  
      &:hover,
      &:active {
        color: #51D6BC;
      }
    }
  
    input:focus ~ .input-group-text {
      border-color: #51D6BC;
    }
  
    input.is-invalid {
      border-color: #dc3545;

      &:focus {
        box-shadow: none;
      }
    }
  
    input.is-invalid ~ .input-group-text {
      border-color: #dc3545;
      box-shadow: none;
      border-top-right-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
    }
  }
}

.admin-form-custom-1 {
  color: #3b4043;

  label {
    color: #707f89;
    line-height: 17px;
  }

  select.form-select {
    padding: 12px 40px 12px 24px;
    font-size: 16px;
    border: 1px solid #cdd6dd;
    letter-spacing: 0px;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    // color: #3b4043;
    &:invalid {
      color: #cdd6dd;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%2398A6B5' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    }

    &>option {
      color: #3b4043;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23b4043' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    }

    &:focus {
      box-shadow: none;
      border-color: #cdd6dd;
    }

    &.is-invalid {
      background-color: #f1def4;
      border-color: #d869db;
    }
  }

  .form-check-input[type='checkbox'] {
    width: 30px;
    height: 30px;
    border: 1px solid #cdd6dd;
    border-radius: 4px;
    margin-top: 0;

    &:focus {
      box-shadow: none;
    }
  }

  .form-check-input:checked {
    background-color: #54dbc2;
    border-color: #54dbc2;
  }

  .form-check-label {
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0px;
    color: #3b4043;
    margin: 0 15px 0 10px;
  }

  input.form-control {
    padding: 12px 24px;
    font-size: 16px;
    border: 1px solid #cdd6dd;
  }

  .input-group {
    button {
      border: 0;
      color: #307D6E;
      padding-right: 0px;
      text-align: right;
      font-size: 30px;
    }
  }

  .select-picker__input-group {
    .select-picker__input {
      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #cdd6dd;
        opacity: 1; /* Firefox */
      }
      
      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #cdd6dd;
      }
      
      &::-ms-input-placeholder { /* Microsoft Edge */
        color: #cdd6dd;
      }
    }
  }
}

.admin-form-bg-custom-1 {
  background-color: #F4F9F8;

  .btn-submit-register {
    margin-top: 32px;
  }
}
