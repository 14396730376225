.client-pagination-custom-1 {
  margin-bottom: 0;
  .page-item {
    &.prev,
    &.next {
      .page-link {
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 0;
      }
    }

    &.prev {
      &.active {
        .page-link {
          background-image: $pg-icon-prev-active;
        }
      }

      .page-link {
        background-image: $pg-icon-prev;
        margin-right: calc(8.4px + 4vw);

        &:hover {
          background-image: $pg-icon-prev-hover;
        }
      }

      &.disabled {
        .page-link {
          background-image: $pg-icon-prev-disable;
        }
      }
    }

    &.next {
      &.active {
        .page-link {
          background-image: $pg-icon-next-active;
        }
      }

      .page-link {
        background-image: $pg-icon-next;
        margin-left: calc(8.4px + 4vw);

        &:hover {
          background-image: $pg-icon-next-hover;
        }
      }

      &.disabled {
        .page-link {
          background-image: $pg-icon-next-disable;
        }
      }
    }

    .page-link {
      width: 66px;
      height: 66px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      font-size: 28px;
      color: #484848;
      border: none;

      &:focus {
        box-shadow: none;
      }

      &:hover {
        color: transparent;
        -webkit-text-stroke: 2px #484848;
        font-family: Segoe UI;
      }
			@media (max-width: 575px) {
				width: 55px;
				height: 55px;
			}
			@media (max-width: 425px) {
				width: 40px;
				height: 40px;
			}
			@media (max-width: 375px) {
				width: 30px;
				height: 30px;
			}
    }

    &.active {
      .page-link {
        color: transparent;
        -webkit-text-stroke: 2px #484848;
        font-family: Segoe UI;
        text-shadow: 3px 0px #D869DB, -3px 0px #52D7BE;
      }
    }
  }
}