.select-picker__container {
  position: relative;

  .select-picker__input {
    display: block;
    width: 100%;
    padding: 12px 40px 12px 24px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #919191;
    appearance: none;
    border-radius: 8px;
    color: transparent;
    text-shadow: 0 0 0 #212529;
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    position: relative;
    cursor: pointer;
  }

  .select-picker__input::placeholder {
    text-shadow: none;
  }

  .select-picker__input:focus,
  .select-picker__input:active {
    outline: none;
    box-shadow: none;
    border-color: #51D6BC;
  }

  .select-picker__input:disabled {
    border-color: #C8C8C8;
  }

  .select-picker__input-group {
    position: relative;
    width: 100%;
    display: flex;
  }

  .select-picker__input-group-text {
    display: flex;
    align-items: center;
    padding: 15px 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    background-color: transparent;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    position: absolute;
    top: 1px;
    right: 1px;
    pointer-events: none;
  }

  &.is-invalid .select-picker__input {
    background-color: #F1DEF4;
    border-color: #D869DB;
  }

  .select-picker-option__popper {
    position: absolute;
    inset: auto 0px 0px auto;
    transform: translate(0px, -50px);
    border: 1px solid #51D6BC;
    z-index: 999999999;
    background-color: white;
    width: 100%;
    margin-top: 4px;
    margin-bottom: 4px;
    padding: 10px 0;
    border-radius: 8px;
    max-height: 41vh;
    overflow: auto;
    display: none;
  }

  .select-picker-option__popper.show {
    display: block;
  }

  .select-picker-option__popper::-webkit-scrollbar {
    width: 8px;
  }

  .select-picker-option__popper::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .select-picker-option__popper::-webkit-scrollbar-thumb {
    background: #88E6D5;
    border-radius: 8px;
  }

  .select-picker-option__popper::-webkit-scrollbar-thumb:hover {
    background: #51D6BC;
  }

  .select-picker-option__popper .select-picker-option__item {
    cursor: default;
    background-color: white;
    font-size: 1rem;
    padding: 4px 24px;
    position: relative;
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    position: relative;
    -o-user-select: none;
    transition: all .2s;

    @media all and (max-width: 568px) {
      border-bottom: 1px solid #C8C8C8;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .select-picker-option__popper .select-picker-option__item.active {
    background-color: #F4F9F8;
  }

  .select-picker-option__popper .select-picker-option__item.placeholder-text {
    color: #C8C8C8;
  }

  .select-picker-option__popper .select-picker-option__item.active::before {
    position: absolute;
    content: '';
    top: 50%;
    transform: translate(0, -50%);
    left: 0;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='rgb(81 214 188)'  class='bi bi-check' viewBox='0 0 16 16'%3E%3Cpath d='M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    width: 24px;
    height: 24px;
  }

  .select-picker-option__popper .select-picker-option__item:hover {
    background-color: #F4F9F8;
  }
}


.multiple_select__container {
  border-radius: 8px;
  color: #000;
  max-width: 100%;
  width: 100%;
  display: flex;
	flex-flow: row wrap;

  .select-picker__input {
    border: none;
  }

  .select__input-text {
    align-items: center;
    background-color: var(--bs-gray-500);
    border: 1px solid var(--bs-gray-600);
    border-radius: 5px;
    color: #fff;
    display: inline-block;
    margin: 5px 10px 5px 0;
    padding: 5px 5px 5px 10px;
    white-space: nowrap;

    .select__btn-remove {
      color: #fff;
      line-height: 0;
      margin-left: 4px;
      padding: 0;
      font-size: 22px;
    }
  }

  .btn_remove_all {
    background: #D869DB;
    border-color: #D869DB;
  }
}

.admin-wrapper {
	.select-picker__container {
		.select-picker__input-group {
			.select-picker__input {
				border-radius: 0;
				border: 1px solid #cdd6dd;
			}
			.select-picker__input-group-text {
				svg {
					width: 14px;
				}
			}
		}
		.select-picker-option__popper {
			border-radius: 0;
			border: 1px solid #cdd6dd;
		}
	}
}