// Fonts
@import url("https://use.typekit.net/yyg3bfa.css");
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700;900&display=swap');

// Base
@import './partials/base/variables';
@import './partials/base/container';
@import './partials/base/mixins';
@import './partials/base/reset';
@import './partials/base/common';
@import './partials/base/link';

// Components
@import './partials/components/header';
@import './partials/components/sidebar';
@import './partials/components/sidebar';
@import './partials/components/footer';
@import './partials/components/form';
@import './partials/components/button';
@import './partials/components/icon';
@import './partials/components/modal';
@import './partials/components/paginate';
@import './partials/components/card';
@import './partials/components/tabs';

// Pages
@import './partials/pages/auth';
@import './partials/pages/purchase';
@import './partials/pages/transfer-points';
@import './partials/pages/search-influencer';
@import './partials/pages/search-npo';
@import './partials/pages/my-page';

// Home
@import './partials/pages/home';
@import './partials/pages/project';
@import './partials/pages/identity-verify';
@import './partials/pages/cpanel-project';
@import './partials/pages/reward';
@import './partials/pages/my-project';
@import './partials/pages/list-talk-room';
@import './partials/pages/news';
@import './partials/pages/point';
@import './partials/pages/message';
@import './partials/pages/project-reviews';
@import './partials/pages/email-verify';
@import './partials/pages/evaluation';
@import './partials/pages/invitation'

