.main-wrapper:has(> .full-row) {
  max-width: 100%;
}

.home-top {
  background: #f4f9f8 0% 0% no-repeat padding-box;
  padding: 40px 0;

  .custom-mb {
    .npo_or_company_name {
      margin-top: 10px;

      .progress {
        min-width: 240px;
      }
    }

    .top-page-project {
      .project__img-box {
        @media all and (min-width: 1025px) {
          height: 546px;
        }

        @media all and (min-width: 992px) and (max-width: 1024px) {
          height: 570px;
        }
      }
    }
  }

  .card {
    border-radius: 8px;
    border: none;
    box-shadow: -4px -4px 0px 0px rgb(84 219 194 / 35%), 4px 4px 0px 0px rgb(200 123 210 / 35%);
    background-color: #EBEBEB;

    &:hover {
      img {
        transform: scale(1.2);
      }

      .card-title {
        text-shadow: 0 0 5px rgb(0 0 0 / 50%);
      }
    }

    .project__img-box {
      border-radius: 8px 8px 0 0;
      height: 500px;
      overflow: hidden;

      @media all and (min-width: 992px) and (max-width: 1024px) {
        height: 520px;
      }

      @media (min-width: 320px) and (max-width: 991px) {
        height: 242px;
      }

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100px;
        left: 0;
        bottom: 0;
        background: transparent linear-gradient(180deg, #24242400 0%, #24242466 100%) 0% 0% no-repeat padding-box;
        z-index: 9;
        cursor: pointer;
        pointer-events: none;
      }

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        transition: .3s;
      }
    }

    .card-title {
      bottom: 0;
      left: 0px;
      z-index: 99;
      padding: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;

      h5 {
        font: normal normal bold 24px/36px 'Noto Sans JP';
        letter-spacing: 0px;
        color: #ffffff;
        overflow: hidden;
        height: 78px;
        display: flex;
        align-items: flex-end;
        a {
          text-decoration: none;
          color: inherit;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }

    .card-content {
      background-color: white;
      padding: 10px;
      border-radius: 0 0 10px 10px;

      .info-project {
        padding: 8px 0 16px 8px;

        .type {
          &.type-0 {
              border: 2px solid #cdf1ea;
              background: #f4f9f8;
          }

          &.type-1 {
              border: 2px solid #f1def4;
              background: #f9f2fb;
          }

          &.type-2 {
              border: 2px solid #54dbc2;
              background: #54dbc2;
              color: #fff;
          }

          border-radius: 20px;
          font-size: 13px;
          padding: 5px 10px;
          display: inline-block;
        }
      }

      h5 {
        font: normal normal bold 16px/24px 'Noto Sans JP';
        letter-spacing: 0px;
        color: #919191;
      }

      p {
        font: normal normal normal 16px/24px 'Noto Sans JP';
        letter-spacing: 0px;
        color: #484848;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      .remain-day {
        font-family: 'Noto Sans JP';
        font-weight: bold;
        padding: 10px 10px;
        background: transparent linear-gradient(54deg, #54dbc2 0%, #5ebfe7 50%, #d869db 100%) 0% 0% no-repeat padding-box;
        border-radius: 8px;
        color: #ffffff;
        opacity: 1;
        min-width: 122px;

        span {
          font-size: 18px;
          line-height: 15px;
        }

        h3 {
          font-family: 'Noto Sans JP';
          font-weight: bold;
          margin-bottom: 0;
          font-size: 42px;
          line-height: 36px;
          text-align: right;

          span {
            font-size: 18px;
            margin-left: 10px;
            line-height: 34px;
          }
        }
      }
    }

    .card-sub-title {
      background: #FFFFFFCC 0% 0% no-repeat padding-box;
      padding: 6px 16px;
      border-radius: 4px 0px 16px 0px;
      top: 0;

      @media all and (max-width: 525px) {
        padding: 3px 10px;
      }

      span {
        font: normal normal normal 14px/24px 'Noto Sans JP';
        letter-spacing: 0px;
        color: #484848;
      }
    }
  }

  .small-card {

    &:nth-child(1),
    &:nth-child(2) {
      margin-bottom: calc(var(--bs-gutter-x) * 0.5);
    }

    .card {
      .project__img-box {
        height: 237px;
      }
    }
  }

  .sub-card {
    margin-bottom: 9px;

    .project__img-box {
      &::before {
        height: 80px;
      }
    }

    .card-title {
      h5 {
        font: normal normal bold 16px/24px 'Noto Sans JP';
        letter-spacing: 0px;
        color: #ffffff;
        overflow: hidden;
        height: 54px;
        display: flex;
        align-items: flex-end;
        a {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }

    .card-content {
      padding: 4px 4px 4px 8px;
      column-gap: 5px;

      .info-project {
        padding: 0;

        h5 {
          font: normal normal bold 12px/17px 'Noto Sans JP';
          letter-spacing: 0px;
          color: #919191;
        }

        p {
          font: normal normal normal 14px/24px 'Noto Sans JP';
          letter-spacing: 0px;
          color: #484848;
          margin-top: 0px !important;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }

      .remain-day {
        padding: 5px 10px 5px 10px;
        border-radius: 8px;
        color: #ffffff;
        opacity: 1;
        min-width: 48px;

        &>div {
          line-height: 10px;
        }

        span {
          font-size: 8px;
          line-height: 8px;
        }

        h3 {
          font-size: 19px;
          line-height: 19px;

          span {
            font-size: 9px;
            margin-left: 5px;
            line-height: 18px;
            font-family: 'Noto Sans JP';
          }
        }
      }
    }
  }

  @media all and (max-width: 1024px) {
    .card {
      a img {
        height: 348px;
        object-fit: cover;
      }

      .card-title {
        h5 {
          font-size: 20px;
        }
      }
    }

    .small-card {
      .card {
        .card-title {
          h5 {
            font-size: 16px;
            line-height: 22px;
            height: 48px;
          }
        }
      }

      .card-content {
        background-color: white;
        padding: 10px;
        border-radius: 0 0 10px 10px;

        .info-project {
          width: 71%;

          p {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            height: 22px;
          }
        }

        .remain-day {
          padding: 5px;
        }
      }
    }
  }

  @media all and (max-width: 1440px) {
    zoom: 75.2%;

    .popular-category .inner {
      background: linear-gradient(90deg, #f4f9f8 71%, #fff 29%);
    }
  }

  @media all and (max-width: 1250px) {
    zoom: 65.2%;
  }

  @media all and (max-width: 1100px) {
    zoom: 57.3%;
  }

  @media all and (max-width: 991px) {
    zoom: 100%;

    .client-container {
      .custom-mb {
        margin-bottom: 30px;
      }
    }

    .slider {
      .slider-item {
        margin-right: 25px;
        margin-top: 5px;
        margin-bottom: 5px;

        >.card {
          overflow: hidden;

          .type {
            &.type-0 {
                border: 2px solid #cdf1ea;
                background: #f4f9f8;
            }
  
            &.type-1 {
                border: 2px solid #f1def4;
                background: #f9f2fb;
            }
  
            &.type-2 {
                border: 2px solid #54dbc2;
                background: #54dbc2;
                color: #fff;
            }
  
            border-radius: 20px;
            font-size: 13px;
            padding: 2px 20px;
            display: inline-block;

            @media all and (max-width: 425px) {
              padding: 2px 10px;
            }
          }

          .card-content {
            .remain-day {
              padding: 8px;
              border-radius: 8px;
              min-width: 50px;
    
              span {
                margin-right: 5px;
                font: normal normal bold 10px/8px 'Noto Sans JP';
              }
    
              h3 {
                margin: 0px;
                font: normal normal bold 16px/19px 'Noto Sans JP';
    
                span {
                  font: normal normal bold 10px/8px 'Noto Sans JP';
                  margin-right: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  @media all and (min-width: 768px) and (max-width: 991px) {
    .slider {
      .slider-item {
        >.card {
          h5 {
            height: auto;
          }
          .card-content {
            .remain-day {
              padding: 10.5px
            }
          }
        }
      }
    }
  }

  @media all and (max-width: 768px) {
    padding: 20px 0 36px 0;
    margin-bottom: 10px;

    .slick-list {
      padding: 0 20% 0 0 !important;
    }

    .card {
      .card-title {
        h5 {
          font: normal normal bold 16px/24px 'Noto Sans JP';
          letter-spacing: 0px;
          color: #ffffff;
          height: 54px;
        }
      }

      .card-content {
        padding: 4px 4px 4px 8px;

        .info-project {
          padding: 0;

          h5 {
            font: normal normal bold 12px/17px 'Noto Sans JP';
            letter-spacing: 0px;
            color: #919191;
          }

          p {
            font: normal normal normal 14px/24px 'Noto Sans JP';
            letter-spacing: 0px;
            color: #242424;
            margin-top: 0 !important;
          }
        }

        .remain-day {
          padding: 3px 8px 3px 7px;
          min-width: 50px;

          span {
            font: normal normal bold 8px/8px 'Noto Sans JP';
            letter-spacing: 0px;
            color: #ffffff;
          }

          h3 {
            font: normal normal bold 19px/19px 'Noto Sans JP';
            letter-spacing: 0px;
            color: #ffffff;

            span {
              font: normal normal bold 9px/18px 'Noto Sans JP';
              letter-spacing: 0px;
              color: #ffffff;
              margin-left: 5px;
            }
          }
        }
      }
    }

    .slider {
      .slider-item {
        padding: 3px;
        margin-right: 10px;

        .card>img {
          border-radius: 8px 8px 0 0;
        }

        .card>a>img {
          height: 220px;
        }
      }

      .card-title {
        bottom: 0;
        left: 0px;
        padding-bottom: 8px;

        h5 {
          line-height: 20px;
          font-size: 14px;
          height: auto;
          margin-bottom: 0;
        }
      }

      .card-content {
        h5 {
          font-size: 12px;
          font-weight: bold;
          min-height: 40px;
        }

        p {
          font-size: 14px;
          font-weight: normal;
          margin-top: 0px !important;
        }

        @media all and (max-width: 525px) {
          .rank-and-remain {
            flex-wrap: wrap;
            .mobile-slider-rank {
              min-width: 170px;
            }
          }
        }
      }
    }
  }

  @media all and (max-width: 425px) {
    .card {
      a img {
        height: 242px;
      }
    }

    .slider {
      .slider-item {
        .slider-project__img-box {
          height: 162px;
          overflow: hidden;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          img {
            transition: .3s;
          }
        }

        .card-content {

          p {
            width: 100%;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            height: 25px;
          }
        }
      }
    }
  }
}

.popular-category {
  padding: 50px 0;
  background-color: #fff;

  .inner {
    $categoryItem: 7;
    padding: 50px 0;

    @media (min-width: 1565px) {
      background: linear-gradient(90deg, #f4f9f8 calc(((100% - 1500px) / 2) + ((1500px / $categoryItem) * 5)), #fff 29%);
    }

    @media (min-width: 1500px) and (max-width: 1564px) {
      background: linear-gradient(90deg, #f4f9f8 calc(((100% - 1500px) / 2) - 12px + ((1500px / $categoryItem) * 5)), #fff 29%);
    }

    @media (min-width: 769px) and (max-width: 1499px) {
      background: linear-gradient(90deg, #f4f9f8 calc(((100% / 7) * 5) - 16px), #fff 29%);
    }

    .grid-container {
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      grid-gap: 24px;
      margin-top: 24px;
      width: 100%;

      @media (max-width: 1500px) {
        grid-gap: 8px;
      }

      @media all and (max-width: 768px) {
        grid-gap: 24px;
      }

			@media all and (max-width: 1440px) {
				grid-template-columns: repeat(7, 1fr);
			}
			@media all and (max-width: 1280px) {
				grid-template-columns: repeat(6, 1fr);
			}
			@media all and (max-width: 1024px) {
				grid-template-columns: repeat(5, 1fr);
			}

      .grid-item {
        text-align: center;
        cursor: default;

        &:hover {
          .category__title {
            color: #54DBC2;
          }

          img {
            transform: scale(1.05);
          }
        }

        .category__img-box {
          max-width: 140px;
          max-height: 140px;
          margin: auto;

          img {
            width: 100%;
            height: auto;
            object-fit: cover;
            transition: .3s;
          }
        }

        .category__title {
          width: 114px;
          margin-top: 24px;
          margin-bottom: 0;
          margin-left: auto;
          margin-right: auto;
          font-size: 16px;
          text-align: center;
          color: #484848;
          text-decoration: none;
          transition: .3s;
        }
      }
    }
  }


  @media all and (max-width: 768px) {
    padding: 125px 0 60px 0;
    position: relative;

    .inner {
      background: linear-gradient(90deg, #f4f9f8 65%, #fff 35%);

      .section.title {
        position: absolute;
        top: 53px;
      }

      .grid-container {
        grid-template-columns: repeat(2, 1fr);

        .grid-item {
          .category__img-box {
            max-width: 159px;
            max-height: 159px;
          }

          .category__title {
            margin-top: 4px;
            font-size: 14px;
          }
        }
      }
    }
  }
}

.popular-genre {
  background-color: #fff;
  padding: 50px 0;

  .inner {
    $popularItem: 7;
    padding-bottom: 50px;
    position: relative;

    &::before {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      height: 100%;
      background-color: #F9F2FB;
      width: 65%;

      @media (min-width: 1565px) {
        width: calc((((100% - 1500px) / 2) + (1500px / 4) * 3) - 40px);
      }

      @media (min-width: 1500px) and (max-width: 1564px) {
        width: calc((((100% - 1500px) / 2) + (1500px / 4) * 3) - 50px);
      }

      @media (min-width: 769px) and (max-width: 1499px) {
        width: calc(((100% / 4) * 3) - (40px + 24px));
      }
    }

    .content {
      margin-top: 20px;
    }

    .card {
      background-color: #cdf1ea;
      padding: 4px 0 0 4px;
      border-radius: 8px;
      box-shadow: 4px 4px #f1def4;
      border: none;
      margin-bottom: 11px;

      &::before {
        content: '';
        position: absolute;
        left: 0px;
        bottom: 0px;
        height: 67px;
        width: 100%;
        z-index: 9;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        background: transparent linear-gradient(180deg, #24242400 0%, #24242466 100%) 0% 0% no-repeat padding-box;
        cursor: pointer;
        pointer-events: none;
      }

      &:hover {
        img {
          transform: scale(1.1);
        }

        .card-title {
          text-shadow: 0 0 5px rgb(0 0 0 / 50%);
        }
      }

      .card-image {
        overflow: hidden;
        border-radius: 8px;
        height: 235px;

        img {
          width: 100%;
          height: 100%;
          transition: .3s;
          object-fit: cover;
        }
      }

      .card-title {
        left: 24px;
        bottom: 0px;
        z-index: 99;
        font-size: 24px;
        color: white;
      }
    }
  }

  @media all and (max-width: 575px) {
    position: relative;
    padding: 35px 0 20px 0;

    .inner {
      padding-bottom: 40px;

      .title-bg {
        line-height: 40px;
        margin-bottom: -5px;
      }

      .content {
        margin-top: 24px;
      }

      .card {
        box-shadow: 0px 4px 8px #307D6E3D;
        padding: 0;
        background-color: transparent;

        .card-title {
          left: 8px;
          bottom: 0px;
          font-size: 14px;
        }

        .card-image {
          height: 110px;
        }
      }
    }
  }
}

.contact-line {
  background-color: #fff;
  padding-top: 50px;

  .line-link {
    font-size: 24px;
    color: #242424;

    &:hover {
      color: #62dec7 !important;
    }
    @media all and (max-width: 575px) {
      font-size: 16px;
    }
  }

  @media all and (max-width: 575px) {
    padding-top: 35px;
  }
}

.point {
	.client-container {
    @media all and (min-width: 1200px) {
      .mw-pc {
        max-width: 550px !important;
      }
    }
		@media all and (min-width: 991px) {
			padding: 0px;
			width: 100%;
			max-width: 100%;
		}
	}
  padding: 50px 0 104px 0;
  background-color: #fff;

  .bg-item {
    background-image: url(/assets/images/point-pc-bg.png);
    background-color: white;
    background-repeat: no-repeat;
    height: 641px;
    z-index: 0;
    background-size: contain;
    background-position-y: bottom;
  }

  .list-image {
    top: 90px;

    .image-left {
      top: 0;
      left: 0;

      img {
        width: 320px;
      }
    }

    .image-right {
      left: 192px;
      top: 206px;

      img {
        width: 240px;
      }
    }

    img {
      object-fit: cover;
    }
  }

  .list-content {
    margin-left: 537px;
    z-index: 1;
    position: relative;

    .point-content {
      padding: 25px;
      overflow: hidden;
			height: 179px;

      .point-list {
        float: left;
        margin-right: 40px;

        h3 {
          text-align: left;
          letter-spacing: 6.2px;
          color: #cdf1ea;
          text-shadow: 3px 3px #f1def4;
          font-family: 'futura-pt';
          line-height: 62px;
          font-size: 62px;
        }

        h4 {
          text-align: right;
          letter-spacing: 0px;
          color: #484848;
          text-shadow: -2px -2px #54dbc2, 2px 2px #d869db;
          margin-top: -10px;
          margin-right: 5px;
          font-family: 'futura-pt';
          line-height: 56px;
          font-size: 62px;
        }
      }

      .title {
        float: revert;
        padding: 25px 0 5px;
        cursor: default;
        color: #484848;

        h5 {
          letter-spacing: 0px;
          font-size: 24px;
          font-weight: bold;
          transition: .3s;
        }
      }

      &:hover {
        &:nth-child(2n + 1) .title {
          color: #54DBC2;
        }

        &:nth-child(2n) .title {
          color: #D869DB;
        }
      }

      .content {
        span {
          color: #484848;
          font-size: 16px;
        }
      }
    }

		.point-content:first-child {
      background-color: #f4f9f8;
		}

    .point-content:nth-child(2) {
      margin-left: 104px;
      @media all and (min-width: 991px) {
        background-color: #f9f2fb;
      }
    }

    .point-content:nth-child(3) {
      margin-left: 208px;
      @media all and (min-width: 991px) {
        background-color: #f4f9f8;
      }
    }
  }

  @media all and (max-width: 1800px) {
    padding: 10px 0 76px 0;

		.bg-item {
			height: 500px;
		}

    .list-content {
      margin-left: 420px;

      .point-content {
        padding: 16px;
				height: 135px;
        .point-list {
          margin-right: 20px;

          h3 {
            line-height: 52px;
            font-size: 52px;
          }

          h4 {
            line-height: 46px;
            font-size: 52px;
          }
        }

        .title {
          padding: 18px 0 5px;

          h5 {
            font-size: 20px;
          }
        }

        .content {
          span {
            font-size: 16px;
          }
        }
      }

      .point-content:nth-child(2) {
        margin-left: 80px;
      }

      .point-content:nth-child(3) {
        margin-left: 160px;
				height: 150px;
      }
    }
  }

  @media all and (max-width: 1366px) {
    padding: 0px 0 52px 0;

		.bg-item {
			height: 450px;
		}

    .list-content {
      margin-left: 375px;

      .point-content {
        padding: 5px;
				height: 110px;

        .title {
          h5 {
            font-size: 16px;
          }
        }

        .content {
          span {
            font-size: 11px;
          }
        }
      }

      .point-content:nth-child(2) {
        margin-left: 75px;
        padding-left: 0;
        padding-right: 0;
				height: 125px;
      }

      .point-content:nth-child(3) {
        margin-left: 148px;
				height: 140px;
      }
    }
  }

  @media all and (max-width: 1024px) {
    padding: 0px 0 52px 0;

    .bg-item {
      height: 350px;
    }

    .list-content {
      margin-left: 292px;

      .point-content {
				height: 90px;
				padding: 2px;
        .point-list {
          h3 {
            line-height: 42px;
            font-size: 42px;
          }

          h4 {
            line-height: 36px;
            font-size: 42px;
          }
        }

        .title {
          padding: 10px 0 0px;

          h5 {
            font-size: 16px;
          }
        }

        .content {
          span {
            font-size: 11px;
          }
        }
      }

      .point-content:nth-child(2) {
        margin-left: 60px;
				height: 95px;
      }

      .point-content:nth-child(3) {
        margin-left: 115px;
				height: 110px;
      }
    }
  }

  @media all and (max-width: 991px) {
    position: relative;
    padding: 10px 0 64px 0;

    .bg-item {
      background-image: url(/assets/images/point-mobile-bg.png);
      top: -16px;
      height: 260px;
    }

    .bg-bottom-item {
      background-image: url(/assets/images/point-mobile-bottom-bg.png);
      height: 64px;
      background-position-x: calc(100% - 15px);
      background-repeat: no-repeat;
    }

    .list-image {
      .image-left {
        left: 68px;

        img {
          width: 144px;
        }
      }

      .image-right {
        right: 32px;
        top: 92px;
        left: unset;

        img {
          width: 104px;
        }
      }
    }

    .list-content {
      margin-left: 0;
      margin-top: 235px;

      .point-content {
        margin-bottom: 10px;
        background: linear-gradient(0deg, #f4f9f8 86%, transparent 35%);
        padding: 0 calc(var(--bs-gutter-x) * 1.5) 0 0;
        margin-right: calc(var(--bs-gutter-x) * -1.5);
				height: auto;

        &:nth-child(2n) {
          background: linear-gradient(0deg, #f9f2fb 86%, transparent 35%);
        }

        &:last-child {
          margin-bottom: 0;
        }

        .point-list {
          h3 {
            letter-spacing: 4.2px;
            line-height: 48px;
            font-size: 48px;
          }

          h4 {
            line-height: 48px;
            font-size: 48px;
            text-align: left;
            margin-left: 8px;
          }
        }

        .content {
          clear: both;
          padding-left: 0;
          padding: 8px;

          span {
            font-size: 16px;
          }
        }

        .title {
          float: left;
          padding: 0;
          margin: 60px 0 0 -50px;

          h5 {
            font-size: 18px;
            margin-bottom: 0;
          }
        }
      }

      .point-content:nth-child(2) {
        margin-left: 0;
				height: auto;
      }

      .point-content:nth-child(3) {
        margin-left: 0;
				height: auto;
      }
    }
  }

  @media all and (max-width: 575px) {
    position: relative;
    padding: 10px 0 64px 0;
  }

  @media all and (max-width: 575px) {
    .list-content {
      .point-content {
        padding: 0 calc(var(--bs-gutter-x) * 0.5) 0 0;
        margin-right: calc(var(--bs-gutter-x) * -0.5);
      }
    }
  }

  @media all and (max-width: 375px) {
    .bg-item {
      top: 15px;
      height: 260px;
      background-size: inherit;
    }
  }
}

.news {
  padding: 50px 0 100px 0;
	min-height: 350px;
	@media screen and (max-width: 991px) {
		min-height: auto;
	}

  .header {
    h2.title-bg {
      font-family: 'futura-pt';
    }

    h3 {
      margin: 5px 0px 0px;
      position: absolute;
      writing-mode: vertical-rl;
      text-orientation: mixed;
      text-align: left;
      font: normal normal 900 62px 'futura-pt';
      line-height: 45px;
      letter-spacing: 6.2px;
    }

    .outline-1 {
      color: transparent;
      -webkit-text-stroke: 2px #cdf1ea;
      text-shadow: none;
      top: 44px;
      left: 20px;
      z-index: -1;
    }

    .outline-2 {
      color: transparent;
      -webkit-text-stroke: 2px #f1def4;
      text-shadow: none;
      top: 48px;
      left: 16px;
      z-index: -1;
    }
  }

  .news-content {
    margin-top: 70px;

    .news__item {
      padding: 18px 30px;
      border-bottom: 1px solid #c8c8c8;

      &:first-child {
        border-top: 1px solid #c8c8c8;
      }


      &:hover .title a {
        color: #54DBC2;
      }

      .date {
        color: #919191;
        margin-right: 42px;
      }

      .title {
        a {
          color: #242424;
          text-decoration: none;
          transition: .3s;
        }
      }
    }

    .action_new {
      margin-top: 24px;
      background: #fff;
      border-radius: 8px;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 1.44px;
      color: #54dbc2;
      padding: 10px 20px;
      text-align: center;
      position: relative;
      text-decoration: none;
      transition: .3s;

      &:before {
        content: '';
        position: absolute;
        height: calc(100% + 4px);
        width: calc(100% + 4px);
        left: -2px;
        top: -2px;
        background: transparent linear-gradient(81deg, #54dbc2 0%, #5ebfe7 50%, #d869db 100%) 0% 0% no-repeat padding-box;
        border-radius: 8px;
        z-index: -9;
      }

      &:after {
        content: '';
        position: absolute;
        background: #fff;
        box-shadow: 0 3px 6px rgb(0 0 0 / 35%);
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        left: -2px;
        top: -2px;
        z-index: -99;
        border-radius: 8px;
      }

      &:hover {
        background-color: #CDF1EA;
      }
    }
  }

  @media all and (max-width: 991px) {
    padding: 45px 0 56px 0;

    .header {
      .title-bg {
        margin-bottom: 0px;
      }

      h3 {
        writing-mode: unset;
      }

      .outline-1 {
        top: -18px;
        left: 200px;
      }

      .outline-2 {
        top: -16px;
        left: 202px;
      }
    }

    .news-content {
      margin-top: 30px;

      .news__item {
        padding: 15px 0;

        .title {
          a {
            -webkit-line-clamp: 2
          }
        }
      }

      .action_new {
        margin-top: 16px;
      }
    }
  }

  @media all and (max-width: 575px) {
    padding: 0 0 56px 0;

    .header {
      .title-bg {
        margin-bottom: -10px;
      }

      h3 {
        writing-mode: unset;
      }

      .outline-1 {
        top: -6px;
        left: 154px;
        font-size: 48px;
      }

      .outline-2 {
        top: -4px;
        left: 156px;
        font-size: 48px;
      }
    }

    .news-content {
      margin-top: 30px;

      .info {
        margin: inherit;
        padding: 15px 0 !important;

        .title {
          padding-left: 0;
          padding-right: 0;
          margin-left: 0 !important;
        }
      }

      .button {
        .action_new {
          margin-right: inherit;
        }
      }
    }
  }
}

.home-page {
  min-height: calc(100vh - (112px + 83px) - 110px);
}

@media all and (max-width: 768px) {
  .home-top {
    zoom: 100%;

    .client-container {
      .custom-mb {
        margin-bottom: 30px;
      }
    }
  }
}

.client-logged {
	.home-top {
		.small-card {
			.sub-card {
				.card-content {
          min-height: 80px;
          
					@media all and (min-width: 992px) and (max-width: 1024px) {
            min-height: 90px;
          }
				}
			}
		}
	}
}