.admin_my-page {
	.section {
		.section_item {
			.border-bottom_dashboard {
				border-bottom: 2px solid #98a6b5;
			}
			.total_project {
				height: auto;
				background-color: #f4f4f4;
				text-align: center;
				padding: 10px 0px;
				.total_project-number {
					font-size: xxx-large;
				}
			}
		}
	}
	.chart-container {
		position: relative;
		height: 400px;
		@media screen and (max-width: 1440px) {
			height: 300px;
		}
		@media screen and (max-width: 720px) {
			height: 250px;
		}
	}
}