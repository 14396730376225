.page-news {
  .news-content {
    margin-bottom: 104px;

    @media all and (max-width: 575px) {
      margin-bottom: 40px;
    }

    .news__item {
      &:first-child {
        border-top: 0;
      }

      &:last-child {
        border-bottom: 0;
      }

      @media all and (max-width: 575px) {
        .title {
          a {
            -webkit-line-clamp: 2
          }
        }
      }
    }
  }
}

.page-news-detail {
  .news-header {
    border-bottom: 1px solid #c8c8c8;
    margin-bottom: 10px;
    padding-bottom: 10px;

    .news-date {
      color: #919191;
      margin-right: 20px;
      line-height: 34px;
    }

    .news-title {
      font-size: 18px;
      line-height: 34px;
      flex-shrink: 1;
    }
  }

  .news-body {
    line-height: 34px;
    margin-bottom: 34px;
    white-space: pre-wrap;
  }

  button {
    padding: 16px !important;
  }
}

.main-menu-wrapper.has-top-bar {
  flex-direction: column;
  padding-top: 0;

  .main-menu__client {
    padding-top: 0;
  }

  .news-header {
    width: 100%;
    background-color: #4F4F4F;
    font-size: 14px;
    color: #FFFFFF;
    min-height: 28px;
    line-height: 1.2;
    display: flex;
    align-items: center;
    padding: 5px 0;
  }
}

.btn-back-link {
  position: relative;
  font-size: 16px;
  color: #2c3331 !important;
  text-decoration: none !important;
  font-weight: 700;
  padding-right: 40px;
  padding-left: 45px;
  transition: all 0.3s;
  margin-bottom: 20px;

  @media (max-width: 1312px) {
    padding-right: 18px;
    margin-left: 0px !important;
  }

  &::before {
    background-image: $icon-carret;
    background-repeat: no-repeat;
    background-size: 100%;
    content: '';
    position: absolute;
    left: 0;
    top: -50%;
    width: 42px;
    height: 42px;
    transform: rotate(180deg);
  }
}

.news-list {
  a {
    color: unset;
    text-decoration: none;
  }
}

.my-news-detail {
  .title {
    h2 {
      font-size: 32px;
      font-weight: 700;
      letter-spacing: 0px;
      color: #484848;
      margin-bottom: 16px;

      @media all and (max-width: 767px) {
        font-size: 26px;
        margin-left: 0px !important;
        padding-left: 0px !important;
      }
    }

    @media all and (max-width: 1280px) {
      padding-right: 0px;
    }
  }

  .publish-at {
    font-size: 16px;
    color: #919191;
  }

  .body {
    font-size: 16px;
    color: #242424;
    opacity: 1;
    line-height: 34px;
    text-align: justify;
    white-space: pre-wrap;

    @media all and (max-width: 767px) {
      margin-left: 0px !important;
      padding-left: 0px !important;
    }
  }

  .sub-title {
    margin: 16px 0;

    .btn-talk-room {
      padding: 12px 27px;

      @media all and (max-width: 575px) {
        margin-top: 10px;
      }
    }
  }
}