#reward-rate-setting-page {
  .title__page {
    color: #4F4F4F;
    font-size: 22px;
    padding-bottom: 40px;
  }

  .custom-form__page {
    .col-form-label {
      color: #919191;
    }

    .form-control {
      padding-top: 12px;
      padding-bottom: 12px;
      border-color: #919191;

      &:focus {
        box-shadow: 0 0 0 0.25rem rgb(205 214 221 / 55%);
      }

      &.is-invalid {
        border-color: #D869DB;
        background-color: #F1DEF4;
        background-image: none;
        position: relative;
        padding-right: .75rem;

        &:focus {
          box-shadow: 0 0 0 0.25rem #d869db40;
        }
      }
    }

    .invalid-feedback {
      color: #D869DB;
      position: relative;
      padding-left: 15px;

      &:before {
        content: "";
        position: absolute;
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill=%27%23D869DB%27 class="bi bi-x-circle-fill" viewBox="0 0 16 16"> <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/> </svg>');
        width: 15px;
        height: 15px;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
      }
    }
  }
}

.az-admin-tabs {
  .nav-tabs {
    --bs-nav-tabs-border-width: 0;
    border-bottom: 4px solid #f4f9f8;
  }
  .nav-link {
    font: normal normal normal 16px/30px Source Han Sans JP;
    letter-spacing: 0px;
    color: #4f4f4f;
    padding: 0;
    margin-right: 47px;
    padding-bottom: 20px;
  }
  .nav-link.active {
    position: relative;
    &:after {
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      bottom: -5px;
      height: 4px;
      background: #54dbc2;
    }
  }
}