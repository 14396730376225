.toast-fixed-rn-custom-1 {
  position: fixed;
  right: 16px;
  bottom: 32px;

  .toast {
    transition: .3s;
    animation: toastSlideInRight 0.5s ease-in-out;

    &.toast-success {
      background-color: #54DBC2;
      color: white;
      font-size: 16px;
      font-weight: bold;
      border: none;
    }

    .toast-body {
      padding: 16px;
      text-shadow: 0 0 5px rgb(0 0 0 / 25%);
    }
  }
}

@keyframes toastSlideInRight {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
}