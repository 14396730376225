.evaluation-page {
    .overall-rating {
        h3 {
            margin-bottom: 0;
        }
    }
    .rating-history-list {
        border-top: 2px solid #c8c8c8;
        margin-bottom: 20px;
        min-height: 167px;
        .item {
            border-bottom: 1px solid #c8c8c8;
            padding: 15px 40px;
            .rating-datetime {
                color: #919191;
            }
        }
    }
    @media (min-width: 992px) {
        .overall-rating-stars {
            margin-left: 100px;
        }
    }
    @media (max-width: 991px) {
        .overall-rating {
            h3 {
                margin-bottom: 15px;
            }
        }
        .rating-history-list {
            .item {
                padding: 15px 0;
                .item-info {
                    margin-bottom: 15px;
                }
            }
        }
    }
}