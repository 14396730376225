.bs-modal-custom-1 {
  .modal-content {
    background-color: white;
    border: none;
    border-radius: 8px;

    &::before {
      position: absolute;
      content: "";
      z-index: -9;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      background: #cdf1ea 0% 0% no-repeat padding-box;
      left: -4px;
      top: -4px;
    }

    &::after {
      position: absolute;
      content: "";
      z-index: -9;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      background: #f1def4 0% 0% no-repeat padding-box;
      right: -4px;
      bottom: -4px;
    }
  }

  &.not_points_recruit_talk_room {
    float: right;
    padding-left: 40px;
    padding-right: 40px;
    width: min(95vw, 1008px);
    max-width: 100%;

    @media (min-width: 320px) and (max-width: 480px) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}

.bs-dialog-box {
  .dialog-box__title {
    font-size: 18px;
    margin-bottom: 14px;
  }

  button {
    padding-top: 12px;
    padding-bottom: 12px;
    width: 90%;
  }
}