.my-points-index-page {
  .my-points__container {
    padding-top: 40px;
    padding-bottom: 50px;
    padding-left: calc(22.4px + 8vw);
    padding-right: calc(22.4px + 8vw);
    margin-bottom: 35px;

    // Mobile and Ipad
    @media (min-width: 320px) and (max-width: 1024px) {
      padding-left: 32px;
      padding-right: 32px;

      .my-points-value-group {
        margin-bottom: 16px;
      }
    }

    .my-points__name {
      font-size: 22px;
      margin-right: 8px;

      // Mobile and Ipad
      @media (min-width: 320px) and (max-width: 1024px) {
        font-size: 20px;
      }
    }

    .my-points__box-value {
      position: relative;
      padding-left: 55px;
      font-weight: bold;
      display: inline-flex;
      align-items: center;

      &::before {
        position: absolute;
        content: '';
        width: 47px;
        height: 54px;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        background-image: $icon-point-green;
        background-repeat: no-repeat;
        background-size: cover;
      }

      span {
        &:first-child {
          font-size: 70px;
          line-height: 61px;
          color: #484848;

          // Mobile and Ipad
          @media (min-width: 320px) and (max-width: 1024px) {
            font-size: 60px;
          }
        }

        &:last-child {
          margin-left: 12px;
          margin-top: 8px;
          font-size: 22px;
        }
      }
    }

    .my-points__btn {
      padding-left: 53px;
      padding-right: 53px;
      white-space: nowrap;
    }
  }
  .table {
    font-size: 22px;
    @media all and (max-width: 470px) {
      font-size: 16px;
    }
    th {      
      color: #919191;
    } 
    .table-purchase-row {
      font-weight: bold;
      td {
        color:#484848;        
      }
      .color-pink {
        color: #D869DB;
      }
      .number_point {
        font-size: 36px;
        position: relative;
        padding-left: 35px;
        font-weight: bold;
        display: inline-flex;
        align-items: center;
        @media all and (max-width: 470px) {
          font-size: 20px;
        }
  
        &::before {
          position: absolute;
          content: '';
          width: 30px;
          height: 30px;
          top: 50%;
          left: 0;
          transform: translate(0, -50%);
          background-size: 27px;
          background-image: $icon-point-green;
          background-repeat: no-repeat;          
        }
      }
    }   
  }
}

.my-points-index_default {
  @media all and (max-width: 700px) {
    .client-cpanel__page-title-after {
      font-size: 40px;
      line-height: 45px;
    }
  }
	@media (min-width: 320px) and (max-width: 494px) {
		padding-top: 50px;
	}
}

.points-list__container {
  position: relative;

  .point-list__header {
    font-size: 22px;
    padding: 16px 0;
    border-bottom: 2px solid #C8C8C8;
  }


  .points-list__body {
    margin-bottom: 8px;

    .points-list__item {
      padding: 16px 40px;
      border-bottom: 1px solid #C8C8C8;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:first-child {
        border-top: 2px solid #C8C8C8;
      }

      // Mobile and Ipad
      @media (min-width: 320px) and (max-width: 1024px) {
        padding: 8px 0;
      }

      .points-list__box-value {
        position: relative;
        padding-left: 43px;
        font-weight: bold;
        display: inline-flex;
        align-items: center;

        &::before {
          position: absolute;
          content: '';
          width: 35px;
          height: 40px;
          top: 50%;
          left: 0;
          transform: translate(0, -50%);
          background-image: $icon-point-green;
          background-repeat: no-repeat;
          background-size: cover;
        }

        span {
          &:first-child {
            font-size: 50px;
            line-height: 61px;
            color: #484848;

            // Mobile and Ipad
            @media (min-width: 320px) and (max-width: 1024px) {
              font-size: 24px;
            }
          }

          &:last-child {
            margin-left: 12px;
            margin-top: 8px;
            font-size: 22px;
          }
        }
      }

      .points-list__btn-price {
        font-family: system-ui;
        width: 310px;
        font-size: 50px;
        line-height: 61px;
        padding: 9px 30px;

        // Mobile and Ipad
        @media (min-width: 320px) and (max-width: 1024px) {
          font-size: 22px;
          line-height: 35px;
          width: 163px;
        }
      }

      .points-list__box-value-2 {
        font-size: 40px;
        font-weight: bold;
        width: 310px;
        padding: 5px 15px 5px 25px;

        &::before {
          content: "";
          background-image: $icon-point-white;
          background-repeat: no-repeat;
          position: absolute;
          width: 33px;
          height: 36px;
          left: 35px;
          top: 50%;
          transform: translateY(-50%);
        }

        .font-small-18 {
          font-size: 18px;
        }

        @media all and (max-width: 1024px) {
          font-size: 26px;
          width: 180px;
          padding-left: 40px;

          &::before {
            background-size: contain;
            width: 22px;
            height: 25px;
            left: 15px;
          }
        }

        @media all and (max-width: 480px) {
          margin-bottom: 10px;
        }
      }

      .points-list__btn-price-2 {
        .cost__item {
          font-size: 26px;
          font-weight: bold;

          @media all and (max-width: 480px) {
            text-align: center !important;
          }
        }

        .price__item {
          font-size: 42px;
          color: #D869DB;
          font-weight: bold;
        }

        .font-small-18 {
          font-size: 18px;
        }

        .font-small-22 {
          font-size: 22px;
        }

        .font-small-35 {
          font-size: 35px;
        }

        @media all and (max-width: 1024px) {
          .cost__item {
            font-size: 20px;
          }

          .font-small-18 {
            font-size: 15px;
          }

          .price__item {
            font-size: 28px;
          }

          .font-small-22 {
            font-size: 18px;
          }

          .font-small-35 {
            font-size: 25px;
          }
        }
      }

      @media all and (max-width: 480px) {
        flex-direction: column;
      }
    }
  }
}