form {
  .form-group-item {
    margin-bottom: 32px;

    @media all and (max-width: 575px) {
      margin-bottom: 24px;
    }
  }

  .custom-form-select {
    border-radius: 0;

    &:invalid {
      color: #cdd6dd;
    }

    &:first-child {
      color: #cdd6dd;
    }

    &:invalid option:not(:first-child) {
      color: black;
    }

    &:focus {
      border-color: #98a6b5;
      box-shadow: 0 0 0 0.2rem #98a6b529;
    }
  }

  .form-radio-input[type='radio'] {
    width: 30px;
    height: 30px;
    border: 1px solid #cdd6dd;
    border-radius: 50%;
    margin-top: 0;
    appearance: none;
    background-clip: content-box;
    background-color: #fff;
    &:focus {
      box-shadow: none;
    }
    margin: 10px 10px -10px 0px;
  }

  .form-radio-input:checked {
    background-color: #54dbc2;
    border: 1px solid #54dbc2;
    padding: 5px;
  }
}

.hashtag__container {
  display: flex;
  overflow: auto;
  width: 100%;
  max-width: 100%;
  border: 1px solid #ced4da;
  color: black;
  padding-left: 0.75rem;

  .hashtag__input {
    width: 100%;
    min-width: 50%;
    border: none;
    border-radius: 0;
    padding-left: 0;

    &:focus {
      box-shadow: none;
    }
  }

  .hashtag-item {
    display: flex;
    align-items: center;
    margin: 4px 0;
    margin-right: 10px;
    padding: 0 10px;
    padding-right: 5px;
    border: 1px solid var(--bs-gray-600);
    border-radius: 5px;
    background-color: var(--bs-gray-500);
    white-space: nowrap;
    color: white;

    .hashtag__btn-remove {
      line-height: 0;
      color: white;
      padding: 0;
      margin-left: 4px;
    }
  }
}

.title-form {
  text-align: left;
  font-weight: 700;
  font-size: 32px;
  letter-spacing: 0px;
  color: #484848;
  opacity: 1;
  margin-bottom: 32px;

  @media all and (max-width: 767px) {
    font-size: 26px;
  }

  span {
    text-align: left;
    display: block;
    margin-top: 12px;
    font-size: 16px;
    font-weight: 400;

    @media all and (max-width: 767px) {
      margin-top: 8px;
    }
  }
}

.input-group > .search-input {
  border-right: none !important;
}

.input-group > .search-btn {
  border-left: none !important;
}
.input-group > .search-input:active ~ .search-btn {
    box-shadow: none;
    border-color: #51d6bc;
}
.input-group > .search-input:focus ~ .search-btn {
  box-shadow: none;
  border-color: #51d6bc;
}

.row.item {
  margin-bottom: 32px;
}

.btn.amazing-green {
  background: #54dbc2 0% 0% no-repeat padding-box;
  border: 3px solid #54dbc2;
  border-radius: 8px;
  opacity: 1;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 1.44px;
  color: #ffffff;
  white-space: nowrap;
}

.btn.amazing-outline-white {
  border: 3px solid #54dbc2;
  border-radius: 8px;
  font-weight: 700;
  font-size: 18px;
  text-align: left;
  letter-spacing: 0px;
  color: #54dbc2;

  svg {
    margin-right: 5px;
  }

  &:disabled {
    border-color: #919191;
    color: #919191;
  }
}

.mt-btn-form {
  margin-top: 130px;

  @media all and (max-width: 767px) {
    margin-top: 16px;
  }
}

.mt-btn-cf-form {
  margin-top: 55px;

  @media all and (max-width: 767px) {
    margin-top: 16px;
  }
}

.col-lg-6-custom {
  @media all and (min-width: 1400px) {
    width: 445.98px;
    padding: 0 20px;
  }
}

.form-style-1 {
  .title-form {
    margin-bottom: 33px;
    margin-top: 10px;

    @media all and (max-width: 767px) {
      margin-bottom: 24px;
    }
  }

  .label-required {
    margin-left: 6px;
    color: #54dbc2;
  }

  .form-label {
    text-align: left;
    letter-spacing: 0px;
    color: #919191;
    opacity: 1;
  }

  div.form-label {
    text-align: left;
    letter-spacing: 0px;
    color: #919191;
    opacity: 1;
  }

  .bm-ct-18 {
    margin-bottom: 18px;
  }

  .bm-26-lg {
    @media all and (min-width: 992px) {
      margin-bottom: 26px;
    }
  }

  .elm-mt-lg-42 {
    margin-top: 42px;

    label.form-label + div {
      @media all and (min-width: 992px) {
        margin-top: -5px;
      }
    }
  }

  .pt-md-45 {
    @media all and (min-width: 992px) {
      padding-top: 45px;
    }

    @media all and (max-width: 991px) {
      padding-top: 20px;
    }
  }

  .form-control {
    font-size: 16px;
    color: #484848;
    border: 1px solid #919191;
    border-radius: 8px;
    text-align: left;
    opacity: 1;
    padding: 12px 24px;

    &:focus,
    &:active {
      box-shadow: none;
      border-color: #51d6bc;
    }

    &:disabled {
      border-color: #c8c8c8;
      color: #c8c8c8;
      background-color: #ffffff;
      cursor: not-allowed;
    }

    &.is-invalid {
      background-image: none;
    }
  }

  .input-group-password {
    input {
      border-right: 0;
    }

    .input-group-text {
      background-color: white;
      border-color: #919191;
      border-radius: 8px;
  
      &:hover,
      &:active {
        color: #51D6BC;
      }
    }

    .form-control:focus ~ .input-group-text {
      border-color: #51D6BC;
    }

    .form-control.is-invalid ~ .input-group-text {
      border-color: #d869db;
      background-color: #f1def4;
      border-top-right-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
    }
  }

  &.form-input-rounded-0 {
    .input-group-password {
      input {
        border-radius: 0 !important;
      }

      .input-group-text {
        border-radius: 0 !important;
      }

      .form-control.is-invalid ~ .input-group-text {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }
  }

  textarea.form-control {
    padding: 15px 22px;
    line-height: 20px;
  }

  .form-control.form-select {
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%238B8E8D%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e');

    &:invalid {
      color: #cdd6dd;
    }

    &:first-child {
      color: #cdd6dd;
    }

    &:invalid option:not(:first-child) {
      color: black;
    }

    &:focus {
      box-shadow: none;
      border-color: #51d6bc;
    }
  }

  .form-select.is-invalid {
    &:not([multiple]) {
      &:not([size]) {
        background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%238B8E8D%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e'),
          none;
      }
    }

    &:not([multiple])[size='1'] {
      background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%238B8E8D%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e'),
        none;
    }
  }

  .was-validated {
    .form-select {
      &:invalid {
        &:not([multiple]) {
          &:not([size]) {
            background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%238B8E8D%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e'),
              none;
          }
        }

        &:not([multiple])[size='1'] {
          background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%238B8E8D%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e'),
            none;
        }
      }
    }
  }

  select.form-control {
    height: 50px;
  }

  .form-check.form-check-inline {
    padding-left: 0;
    margin: 12px 38px 12px 0;
    align-items: center;
    display: inline-flex;

    @media all and (max-width: 414px) {
      width: 100%;
      margin-right: 0;
    }

    .form-check-input {
      width: 32px;
      height: 32px;
      border: 1px solid #919191;
      border-radius: 8px;
      opacity: 1;
      margin: 0 8px 0 0;
      padding: 14.88px;
      cursor: pointer;

      &:checked[type='checkbox'] {
        background-color: #51d6bc;
      }

      &:checked[type='radio'] {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='%2351D6BC'/></svg>");
        background-color: #fff;
      }

      &:focus {
        box-shadow: 0 0 0 0.25rem rgb(81 214 188 / 25%);
      }
    }

    .form-check-input[type='radio'] {
      border-radius: 100%;
    }

    label {
      font-size: 18px;
      color: #919191;
      cursor: pointer;
    }
  }

  .form-check-radio {
    .form-check.form-check-inline {
      margin: 6px 30px 6px 0;
    }
  }

  li {
    padding-bottom: 15px;

    &:last-child {
      padding-bottom: 0;
    }

    @media all and (max-width: 767px) {
      padding-bottom: 4px;
    }
  }

  .form-control.is-invalid {
    background-color: #f1def4;
    border-color: #d869db;
  }

  .invalid-feedback {
    color: #d869db;
    margin-top: 0.5rem;
    position: relative;
    padding-left: 16px;

    &:before {
      content: '';
      position: absolute;
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill=%27%23D869DB%27 class="bi bi-x-circle-fill" viewBox="0 0 16 16"> <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/> </svg>');
      width: 15px;
      height: 15px;
      left: 0;
      top: 2px;
    }
  }

  input[type='date']::-webkit-calendar-picker-indicator {
    background: url('../../../../assets/svg/datetime-icon.svg') no-repeat;
    width: 20px;
    height: 20px;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  ::-webkit-input-placeholder {
    /* Edge */
    color: #c8c8c8;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #c8c8c8;
  }

  ::placeholder {
    color: #c8c8c8;
  }
  &.project-create-page {
    .form-label {
      color: #484848;
    }
  }
}

.create-project__search-user-form {
  .form-style-1 {
    .form-label {
      color: #484848;
    }
  }
}
