.client__footer-main {
  background-color: #4F4F4F;
  padding: 13px 0;

  @media (max-width: 767px) {
    padding-bottom: 45px;
  }

  .client-footer__body {
    min-height: 57px;

    .footer__logo-title {
      margin-top: 3px;
      margin-bottom: 8px;
      padding-bottom: 8px;
      font-size: 32px;
      color: white;
      font-weight: 700;
    }

    .client-footer__main-nav {
			justify-content: flex-end !important;
			flex-wrap: wrap;
			
      // Mobile and Ipad
      @media screen and (max-width: 767px) {
				margin-top: 20px;
        justify-content: flex-start !important;
      }
    }

    .client-footer__nav {
      margin-left: 40px;
			
      &:first-child {
        margin-left: 0;
      }
	  @media screen and (max-width: 525px) {
		width: 100%;
	  }
	  
	  @media screen and (max-width: 767px) {
		margin-left: 0;
		margin-right: 40px;
		&:last-child {
			margin-left: 0;
			margin-right: 0;
		}
	   }

      li {
        font-size: 14px;

		span {
			color: white;
		}

        .nav-link {
          padding: 4px 0;
          color: white;
          display: flex;
          align-items: center;

          svg {
            margin-left: 4px;
          }

          &:hover {
            color: $color-gray-500;
          }
        }
      }
    }
  }

  .text-copyright {
    font-size: 12px;
    color: $color-gray-500;
    margin-bottom: 0;
    position: absolute;
    left: 0;
    bottom: 0;

    @media (max-width: 1500px) {
      left: 16px;
    }

    @media (max-width: 767px) {
      bottom: -40px;
    }
  }
}