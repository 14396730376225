.transfer-points-page {
    padding-top: 6vw;
    .earned-info__container {
        margin-top: 40px;
        .box-style-1__border {
            margin-top: 20px;
            margin-bottom: 40px;
        }
    }
    .transfer_points__container {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
        padding: 40px calc(22.4px + 4vw) 50px;
        .my-points-value-group {
            text-align: center;
            .transfer-points__box-value {
                span {
                    &:first-child {
                        color: #484848;
                        font-size: 70px;
                        line-height: 61px;
                        font-weight: 700;
												@media (max-width: 425px) {
													font-size: 60px;
													word-break: break-all;
												}
                    }
                    &:last-child {
                        font-size: 22px;
                        margin-left: 12px;
                        margin-top: 8px;
                    }
                }
            }
        }
        .earned-points__btn {
            padding: 11px 62px;
            white-space: nowrap;
        }
    }
    .earned-list__container {
        .earned-list__body {
            margin-top: 10px;
            border-top: 2px solid #c8c8c8;
            .earned-list__item {
                align-items: center;
                border-bottom: 1px solid #c8c8c8;
                display: flex;
                justify-content: space-between;
                padding: 16px 30px;

                .earned-list__box-history {
                    p {
                        &:first-child {
                            font-size: 14px;
                            color: #919191;
                        }
                        &:last-child {
                            font-size: 14px;
                            color: #242424;
                        }
                    }
                }
                .earned-list__box-value {
                    font-size: 18px;
                    color: #242424;
                }
            }
        }
    }
}

.transfer-modal-value-group {
    align-items: center;
    display: flex;
    .transfer-modal__name {
        font-size: 32px;
        color: #484848;
    }
    .transfer-modal__box-value {
        span {
            &:first-child {
                color: #484848;
                font-size: 70px;
                line-height: 61px;
                font-weight: 700;
                margin-left: 50px;
            }
            &:last-child {
                font-size: 32px;
                margin-left: 12px;
                margin-top: 8px;
                color: #2C3331;
            }
        }
    }
    label {
        font-size: 22px;
        color: #2C3331;
    }
}
.confirmed-earned-points__btn {
    padding: 11px 108px;
    white-space: nowrap;
    margin-bottom: 30px;
}

@media all and (max-width: 1500px) {
    .transfer-points-page {
        .transfer_points__container {
            padding: 40px 20px 50px;
        }
    }
}

@media all and (max-width: 1079px) {
    .transfer-modal {
        text-align:center
    }
    .transfer-modal-value-group {
        flex-direction: column;  
        .transfer-modal__box-value {
                span {
                    &:first-child {
                        margin-left: 0;
                    }
                
                }
        }
    }
}

@media all and (max-width: 900px) {
    .transfer-points-page {
        margin-top: 30px;
    }
}
@media all and (max-width: 820px) {
    .transfer-points-page {
        .earned-list__container {
            .earned-list__body {
                .earned-list__item {
                    padding: 16px 0px;
                }
            }
        }
        .transfer_points__container {
            flex-direction: column;
                .my-points-value-group {
                    text-align: center;
                }
                .my-points-intro {
                    margin-bottom: 25px;
                    margin-top: 25px;
                }
        }
        
    }
}

@media all and (max-width: 753px) {
    .transfer-points-page {
        margin-top: 100px;
    }
}

@media all and (max-width: 703px) {
    .transfer-points-page {
        margin-top: 180px;
    }
}

@media all and (max-width: 567px) {
    .transfer-points-page {
        margin-top: 30px;
    }
}

@media all and (max-width: 541px) {
    .transfer-points-page {
        margin-top: 55px;
    }
}

@media all and (max-width: 499px) {
    .transfer-points-page {
        margin-top: 100px;
    }
}


@media all and (max-width: 374px) {
    .transfer-points-page {
        .transfer_points__container {
            .earned-points__btn {
                padding: 11px 40px;
            }
        }
    }
    .confirmed-earned-points__btn {
        padding: 11px 40px;
    }
}
