.user-statistical {
  margin-top: 70px;
}
.project-statistical {
  margin-top: 90px;
  .project-statistical-inner {
    display: flex;
    justify-content: space-between;
  }
  .project-statistical-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 160px;
    max-width: 23%;
    background: #62dec7;
    border-radius: 8px;
    padding: 15px 0;
    .number {
      min-width: 35px;
      aspect-ratio: 1 / 1;
      letter-spacing: -1px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #62dec7;
      background: white;
      border-radius: 50%;
      font-weight: 500;
      font-size: 24px;
      margin-bottom: 15px;
    }
    .type-text {
      font-size: 18px;
      color: white;
    }
  }
  @media (max-width: 1199px) {
    margin-top: 0;
  }
  @media (max-width: 991px) {
    .project-statistical-item {
      padding: 10px 0;
      .number {
        font-size: 20px;
        margin-bottom: 10px;
      }
      .type-text {
        font-size: 15px;
      }
    }
  }
}
.reward-statistical {
  .reward-statistical-inner {
    background: #f4f9f8;
    border-radius: 8px;
    padding: 30px 15px 40px 15px;
  }
  .block-title {
    font-size: 24px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 20px;
    .align-middle {
      display: inline-block;
      margin-top: -12px;
    }
  }
  .reward-statistical-item {
    width: 140px;
    max-width: 32%;
    height: 140px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.3);
    .type-text {
      font-size: 14px;
    }
    .reward-point {
      strong {
        font-size: 22px;
      }
      small {
        font-size: 14px;
      }
    }
  }
  @media (max-width: 1199px) {
    margin-top: 50px;
  }
  @media (max-width: 991px) {
    .reward-statistical-item {
      height: auto;
      padding: 10px 0 5px;
      .icon-svg {
        margin-top: 5px !important;
        margin-bottom: 0px !important;
      }
      .reward-point {
        strong {
          font-size: 20px;
        }
        small {
          font-size: 13px;
        }
      }
    }
  }
}
.my-pages {
  .alert-box {
    font-size: 14px;
    background-color: #f4f9f8;
  }
  .transfer-points-page {
    padding-top: 70px;

    @media all and (max-width: 499px) {
      margin-top: 0;
    }
  }
  .user-rank {
    .teddybear-rating {
      img {
        padding: 0 2px;
      }
    }
  }
  @media (max-width: 991px) {
    .mypage-user-info-left__card {
      .card-body {
        margin: 0;
      }
      .my-point {
        img {
          width: 40px;
        }
        strong {
          font-size: 30px;
        }
        small {
          font-size: 24px;
        }
      }
    }
    .user-verify {
      font-size: 13px;
    }
  }
  @media (max-width: 767px) {
    .tiktok-avatar {
      width: 80px;
      height: 80px;
    }
    .mypage-user-info-left__card {
      .card-right-top {
        display: block !important;
      }
      .tiktok-name {
        text-align: left;
        margin-bottom: 5px !important;
      }
      .card-right {
        .my-point {
          img {
            width: 25px;
          }
          strong {
            font-size: 20px;
          }
          small {
            font-size: 16px;
          }
        }
      }
    }
    .my-review {
      margin: 0 0 10px !important;
    }
    .user-rank {
      display: flex !important;
      align-items: center;
      margin-bottom: 10px;
      .label-icon {
        width: 20px;
      }
      .my-point {
        margin-right: 0 !important;
      }
    }
    .reward-page {
      margin-top: 100px;
      .page-title {
        font-size: 22px;
      }
      .client-cpanel__page-title-after {
        font-size: 32px;
      }
      .outline-wrapper {
        font-size: 16px;
        padding: 30px 15px;
        .latest-update {
          font-size: 40px;
          display: block;
        }
        .reward-warning{
          font-size: 14px;
        }
        button {
          margin-top: 30px;
        }
      }
    }
  }
}

.my-page-company, .my-page-npo {
  .project-statistical {
    max-width: 450px;
    margin: 0 auto;
    .project-statistical-item {
      max-width: 31%;
    }
  }
}
