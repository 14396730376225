$menu-navbar-height: 112px;
$title-margin-top: 60px;
$menu-navbar-responsive-height: 80px;
$main-footer-height: 142px;

$w-sidebar: 304px;

// Colors
$color-blue-500: #0060e3;
$color-blue-600: #004bb1;

$color-green-500: #54DBC2;
$color-gray-500: #919191;

// width container
$width-container: 1270px;
$w-container-1248: 1248px;

// paddings
$container-1500--px: 16px;
$container-1456--px: 8px;

// icon
$icon-carret: url('../../../../assets/svg/triangle-ic.svg');
$icon-carret-hover: url('../../../../assets/svg/triangle-hover-ic.svg');
$icon-carret-active: url('../../../../assets/svg/triangle-active-ic.svg');
$icon-carret-disable: url('../../../../assets/svg/triangle-disable-ic.svg');

$pg-icon-prev: url('../../../../assets/svg/prev-ic.svg');
$pg-icon-prev-hover: url('../../../../assets/svg/prev-hover-ic.svg');
$pg-icon-prev-active: url('../../../../assets/svg/prev-active-ic.svg');
$pg-icon-prev-disable: url('../../../../assets/svg/prev-disable-ic.svg');

$pg-icon-next: url('../../../../assets/svg/next-ic.svg');
$pg-icon-next-hover: url('../../../../assets/svg/next-hover-ic.svg');
$pg-icon-next-active: url('../../../../assets/svg/next-active-ic.svg');
$pg-icon-next-disable: url('../../../../assets/svg/next-disable-ic.svg');

$icon-point-green: url('../../../../assets/svg/point-green-ic.svg');
$icon-point-white: url('../../../../assets/svg/point-white-ic.svg');

$icon-project-type: url('../../../../assets/svg/type-ic.svg');
$icon-open-link: url('../../../../assets/svg/open-link-ic.svg');
$icon-location: url('../../../../assets/svg/location-ic.svg');