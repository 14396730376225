.admin-login-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $color-green-300;

  .admin-login__card {
    border: none;
    width: 340px;
    background-color: transparent;

    .card-header {
      background-color: transparent;
      border-bottom: none;
      text-align: center;
      color: #242424;
      font-size: 28px;
      font-weight: bold;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 46px;
    }

    .card-body {
      padding: 0;
    }
  }
}