.page-list-talk-room {
    .subtab-action {
        margin-top: 25px;
        margin-bottom: 60px;
        .type-button {
            margin-right: 30px;
        }
    }
    .list-room {
        border-top: 1px solid #ced4da;
        .talk-room-item {
            border-bottom: 1px solid #ced4da;
            text-align: left;
            width: 100%;
            padding: 0px;
            .room-status {
                width: 165px;
                height: 35px;
                background: #cdf1ea;
                border-radius: 60px;
                margin-right: 15px;
                &.status-0 {
                    background-color: #54DBC2;
                    color: #FFFFFF;
                }
                &.status-1 {
                    background-color: #CDF1EA;
                    color: #919191;
                }
                &.status-2 {
                    background-color: #CDF1EA;
                    color: #919191;
                }
              
                &.status-3 {
                    background-color: #c8c8c8;
                    color: #919191;
                }
              
                &.status-4 {
                    background-color: #54dbc2;
                    color: #ffffff;
                }
              
                &.status-5 {
                    background-color: #d869db;
                    color: #ffffff;
                }
              
                &.status-6 {
                    background-color: #54DBC2;
                    color: #FFFFFF;
                }
              
                &.status-7 {
                    background-color: #f1def4;
                    color: #d869db;
                }
              
                &.status-8 {
                    background-color: #c8c8c8;
                    color: #919191;
                }
                &.status-9 {
                    background-color: #f1def4;
                    color: #d869db;
                }
            }
            .rounded-circle {
                margin-right: 40px;
            }
            .nickname{
                margin-bottom: 0;
            }
            .appoint-date {
                color: #919191;
                b {
                    font-size: 22px;
                    color: #2c3331;
                }
            }
            .latest-time {
                color:#919191;
            }
            .latest-note {
                color: #919191;
                margin-bottom: 0;
            }
            .latest-msg {
                display: -webkit-box;
                max-width: 100%;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
            .content-display-right{
                width: 100%;
                .paid-reward {
                    right: 0;
                    top: -5px;
                    padding: 5px;
                    width: 200px;
                    border-radius: 10px;
                    background-color: #F9F2FB; 
                    .points {
                        span {
                            margin-left: 20px;
                        }
                    }
                    .fsize-32 {
                        font-size: 22px;
                        font-weight: 700;
                        color: #484848;
                    }
                }
            }
        }
        .talk-room-link {
            width: 100%;
            padding: 0px;
        }
    }
}
@media (max-width: 1500px) {
    .page-list-talk-room {
        .radio-button {
            label {
                font-size: 15px;
                padding: 8px 15px;
            }
        }
    }
}
@media all and (max-width: 870px) {
    .page-list-talk-room {
        .amazing-tabs {
            overflow-y: hidden;
            flex-wrap: nowrap;
            white-space: nowrap;
            border-bottom-width: 0;
            .nav-link.active::after {
                content: none;
            }
            .nav-item {
                border-bottom: 4px solid #dee2e6;
            }
            .nav-item:has(.nav-link.active) {
                border-image-source: linear-gradient(90deg, #54dbc2 0%, #5ebfe7 52%, #d869db 100%);
                border-bottom: 1px solid;
                border-image-slice: 1;
                border-bottom-width: 5px;
            }
        }
        .subtab-action {
            overflow-y: hidden;
            white-space: nowrap;
            margin-bottom: 25px;
        }
        .subtab-action::-webkit-scrollbar,
        .amazing-tabs::-webkit-scrollbar
         {
            display: none; /* for Chrome, Safari, and Opera */
          }
    }
  }

  @media all and (max-width: 630px) {
    .page-list-talk-room {
        .list-room {
            .talk-room-item {
                .mobile-display {
                    display: block !important;
                }
                .rounded-circle {
                    width: 60px;
                    height: 60px;
                    margin-right: 16px;
                    float: left;
                }
                .room-status {
                    width: 132px;
                    height: 32px;
                    font-size: 14px;
                }
                .appoint-date {
                    display: block;
                    font-size: 14px;
                    b {
                        font-size: 18px;
                    }
                }
                .item-title {
                    font-size: 18px;
                }
                .nickname{
                    float: left;
                    margin-right: 10px;
                }
                .latest-time {
                    padding-bottom: 0;
                    margin-bottom: 5px;
                    font-size: 14px;
                }
                .latest-msg {
                    -webkit-line-clamp: 2;
                    margin-bottom: 5px;
                    font-size: 14px;
                }
                .latest-note {
                    font-size: 14px;
                }
                .user-rank-area {
                    height: 30px;
                    line-height: 30px;
                    &.ml-40{
                        margin-left: 0px;
                      }
                    .icon {
                      text-align: left;
                      z-index: 0;
                      position: relative;
                      .icon-svg.user-rank-svg {
                        width: 42px;
                        height: 30px;
                      }
                    }   
                    .verify-color {
                        padding: 2px 6px;
                        line-height: 14px;
                        font-size: 12px;
                    }
                    .teddybear-rating {
                      img {
                        width: 24px;
                      }
                    }
                }
            }

        }
    }
  }
  @media all and (max-width: 480px) {
    .page-list-talk-room {
        .list-room {
            .talk-room-item {
                .content-display-right{
                    .paid-reward {
                        top: 0px;
                        width: 120px;
                        .points {
                            span {
                                margin-left: 10px;
                            }
                            .point-svg{
                                height: 20px;
                                width: 20px;
                            }
                        }
                        .fsize-32 {
                            font-size: 16px;
                        }
                    }
                    .user-rank-area {
                        height: 30px;
                        line-height: 30px;
                        .icon {
                          .icon-svg.user-rank-svg {
                            width: 42px;
                            height: 30px;
                          }
                        }   
                        .verify-color {
                            padding: 2px 6px;
                            line-height: 14px;
                            font-size: 12px;
                        }
                        .teddybear-rating {
                          img {
                            width: 24px;
                          }
                        }
                    }
                }
            }

        }
    }
  }