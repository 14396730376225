.project-list-table .action-column {
    position: relative;
    z-index: 100;
}

.project-list-modal {
    .modal-header {
        border: 0;
    }
    .modal-footer {
        border: 0;
    }
}