.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-input-group {
  position: relative;
  /* 44px */
}

.react-datepicker-input-group .input-group-text {
  position: absolute;
  top: 1px;
  right: 1px;
  padding: 15px;
  border: none;
  border-radius: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: transparent;
  pointer-events: none;
}

.react-datepicker-input-group .form-control {
  padding: 12px 24px;
  padding-right: 48px;
  border-radius: 8px;
}

.react-datepicker-input-group .form-control:focus {
  box-shadow: none;
  border-color: #51D6BC;
}

.react-datepicker-popper-custom-1 .react-datepicker {
  box-shadow: 0 3px 8px #307e6e3d;
  border: none;
}

.react-datepicker-popper-custom-1 .react-datepicker__month-container {
  font-size: 1rem;
}

.react-datepicker-popper-custom-1 .react-datepicker__header {
  border-bottom: none;
  background-color: #54DBC2;
  padding-top: 8px;
  padding-bottom: 8px;
}

.react-datepicker-popper-custom-1 .react-datepicker__navigation-icon--previous::before,
.react-datepicker-popper-custom-1 .react-datepicker__navigation-icon--next::before {
  top: 12px;
  border-color: white;
}

.react-datepicker-popper-custom-1 .react-datepicker__current-month {
  color: white;
  font-size: 16px;
  padding-bottom: 8px;
}

.react-datepicker-popper-custom-1 .react-datepicker__day-names {
  background-color: white;
  padding-top: 8px;
}

.react-datepicker-popper-custom-1 .react-datepicker__day--outside-month {
  color: #C8C8C8;
}

.react-datepicker-popper-custom-1 .react-datepicker__month {
  margin: 0;
}

.react-datepicker-popper-custom-1 .react-datepicker__day-name:nth-child(6),
.react-datepicker-popper-custom-1 .react-datepicker__day-name:nth-child(7) {
  color: magenta;
}

.datepicker_start-sunday .react-datepicker-popper-custom-1 .react-datepicker__day-name:nth-child(1) {
	color: magenta;
}

.datepicker_start-sunday .react-datepicker-popper-custom-1 .react-datepicker__day-name:nth-child(6) {
	color: unset;
}

.react-datepicker-popper-custom-1 .react-datepicker__day-name,
.react-datepicker-popper-custom-1 .react-datepicker__day,
.react-datepicker-popper-custom-1 .react-datepicker__time-name {
  margin: 0;
  width: 40px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.react-datepicker-popper-custom-1 .react-datepicker__day--keyboard-selected,
.react-datepicker-popper-custom-1 .react-datepicker__month-text--keyboard-selected,
.react-datepicker-popper-custom-1 .react-datepicker__quarter-text--keyboard-selected,
.react-datepicker-popper-custom-1 .react-datepicker__year-text--keyboard-selected {
  background-color: #CDF1EA;
  color: #484848;
}

.react-datepicker-popper-custom-1 .react-datepicker__day--selected,
.react-datepicker-popper-custom-1 .react-datepicker__day--in-selecting-range,
.react-datepicker-popper-custom-1 .react-datepicker__day--in-range,
.react-datepicker-popper-custom-1 .react-datepicker__month-text--selected,
.react-datepicker-popper-custom-1 .react-datepicker__month-text--in-selecting-range,
.react-datepicker-popper-custom-1 .react-datepicker__month-text--in-range,
.react-datepicker-popper-custom-1 .react-datepicker__quarter-text--selected,
.react-datepicker-popper-custom-1 .react-datepicker__quarter-text--in-selecting-range,
.react-datepicker-popper-custom-1 .react-datepicker__quarter-text--in-range,
.react-datepicker-popper-custom-1 .react-datepicker__year-text--selected,
.react-datepicker-popper-custom-1 .react-datepicker__year-text--in-selecting-range,
.react-datepicker-popper-custom-1 .react-datepicker__year-text--in-range {
  background-color: #CDF1EA;
  color: #484848;
}