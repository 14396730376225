.transfer-history__btn-group {
  width: 100%;
  height: 100%;

  .btn {
    background-color: white;
    border-radius: 0;
    border: none;
    border-bottom: 4px solid #F4F9F8;
    width: 50%;
    transition: all .2s;

    &:hover {
      border-bottom-color: #72B6A9;
    }
  }

  .btn-check:checked+.btn {
    border-radius: 0;
    border-bottom-color: #54DBC2;
  }
}