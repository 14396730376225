.admin-content {
  padding-top: $h-header;
  width: calc(100% - $w-sidebar);
  margin-left: $w-sidebar;

  @media (max-width: 1199px) {
    width: 100%;
    margin-left: 0;
  }
}

.admin-pagination-custom-1 {
  .page-item {
    .page-link {
      border: 2px solid transparent;
      color: #98a6b5;
      margin: 0 5px;
      padding-top: 15px;
      padding-bottom: 15px;
      min-width: 50px;
      text-align: center;

      &:focus {
        box-shadow: none;
        background-color: unset;
      }

      @media (min-width: 320px) and (max-width: 767px) {
        padding-top: 8px;
        padding-bottom: 8px;
        min-width: 20px;
      }
    }

    &:focus .page-link {
      background-color: unset;
      box-shadow: none;
      background-color: unset;
    }

    &.active .page-link {
      background-color: unset;
      font-weight: bold;
      color: #307d6e;
      box-shadow: none;
    }

    &.next .page-link,
    &.prev .page-link {
      background-color: #fafafa;
      border: 2px solid #f0f3f5;
      border-radius: 10px;
      font-weight: bold;
      color: #307d6e;
      font-size: 36px;
      line-height: 32px;
      padding: 5px 7px;

      @media (min-width: 320px) and (max-width: 767px) {
        font-size: 24px;
        line-height: 24px;
        min-width: 20px;
      }
    }

    &:hover .page-link {
      background-color: unset;
      font-weight: bold;
      color: #307d6e;
    }

    &.disabled:hover .page-link {
      background-color: #fafafa;
    }

    &.prev.disabled,
    &.next.disabled {
      .page-link {
        background-color:#C8C8C8;
        border-color: #C8C8C8;
        color: white;
      }
    }
  }
}

.admin-toast {
  .error {
    .toast-header {
      color: #dc3545;
    }

    .toast-body {
      background: rgba(220, 53, 69, 0.3);
      border-radius: 0 0 5px 5px;
      box-shadow: 0 0.25rem 0.75rem rgb(0 0 0 / 10%);
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.page-not-found {
  min-height: 100vh;
  background-color: #F4F9F8;
}
.border-pink {
  opacity: 1;
  border-color: #F1DEF4 !important;
}

.border_bottom_2 {
  border-bottom: 2px solid #C8C8C8;
}

.min-h-100 {
  min-height: 100px;
}