.user-detail {
  .light-pink {
    background: #f9f2fb 0% 0% no-repeat padding-box;
    height: 100%;

    .flex-fill:first-child {
      width: 50%;
    }
  }

  .point-number {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 0;

    @media all and (max-width:568px) {
      font-size: 50px;
    }
  }

  .table-scroll {
    overflow-y: auto;
    height: 310px;
  }

  .rating-stars {
    svg.star {
      width: 25px;
      height: 25px;
    }
  }

  @media all and (max-width: 767px) {
    .tiktok-avatar {
      margin-top: 20px;
    }
  }
}
