.admin-btn-green {
  &.icon-only {
    border-radius: 50rem !important;
    font-size: 30px;
    padding: 0;
    width: 50px;
    height: 50px;
    line-height: 30px;
    svg {
      margin-right: 0;
    }
  }
}
.hyperlink {
  &.underline span {
    text-decoration: underline;
  }
}

.form-modal {
  .modal-body {
    padding: 0 40px 55px;
    label {
      font-size: 14px;
    }
    .form-select, 
    input:not(.is-invalid) {
      border-color: #919191;
    }
    .form-select:focus,
    input:focus {
      box-shadow: none;
      border-color: #919191;
    }
    input::placeholder {
      color: #cdd6dd;
    }
    .form-update {
      button {
        font-size: 16px;
        line-height: 30px;
        color: #ffffff;
        padding: 10px 45px;
      }
    }
    .form-control:disabled{
      background-color: #e9ecef;
    }
    .form-select:disabled{
      color: #C8C8C8;
    }
  }
}

.react-datepicker__time-container {
  width: 55px;
  .react-datepicker-time__header {
    padding-top: 1px;
    color: #fff;
  }
  .react-datepicker__time {
    .react-datepicker__time-box {
      width: 54px;
      ul.react-datepicker__time-list {
        &::-webkit-scrollbar {
          width: 6px;
          background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #f5f5f5;
          border-radius: 0px;
        }
        &::-webkit-scrollbar-thumb {
          background-image: -webkit-gradient(
            linear,
            left bottom,
            left top,
            color-stop(0.44, rgb(84, 219, 194)),
            color-stop(0.72, rgb(74, 204, 180)),
            color-stop(0.86, rgb(66, 181, 160))
          );
        }
      }
    }
  }
}
.react-datepicker__time-container {
  .react-datepicker__time {
    .react-datepicker__time-box {
      ul.react-datepicker__time-list {
        li.react-datepicker__time-list-item--selected {
          background-color: #cdf1ea;
          color: #484848;
          &:hover{
            background-color: #cdf1ea;
          }
        }
      }
    }
  }
}