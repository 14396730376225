#invitation-setting__page {
    .title__page {
        color: #4F4F4F;
        font-size: 22px;
        padding-bottom: 10px;
    }
    .custom-form__page {
        .form-control {
            padding-top: 12px;
            padding-bottom: 12px;
            border-color: #919191;
        }
        .col-form-label {
            color: #919191;
        }
    }
}