.email-verify-page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    min-height: calc(100vh - ($menu-navbar-height + $main-footer-height));

    .email-verify__loading {
        border: var(--bs-spinner-border-width) solid #54DBC2;
        border-right-color: transparent;
        --bs-spinner-width: 2.5rem;
        --bs-spinner-height: 2.5rem;
        margin-bottom: 32px;

    }

    .email-verify__icon {
        margin-bottom: 32px;

        &.success {
            color: #54DBC2;
        }

        &.failed {
            color: #DD5E89;
        }
    }


    .email-verify__title {
        margin-bottom: 32px;
        font-size: 32px;

        &.success {
            color: #54DBC2;
        }

        &.failed {
            color: #DD5E89;
        }
    }

    .email-verify__text {
        text-align: center;
        color: #919191;
        margin-bottom: 48px;
    }

    .email-verify__btn {
        padding-left: 90px;
        padding-right: 90px;
        font-size: 18px;
    }
}