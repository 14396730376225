.card-info {
    background: #F4F9F8 0% 0% no-repeat padding-box;
    border-radius: 8px;
    border: none;
    text-align: left;
    color: #919191;
}

.mypage-top__row {
    .col__left {
        flex: 0 0 auto;
        width: calc(100% - (8.5vw + 320px));
    }

    .col__right {
        flex: 0 0 auto;
        width: calc(8.5vw + 320px);
    }

    @media (min-width: 320px) and (max-width: 1024px) {
        .col__left {
            margin-bottom: 20px;
        }

        .col__left,
        .col__right {
            width: 100%;
        }
    }
}

.news__list-style {
    min-height: 167px;
    border-top: 2px solid #C8C8C8;
    margin-bottom: 20px;


    @media (min-width: 320px) and (max-width: 767px) {
        margin-top: 24px;
        border-top-width: 1px;
    }

    .news__item {
        display: flex;
        border-bottom: 1px solid #C8C8C8;
        padding: 15px 40px;

        @media (min-width: 320px) and (max-width: 767px) {
            flex-direction: column;
            padding-left: 0;
            padding-right: 0;
        }

        .news__data {
            margin-right: 42px;
            color: #919191;

            @media (min-width: 320px) and (max-width: 767px) {
                margin-bottom: 5px;
            }
        }

        .news__title {
            color: #242424;
            text-decoration: none;
        }
    }
}

.mypage-user-info-left__card {
    border: none;
    margin-bottom: 50px;
    height: auto;
    min-height: 122px;

    &:last-child {
        margin-bottom: 0;
    }
    
    .rating-stars {
      svg {
        margin-right: 5px;
      }
    }

    .tiktok-name {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
    }
    .user-verify {
        img {
            width: 18px;
        }
    }

    .card-right {
        .card-title {
            font-size: 32px;
            @media (max-width: 575px) {
                font-size: 22px;
            }
        }
        .reward-point-list {
            border-bottom: 1px solid #E8E8E8;
            strong {
                font-size: 32px;
            }
            small {
                font-size: 22px;
                font-weight: bold;
            }
            span {
                font-size: 18px;
                font-weight: bold;
            }
            @media (max-width: 575px) {
                strong {
                    font-size: 22px;
                }
                small {
                    font-size: 14px;
                }
                span {
                    font-size: 14px;
                }
            }
        }
    }

    .my-point {
        strong {
            font-size: 55px;
        }
        small {
            font-size: 38px;
            font-weight: bold;
        }
        @media (max-width: 575px) {
            img {
                width: 35px;
            }
            strong {
                font-size: 32px;
            }

            small {
                font-size: 22px;
            }
        }
    }

    .card-body {
        padding-left: calc(1.6vw + 30px);
        padding-right: calc(1.6vw + 30px);


        @media (min-width: 320px) and (max-width: 1024px) {
					padding-top: 24px;
					padding-bottom: 25px;
        }

        @media (min-width: 320px) and (max-width: 991px) {
            margin: auto;
        }

				@media (min-width: 320px) and (max-width: 767px) {
					padding-left: calc(1.6vw + 4px);
					padding-right: calc(1.6vw + 4px);
				}

        .user-info__content {
            @media (min-width: 320px) and (max-width: 767px) {
                width: 245px;
            }
        }

        .user-info__title {
            margin-bottom: 0;
            color: #2C3331;
            font-size: calc(0.1vw + 20px);
            font-weight: bold;

            @media (min-width: 1920px) {
                font-size: 22px;
            }
        }

        .user-info__value {
            font-size: calc(1.5vw + 40px);
            line-height: 60px;
            font-weight: bold;
            color: #484848;

            @media (min-width: 1920px) {
                font-size: 70px;
            }

            small {
                margin-left: 14px;
                font-size: 22px;
            }
        }

        .user-info__rating {
            svg {
                margin-right: 12px;

                &:last-of-type {
                    margin-right: 0;
                }

                @media (min-width: 320px) and (max-width: 1024px) {
                    margin-right: 4px;
                }
            }
        }
    }
}

.mypae-point__card {
	.card-body {
		padding-left: calc(1.6vw + 40px);
		padding-right: calc(1.6vw + 4px);
		width: 80% !important;
		@media (max-width: 1440px) {
			width: 100% !important;
			padding-left: calc(1.6vw + 40px);
			padding-right: calc(1.6vw + 40px);
		}
		@media (min-width: 320px) and (max-width: 991px) {
			width: fit-content !important;
			margin: auto;
			padding-left: calc(1.6vw + 4px);
			padding-right: calc(1.6vw + 4px);
		}
	}
}

.mypage-user-info-right__card {
    border: none;
    min-height: 264px;
    margin-bottom: 24px;

    &:last-child {
        margin-bottom: 0;
    }

    @media (min-width: 320px) and (max-width: 991px) {
        min-height: 169px;
    }

    .card-body {
        @media (min-width: 320px) and (max-width: 767px) {
            padding-top: 24px;
            padding-bottom: 26px;
        }

        .user-info--mw-300 {
            min-width: 300px;

            @media (min-width: 320px) and (max-width: 1024px) {
                min-width: 215px;
            }
        }

        .user-info--mw-255 {
            min-width: 255px;

            @media (min-width: 320px) and (max-width: 1024px) {
                min-width: 225px;
            }
        }
				.user-info--mw-auto {
					min-width: auto;
				}

        .user-info__title {
            font-size: calc(0.1vw + 20px);
            margin-bottom: 0;
        }

        .user-info__value {
            font-size: 32px;

            .point-icon-svg {
                position: relative;
                display: inline-block;
                width: 40px;
                height: 40px;
                margin-right: 6px;
                &::before {
                    position: absolute;
                    content: "";
                    background-size: contain;
                    background-repeat: no-repeat;
                    width: 100%;
                    height: 100%;
                    top: 50%;
                    transform: translate(0, -50%);
                    z-index: 1;
                }
            }
        }

        .rating-stars {
            min-height: 19px;
            svg {
                margin-right: 4px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .teddybear-rating {
        display: inline-flex;
        align-items: flex-end;
        img {
            margin-right: 8px;
            width: 33px;
            height: 33px;
            &.lg {
                width: 66px;
                height: 66px;

                @media (min-width: 320px) and (max-width: 767px) {
                    width: 60px;
                    height: 60px;
                }
            }
        }
    }
}

.user-info__certificate {
    color: #C8C8C8;
    &.success {
        color: #54DBC2;
        svg {
            color: #54DBC2;
        }
    }
}