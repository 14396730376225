body {
  font-family: futura-pt, 'Noto Sans JP', 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', Meiryo, sans-serif;
  font-weight: 400;
}

.bg-overlay {
  background: #f4f9f8 0% 0% no-repeat padding-box;
  opacity: 1;
  font-size: 16px;
  z-index: 1;
  position: relative;
}

.bg-overlay-mobile {
  @media all and (max-width: 425px) {
    background: #FFFFFF !important;
  }
}

.client-main-wrapper {
  min-height: calc(100vh - $main-footer-height);
}

.client-cpanel {
  position: relative !important;
  min-height: 968px;
  background: white;
  z-index: 0; // to support display border box on project creation page

  .client-cpanel__content {
    padding-top: 48px;
    margin-left: $w-sidebar;
    width: calc(100% - $w-sidebar);
    padding-bottom: 104px;

    @media (max-width: 1199px) {
      width: 100%;
      margin-left: 0;
      padding-top: 46px;
    }

    @media (min-width: 320px) and (max-width: 767px) {
      padding-top: 16px;
      padding-bottom: 50px;
    }
  }

  @media (max-width: 1199px) {
    min-height: calc(100vh - 81px);
  }
}

.loading-full__wrapper {
  min-height: calc(100vh - $menu-navbar-height);

  .spinner-lg {
    width: 3rem;
    height: 3rem;
  }
}

.d-content-flex {
  display: contents;
}

.h50 {
  height: 50px !important;
}

.h70 {
  height: 70px !important;
  @media all and (max-width: 991px) {
    height: 50px !important;
  }
}

.w287 {
  width: 287px;
}

.w260 {
  width: 260px;
}

.w160 {
  width: 160px;
}

.w180 {
  width: 180px;
}

.w208 {
  width: 208px;
}

.text-pre-line {
  white-space: pre-line;
}

.line-height--0 {
  line-height: 0;
}

.line-height--inherit {
  line-height: inherit;
}

.line-height--normal {
  line-height: normal;
}

.margin-left20 {
  margin-left: 20px;
}

.margin-top-n10 {
  margin-top: -10px;
}

.text-color-gray-custom {
  color: #4f4f4f;
}

.text-nowrap-mobile {
  white-space: nowrap !important;

  @media all and (max-width: 992px) {
    white-space: normal !important;
  }
}

.text-size-mobile {
  @media all and (max-width: 992px) {
    font-size: 14px;
    font-weight: 400;
  }
}

.title-sub {
  font-size: 22px;
  font-weight: 700;
  color: #484848;
}

.no-border {
  padding-left: 0px;
  padding-right: 0px;

  &:before {
    position: absolute;
    content: '';
    z-index: -9;
    width: 100%;
    height: 100%;
    border-radius: 0px;
    background: none !important;
    left: 0px;
    top: 0px;
  }

  &:after {
    position: absolute;
    content: '';
    z-index: -9;
    width: 100%;
    height: 100%;
    border-radius: 0px;
    background: none !important;
    left: 0px;
    top: 0px;
  }
}

.item-title {
  text-align: left;
  font-weight: 700;
  font-size: 32px;
  letter-spacing: 0px;
  color: #484848;
}

.item-sub-title {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #919191;
  margin-right: 20px;

  svg {
    margin-right: 5px;
  }
}

.main-item {
  margin-left: 10px;
  background: white;
  margin-right: 10px;
  margin-top: -50px;
  padding-top: 20px;

  @media all and (max-width: 720px) {
    margin-left: 0px;
    margin-right: 10px;
  }
}

.amazing-tabs {
  border-bottom-width: 4px;
}

.amazing-tabs .nav-item.show .nav-link,
.amazing-tabs .nav-link {
  font-size: 18px;
  letter-spacing: 0px;
  color: #cdf1ea;
  border: none !important;

  &:hover {
    border: none !important;
  }

  &:focus {
    border: none !important;
  }

  &:focus-visible {
    border: none !important;
  }
}

.amazing-tabs .nav-item.show .nav-link,
.amazing-tabs .nav-link.active {
  border: none !important;
  position: relative;
  z-index: 1;
  font-size: 18px;
  letter-spacing: 0px;
  color: #54dbc2;
  background-color: transparent;

  &:after {
    position: absolute;
    content: '';
    z-index: -9;
    width: 100%;
    height: 4px;
    background: transparent linear-gradient(90deg, #54dbc2 0%, #5ebfe7 52%, #d869db 100%) 0% 0% no-repeat padding-box;
    right: 0px;
    bottom: -3px;
  }
}

.amazing-icon {
  margin-right: 5px;
}

.amazing-mobile-content-center {
  @media all and (max-width: 1140px) {
    justify-content: center !important;
  }
}

.item-share {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #919191;
  padding-right: 20px;

  @media all and (max-width: 960px) {
    display: block;
    padding-top: 10px;
  }
}

.item-pr {
  color: #4f4f4f;

  @media all and (max-width: 960px) {
    padding-bottom: 30px;
  }
}

.external-icon-custom {
  margin-top: -5px;
  margin-left: 5px;
}

.card-galaxy {
  background: #ffffff;
  border-radius: 8px;
  opacity: 1;
  position: relative;
  z-index: 1;
  margin-bottom: 20px;

  &:before {
    position: absolute;
    content: '';
    z-index: -9;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background: #cdf1ea 0% 0% no-repeat padding-box;
    left: -3px;
    top: -3px;
  }

  &:after {
    position: absolute;
    content: '';
    z-index: -9;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background: #f1def4 0% 0% no-repeat padding-box;
    right: -3px;
    bottom: -3px;
  }

  .card-galaxy-body {
    background: #ffffff;
    padding: 40px;
    border-radius: 8px;

    @media all and (max-width: 960px) {
      padding-left: 5px;
      padding-right: 5px;
      padding-top: 20px;
    }

    .item-info {
      width: 100%;
      margin-left: 20px;

      @media all and (max-width: 960px) {
        margin-left: 0px !important;
        margin-top: 20px;
      }

      a {
        text-decoration: unset !important;
      }
    }

    .item-title {
      text-align: left;
      font: normal normal bold 32px/30px 'Noto Sans JP';
      letter-spacing: 0px;
      color: #484848;

      @media (min-width: 1141px) and (max-width: 1500px) {
        max-width: 380px;
      }
    }

    .item-sub-title {
      text-align: left;
      font: normal normal normal 16px/14px 'Noto Sans JP';
      letter-spacing: 0px;
      color: #919191;
      margin-right: 20px;

      svg {
        margin-right: 5px;
      }

      li {
        padding-bottom: 10px;
      }
    }

    .toolbar {
      @media all and (max-width: 1140px) {
        justify-content: center !important;
        transform: translateX(-50%) !important;
        left: 50% !important;
        bottom: 0 !important;
        position: absolute !important;
        padding-bottom: 10px;
      }
    }
  }

  .card-galaxy-body.bg-gray {
    background: #f2f2f2;
  }
}

.padding-bottom20 {
  padding-bottom: 20px !important;
}

.main-container {
  background: #ffffff;
  border-radius: 8px;
  opacity: 1;
  padding-top: 44px;
  padding-bottom: 92px;
  margin-top: 64px;
  position: relative;

  &:before {
    position: absolute;
    content: '';
    z-index: -9;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background: #cdf1ea 0% 0% no-repeat padding-box;
    left: -4px;
    top: -4px;
  }

  &:after {
    position: absolute;
    content: '';
    z-index: -9;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background: #f1def4 0% 0% no-repeat padding-box;
    right: -4px;
    bottom: -4px;
  }

  .row--bs-gutter-2 {
    @media all and (max-width: 767px) {
      --bs-gutter-x: 2rem;
    }
  }

  .form__wrapper {
    padding: 0;

    @media all and (min-width: 1300px) {
      width: 890px;
    }
  }

  @media all and (min-width: 1700px) {
    max-width: $width-container;
  }

  @media all and (max-width: 1700px) {
    width: 93%;
    padding-top: 16px;
    padding-bottom: 24px;
  }

  @media all and (max-width: 575px) {
    margin-top: 20px;
    width: 93%;
    padding-top: 16px;
    padding-bottom: 24px;
  }
}

.mb-mobile-form {
  @media all and (max-width: 575px) {
    margin-bottom: 20px;
  }
}

.col-md-ct-4 {
  @media all and (min-width: 992px) and (max-width: 1300px) {
    width: 33.33333333%;
  }
}

.col-ct-12 {
  @media all and (min-width: 992px) and (max-width: 1300px) {
    width: 100%;
    margin-left: 0;
  }

  .col-child-ct-12 {
    @media all and (min-width: 992px) and (max-width: 1100px) {
      width: 100%;
    }
  }
}

.btn-tiktok-connect {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #307d6e3d;
  border-radius: 8px;
  opacity: 1;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #2c3331;
  height: 50px;
  padding: 12px 45px;
  position: relative;

  &:active {
    border-color: transparent !important;
    color: #ccc !important;

    .btn-tiktok-connect-icon {
      opacity: 0.5;
    }
  }

  &:disabled {
    background: #c8c8c8 0% 0% no-repeat padding-box;
    border-color: transparent;
    color: #fff;
  }

  @media all and (max-width: 319px) {
    padding: 12px 35px;
    font-size: 16px;
  }

  .btn-tiktok-connect-icon {
    position: absolute;
    width: 28px;
    height: 32px;
    left: 17px;
    top: 50%;
    transform: translateY(-50%);

    img {
      width: 100%;
    }

    @media all and (max-width: 319px) {
      width: 20px;
    }
  }
}

.label-icon {
  margin-left: 15px;
  color: #54dbc2;

  svg {
    width: 24px;
    height: 24px;
  }
}

.btn-upload-file {
  background: #54dbc2 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 1.44px;
  color: #ffffff;
  min-height: 50px;
  min-width: 287px;
  padding: 10px 15px;
  cursor: pointer;

  &:hover {
    background: #2de1c0 0% 0% no-repeat padding-box;
    color: #fff;
  }

  &:active {
    background: #54dbc2 0% 0% no-repeat padding-box !important;
    color: #fff !important;
    opacity: 0.6;
    border-color: none;
  }

  @media all and (max-width: 567px) {
    width: 100%;
    min-width: auto;
  }
}

.avatar-300 {
  width: 300px;
  height: 300px;
}

.tiktok-avatar {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  overflow: hidden;
  border: 1px solid #707070;
  opacity: 1;

  img {
    width: 100%;
  }
}

.tiktok-info {
  text-align: left;
  letter-spacing: 0px;
  color: #919191;
  opacity: 1;

  .d-flex.flex-wrap {
    .flex-fill {
      padding: 10px 0;
    }
  }
}

.signup-confirm-page {
  .tiktok-info {
    .d-flex.flex-wrap {
      .flex-fill {
        @media (min-width: 1300px) {
          &:first-child {
            width: 70%;
          }

          &:last-child {
            width: 30%;
          }
        }

        @media (max-width: 1299px) {
          &:first-child {
            width: calc(100% - 100px);
          }
          &:last-child {
            width: 100px;
          }
        }
      }
    }
  }
}

.section {
  .header {
    position: relative;

    .title-bg {
      color: #54dbc2;
      text-shadow: 2px 1px 0px #c87bd2;
      opacity: 0.3;
      font-size: 62px;
      line-height: 15px;
      font-family: 'futura-pt';
      margin-bottom: 0;
      letter-spacing: 6.2px;
      user-select: none;

      @media all and (max-width: 575px) {
        font-size: 48px;
        line-height: 48px;
        letter-spacing: 4.8px;
      }
    }

    .title {
      color: #484848;
      margin-bottom: 0px;
      font-weight: bold;
      font-size: 32px;
      line-height: 30px;
      font-family: Noto Sans JP;
      position: relative;

      @media all and (max-width: 575px) {
        font-size: 26px;
        line-height: 15px;
      }
    }
  }

  .d-flex.flex-wrap {
    .flex-fill {
      padding: 12px 0;
    }

    @media all and (min-width: 1300px) {
      flex-wrap: nowrap !important;

      .flex-fill {
        &:first-child {
          width: 310px;
          max-width: 310px;
        }
      }
    }

    @media all and (min-width: 768px) and (max-width: 992px) {
      .flex-fill {
        &:first-child {
          max-width: 215px;
        }
      }
    }

    @media all and (max-width: 768px) {
      .flex-fill {
        &:first-child {
          max-width: 260px;
        }
      }
    }

    @media all and (max-width: 375px) {
      .flex-fill {
        padding: 5px 0;
      }
    }
  }
}

.group-item-border {
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #c8c8c8;
  margin-left: 0;
  margin-right: 0;

  @media all and (max-width: 767px) {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  >div {
    padding-left: 0;
    padding-right: 0;
  }

  ul {
    padding-left: 0;
  }

  li {
    list-style-type: none;
    padding: 4px 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
}

.align-center-wrap {
  position: relative;
  height: calc(74vh - 95px);

  .position-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
}

.title-section {
  font-weight: 700;
  font-size: 32px;
  letter-spacing: 1.6px;
  color: #2c3331;
  opacity: 1;

  @media all and (max-width: 991px) {
    font-size: 26px;
  }
}

.title-section-sub {
  font-size: 16px;
  letter-spacing: 0.8px;
  color: #919191;
  opacity: 1;
}

.radio-button {
  input[type='radio'] {
    opacity: 0;
    position: fixed;
    width: 0;
    cursor: pointer;
  }

  label {
    cursor: pointer;
    display: inline-block;
    padding: 8px 20px;
    font-size: 16px;
    border-radius: 0.375rem;
    text-align: center;
    color: #54dbc2 !important;
    background-color: #fff;
    border: 2px solid #ced4da;
    line-height: 30px;

    &:hover {
      color: #fff !important;
      background-color: #54dbc2;
      border: 2px solid #54dbc2;
    }

	&.disabled {
		cursor: none;
		background-color: #C8C8C8;
		color: #FFFFFF !important;
		&:hover{
			color: #FFFFFF !important;
			background-color: #C8C8C8;
			border: 2px solid #ced4da;
		}
	}

    @media all and (max-width: 575px) {
      width: 100%;
    }

    @media all and (max-width: 320px) {
      font-size: 14px;
      padding: 8px 10px;
    }
  }

  input[type='radio']:checked+label {
    color: #fff !important;
    background-color: #54dbc2;
    border: 2px solid #54dbc2;
  }
}

.container__w-1248 {
  margin-top: 20px;

  @media all and (min-width: 1300px) {
    max-width: $w-container-1248;

    .form__wrapper {
      width: 896px;
    }
  }

  @media all and (min-width: 1456px) and (max-width: 1590px) {
    max-width: 95%;
  }
}

.img--br-radius {
  border-radius: 8px;
}

.list-hashtag {
  .item-hashtag {
    margin-right: 16px;
    background: #f4f9f8 0% 0% no-repeat padding-box;
    font-size: 16px;
    color: #484848;
    letter-spacing: 0px;
    display: inline-block;
    padding: 5px 12px;
    border: 2px solid #cdf1ea;
    border-radius: 30px;
    margin-top: 5px;
    margin-bottom: 5px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.list-attachments {
  .attached-item {
    font-size: 14px;
    color: #4f4f4f;
    float: left;
    width: 120px;
    margin-right: 20px;
    text-align: left;
    letter-spacing: 0;
    overflow: hidden;
    margin-bottom: 5px;

    &:last-child {
      margin-right: 0;
    }

    @media all and (max-width: 520px) {
      width: 103px;
      margin-right: 9px;
    }

    .attached-img {
      background: #f4f9f8 0% 0% no-repeat padding-box;
      width: 120px;
      height: 120px;
      border-radius: 8px;
      overflow: hidden;

      &>svg.pdf {
        height: 100%;
        width: 70%;
        margin: 0 auto;
      }

      @media all and (max-width: 520px) {
        width: 103px;
        height: 103px;
      }

      img {
        width: 100%;
      }
    }

    .attached-name {
      color: #7b7b7b;
      margin-top: 5px;
      text-decoration: underline;
    }
  }
}

.modal-amazing {
  .modal-content {
    background: #ffffff;
    border-radius: 8px;
    opacity: 1;
    padding-top: 16px;
    padding-bottom: 16px;
    position: relative;
    border: none;

    &:before {
      position: absolute;
      content: '';
      z-index: -9;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      background: #cdf1ea 0% 0% no-repeat padding-box;
      left: -3px;
      top: -3px;
    }

    &:after {
      position: absolute;
      content: '';
      z-index: -9;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      background: #f1def4 0% 0% no-repeat padding-box;
      right: -3px;
      bottom: -3px;
    }
  }

  .modal-header {
    border: none;
    display: block;
    max-width: 718px;
    margin: 0 auto;

    .btn-close {
      position: absolute;
      right: 28px;
      top: 28px;
      padding: 0;
      width: 20px;
      height: 20px;
      color: #919191;

      &:focus {
        box-shadow: none;
      }
    }
  }

  .modal-body {
    max-width: 718px;
    margin: 0 auto;

    @media all and (max-width: 991px) {
      width: 100%;
      margin: auto;
    }
  }

  .modal-title {
    font-weight: 700;
    font-size: 32px;
    color: #484848;

    @media all and (max-width: 991px) {
      font-size: 26px;
    }
  }

  .modal-footer {
    max-width: 718px;
    margin: 0 auto;
  }

  .btn-modal-redirect {
    font-weight: 700;
    font-size: 32px;
    letter-spacing: 2.56px;
    height: 100px;
    width: 396px;

    &.btn-small {
      font-size: 18px;
      height: 50px;
      width: 287px;
    }

    @media all and (max-width: 991px) {
      width: 100%;
      font-size: 26px;
      height: auto;

      &.btn-small {
        width: 200px;
        height: auto;
      }
    }
  }

  &.modal-influencer-search {
    .modal-title {
      font-size: 24px;
    }
  }

  @media all and (min-width: 576px) {
    .modal-dialog {
      --bs-modal-width: 400px;
    }
  }

  @media all and (min-width: 1080px) {
    .modal-dialog {
      --bs-modal-width: 1060px;
    }
    &.small-modal {
      .modal-dialog {
        --bs-modal-width: 600px;
      }
    }
  }
}

.gap-4-ct {
  gap: 1.8rem !important;
}

.amazing-alert {
  padding: 0;
  border-radius: 8px;

  .btn-close {
    top: 50%;
    transform: translateY(-50%);
    padding: 1.2rem 1.5rem;

    &:focus,
    &:focus {
      box-shadow: none;
    }

    @media all and (max-width: 767px) {
      padding: 1rem;
    }
  }

  .amazing-alert-wrap {
    border-radius: 8px;
    overflow: hidden;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #307d6e3d;
    padding-right: 50px;

    .alert-icon {
      padding: 33px 27px;
      background: #d869db 0% 0% no-repeat padding-box;
      margin-right: 24px;
      position: relative;

      &::before,
      &::after {
        content: '';
        position: absolute;
        background: #d869db;
        width: 100%;
        height: 100%;
        left: 0;
      }

      &::before {
        top: -100%;
      }

      &::after {
        bottom: -100%;
      }

      @media all and (max-width: 767px) {
        margin-right: 12px;
      }
    }

    .alert-icon-success {
      padding: 33px 27px;
      background: #51d6bc 0% 0% no-repeat padding-box;
      margin-right: 24px;
      position: relative;

      &::before,
      &::after {
        content: '';
        position: absolute;
        background: #51d6bc;
        width: 100%;
        height: 100%;
        left: 0;
      }

      &::before {
        top: -100%;
      }

      &::after {
        bottom: -100%;
      }

      @media all and (max-width: 767px) {
        margin-right: 12px;
      }
    }

    .alert-body {
      padding: 12px 0;

      .alert-title {
        font-size: 32px;
        font-weight: 700;
        color: #2c3331;

        @media all and (max-width: 767px) {
          font-size: 26px;
        }
      }

      .alert-content {
        font-size: 16px;
        font-weight: 700;
        color: #2c3331;

        @media all and (max-width: 767px) {
          font-size: 14px;
        }
      }
    }

    @media all and (max-width: 767px) {
      padding-right: 42px;
    }
  }
}

.text-pre-wrap {
  white-space: pre-wrap;
}

.page__wrap {
  padding-top: 102px;
  @media all and (max-width: 1024px) {
    padding-top: 0px;
  }
}

.page__wrap.nda-page {
  padding-top: 102px;
  @media all and (max-width: 1024px) {
    padding-top: 60px;
  }
  @media all and (max-width: 575px) {
    padding-top: 40px;
  }
  @media all and (max-width: 375px) {
    padding-top: 85px;
  }
}

.page-title {
  font-size: 32px;
  font-weight: 700;
  color: #484848;
  margin-bottom: 16px;
  min-height: 86px;
  display: flex;
  align-items: flex-end;

  @media (min-width: 320px) and (max-width: 767px) {
    min-height: 66px;
  }

  @media all and (max-width: 767px) {
    font-size: 26px;
    margin-bottom: 8px;
  }
}

.content__wrapper {
  color: #242424;
  padding: 44px 36px;

  @media all and (max-width: 676px) {
    padding: 22px 16px;
  }

  .title-sub {
    font-size: 22px;
    font-weight: 700;
    color: #484848;
  }

  .group-content {
    margin-bottom: 40px;

    @media all and (max-width: 676px) {
      margin-bottom: 20px;
    }

    p:last-child {
      margin-bottom: 0;
    }

    .bg-content {
      background: #f2f2f2 0% 0% no-repeat padding-box;
      border-radius: 8px;
      padding: 40px;

      @media all and (max-width: 676px) {
        padding: 20px;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }

    .bg-content-finish {
      background: #f9f2fb 0% 0% no-repeat padding-box;
      border-radius: 8px;
      padding: 55px;

      @media all and (max-width: 676px) {
        padding: 20px;
      }
    }

    .list-item-custom {
      li {
        padding: 5px 0;
      }
    }
  }
}

// Page Title After
.client-cpanel__page-title-after {
  position: absolute;
  bottom: 12px;
  left: 0;
  width: 100%;
  z-index: -1;
  letter-spacing: 6.2px;
  display: flex;
  align-items: flex-end;
  font: normal normal 500 62px/66px Futura PT;
  letter-spacing: 6.2px;


  @media all and (max-width: 567px) {
    font-size: 40px;
    line-height: 45px;
  }

  .shadow-text {
    color: #cdf1ea;
    text-shadow: 3px 3px #f1def4;
  }

  .stroke-text {
    -webkit-text-stroke: 2px #cdf1ea;
    writing-mode: inherit;
    color: transparent;
    font-weight: 400;
    letter-spacing: 0;
    text-shadow: none;
    position: relative;

    &::after {
      position: absolute;
      content: 'Topics';
      left: 3px;
      top: 3px;
      -webkit-text-stroke: 2px #f1def4;
      z-index: -1;
    }
  }
}

// End Page Title After

.error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  min-height: calc(100vh - ($menu-navbar-height + $main-footer-height));

  .error__code,
  .error__msg {
    font-size: 32px;
    font-weight: bold;
  }

  .error__code,
  .error__img {
    margin-bottom: 24px;
  }

  .error__msg {
    margin-bottom: 48px;
  }
}

// Border xanh, tim
.box-style-1__border {
  width: calc(100% - 8px);
  margin: auto;
  height: 100%;
  background: #ffffff;
  border-radius: 8px;
  position: relative;

  &::before {
    position: absolute;
    content: '';
    z-index: -9;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background: #cdf1ea 0% 0% no-repeat padding-box;
    left: -4px;
    top: -4px;
  }

  &:after {
    position: absolute;
    content: '';
    z-index: -9;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background: #f1def4 0% 0% no-repeat padding-box;
    right: -4px;
    bottom: -4px;
  }

  &.wt-mobile {
    padding-top: 30px;

    @media all and (max-width: 575px) {
      width: 93%;
      padding-top: 16px;
    }
  }

  .form__wrapper {
    @media all and (min-width: 1500px) {
      width: 896px;
    }
  }
}

.line {
  padding-bottom: 10px;
  border-bottom: 4px solid #e4e4e4;
}
.amazing-number {
  li {
    &::marker {
      content: "(" counter(list-item) ") ";
      unicode-bidi: isolate;
      font-variant-numeric: tabular-nums;
      text-transform: none;
      text-indent: 0px !important;
      text-align: start !important;
      text-align-last: start !important;
    }
  }
}
.amazing-number {
  li {
    &::marker {
      content: "(" counter(list-item) ") ";
      unicode-bidi: isolate;
      font-variant-numeric: tabular-nums;
      text-transform: none;
      text-indent: 0px !important;
      text-align: start !important;
      text-align-last: start !important;
    }
  }
}
// End Border xanh, tim

.nav-link {
  .circle-count {
    background: #cdf1ea;
    &.has-messages {
      background: red !important;
    }
  }

  &.active {
    .circle-count {
      background: #62dec7;
    }
  }
}

.amazing-tooltip {
  .tooltip-inner {
    white-space: pre-wrap;
    max-width: unset;
    text-align: start;
  }
}

.star-svg {
  background: url('../../../../assets/svg/star-ic.svg') no-repeat;

  &.star-light {
    background: url('../../../../assets/svg/star-light-ic.svg') no-repeat;
  }
}

.amazing-form-label {
  text-align: left;
  letter-spacing: 0px;
  color: #919191;
  opacity: 1;
}

.amazing-text-end {
  text-align: right;

  @media all and (max-width: 768px) {
    text-align: left;
  }
}

.shortcut-video-item {
  img {
    border-radius: 8px;
    width: 100%;
    height: 255px;
    object-fit: cover;
  }
}

.page-container {
  color: #484848;
}

.btn-remove-css {
  outline: none;
  padding: 0px;
  border: 0px;
  box-sizing: none;
  background-color: transparent;
  text-decoration: none
}

.line-height-initial {
  line-height: initial;
}

// Style ProgressBar
.progress-custom-1 {
  height: 30px;
  border-radius: 30px;
  background-color: #C8C8C8;

  .progress-bar {
    background: transparent linear-gradient(90deg, #54DBC2 0%, #5EBFE7 52%, #D869DB 100%) 0% 0% no-repeat padding-box;
    font-size: 19px;
    font-weight: bold;
    min-width: 15%;
    display: flex;
    align-items: center;
  }

  &.progress__less-than {
    .progress-bar {
      color: transparent;
      min-width: auto;
    }
    span {
      font-size: 19px;
      font-weight: bold;
      color: #fff;
      padding-left: 5px;
      padding-top: 1px;
    }
  }

  &.progress__less-zero {
    span {
      padding-left: 15px;
    }
  }

  @media all and (max-width: 768px) {
    height: 20px;
    .progress-bar {
      font-size: 14px;
    }
    &.progress__less-than {
      span {
        font-size: 14px;
      }
    }
  }
}

// End ProgressBar

.rating-stars {
  line-height: 0;
}

.min-w-60 {
  min-width: 60px;
}

.wrapper__896 {
  max-width: 896px;
  margin-left: auto;
  margin-right: auto;
}

.flex-wrap {
  .flex-fill-ct {
    flex: 1 1 auto!important;
  }
}

.flex-wrap-mobile {
  flex-wrap: wrap!important;
  .flex-fill-ct {
    @media all and (max-width: 992px) {
      flex: 1 1 auto!important;
    }
  }
}

.flex-direction-lg-ct {
  @media all and (max-width: 991px) {
    flex-direction: column;
  }
}

.font-14 {
  font-size: 14px;
}

.font-color-note {
  color: #FF0000;
}

.show-nav-mobile {
  overflow: hidden;
  position: fixed;
}

@media all and (max-width: 767px) {
  .font-mb-16 {
    font-size: 16px;
  }
  .font-mb-18 {
    font-size: 18px;
  }
  .font-mb-26 {
    font-size: 26px;
  }
  .mobile-slider-rank {
    min-height: 35px;
  }

  .slideDownIn {
    -webkit-animation-name: slideDownIn;
    animation-name: slideDownIn;
  }
}

.animation{
	-webkit-animation-duration: 0.3s;
	animation-duration: 0.3s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

 @-webkit-keyframes slideDownIn {
  0% {
    
    -webkit-transform: translateY(-20px);
  }
  100% {
    
    -webkit-transform: translateY(0);
  }
  
  0% {
    -webkit-transform: translateY(-20);
  }
}

@keyframes slideDownIn {
	0% {
		transform: translateY(-20px);
	}
	100% {
		transform: translateY(0);
	}
	0% {
		transform: translateY(-20px);
	}
}