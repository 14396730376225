.icon-svg {
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;

  &::before {
    position: absolute;
    content: '';
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 1;
  }
}

.chart-simple-svg {
  &::before {
    background-image: url("../../../../assets//svg/chart-simple-ic.svg");
  }
}

.gear-svg {
  &::before {
    background-image: url("../../../../assets//svg/gear-ic.svg");
  }
}

.chart-simple-svg {
  &::before {
    background-image: url("../../../../assets//svg/chart-simple-ic.svg");
  }
}

.lock-svg {
  &::before {
    background-image: url("../../../../assets//svg/lock-ic.svg");
  }
}

.pen-to-square-svg {
  &::before {
    background-image: url("../../../../assets//svg/pen-to-square-ic.svg");
  }
}

.point-green-svg {
  &::before {
    background-image: url("../../../../assets//svg/point-green-ic.svg");
  }
}

.point-svg {
  &::before {
    background-image: url("../../../../assets//svg/point-ic.svg");
  }
}

.user-svg {
  &::before {
    background-image: url("../../../../assets//svg/user-ic.svg");
  }
}

.user-profile-svg {
  &::before {
    background-image: url("../../../../assets//svg/user-profile-ic.svg");
  }
}

.fresh-svg {
  &::before {
    background-image: url("../../../../assets//svg/fresh-ic.svg");
  }
}

.warning-svg {
  &::before {
    background-image: url("../../../../assets/svg/triangle-warning.svg");
  }
}

.bear-menu-svg {
  &::before {
    background-image: url("../../../../assets/svg/bear-menu-ic.svg");
  }
}
.box-arrow-up-right-svg {
  &::before {
    background-image: url("../../../../assets/svg/box-arrow-up-right.svg");
  }
}
.influencer-point-svg {
  &::before {
    background-image: url("../../../../assets/svg/influencer-point-ic.svg");
  }
}
.news-svg {
  &::before {
    background-image: url("../../../../assets/svg/info-ic.svg");
  }
}
.user-rank-svg {
  &::before {
    background-image: url("../../../../assets/svg/user-rank-ic.svg");
  }
}
.new-teddy-bear-svg {
  &::before {
    background-image: url("../../../../assets/svg/new-teddy-bear-ic.svg");
  }
}
.company-user-rank-svg {
  &::before {
    background-image: url("../../../../assets/svg/company-user-rank-ic.svg");
  }
}
// .news-svg {
//   &::before {
//     background-image: url("../../../../assets/svg/news.svg");
//   }  
// }

.company-rank-0-13-svg {
  &::before {
    background-image: url("../../../../assets/svg/ranks/company_0-13.svg");
  }
}
.company-rank-0-13-number-than-2-svg {
  &::before {
    background-image: url("../../../../assets/svg/ranks/company_0-13-number-than-2.svg");
  }
}
.company-rank-14-40-svg {
  &::before {
    background-image: url("../../../../assets/svg/ranks/company_14-40.svg");
  }
}
.company-rank-14-40-number-than-2-svg {
  &::before {
    background-image: url("../../../../assets/svg/ranks/company_14-40-number-than-2.svg");
  }
}
.company-rank-41-81-svg {
  &::before {
    background-image: url("../../../../assets/svg/ranks/company_41-81.svg");
  }
}
.company-rank-41-81-number-than-2-svg {
  &::before {
    background-image: url("../../../../assets/svg/ranks/company_41-81-number-than-2.svg");
  }
}
.company-rank-82-135-svg {
  &::before {
    background-image: url("../../../../assets/svg/ranks/company_82-135.svg");
  }
}
.company-rank-82-135-number-than-2-svg {
  &::before {
    background-image: url("../../../../assets/svg/ranks/company_82-135-number-than-2.svg");
  }
}
.company-rank-136-270-svg {
  &::before {
    background-image: url("../../../../assets/svg/ranks/company_136-270.svg");
  }
}
.company-rank-136-270-number-than-2-svg {
  &::before {
    background-image: url("../../../../assets/svg/ranks/company_136-270-number-than-2.svg");
  }
}
.company-rank-271-svg {
  &::before {
    background-image: url("../../../../assets/svg/ranks/company_271.svg");
  }
}
.company-rank-271-number-than-2-svg {
  &::before {
    background-image: url("../../../../assets/svg/ranks/company_271-number-than-2.svg");
  }
}

.influencer-rank-0-15-svg {
  &::before {
    background-image: url("../../../../assets/svg/ranks/Influencer_0-15.svg");
  }
}
.influencer-rank-0-15-number-than-2-svg {
  &::before {
    background-image: url("../../../../assets/svg/ranks/Influencer_0-15-number-than-2.svg");
  }
}
.influencer-rank-16-30-svg {
  &::before {
    background-image: url("../../../../assets/svg/ranks/Influencer_16-30.svg");
  }
}
.influencer-rank-16-30-number-than-2-svg {
  &::before {
    background-image: url("../../../../assets/svg/ranks/Influencer_16-30-number-than-2.svg");
  }
}
.influencer-rank-31-77-svg {
  &::before {
    background-image: url("../../../../assets/svg/ranks/Influencer_31-77.svg");
  }
}
.influencer-rank-31-77-number-than-2-svg {
  &::before {
    background-image: url("../../../../assets/svg/ranks/Influencer_31-77-number-than-2.svg");
  }
}
.influencer-rank-78-200-svg {
  &::before {
    background-image: url("../../../../assets/svg/ranks/Influencer_78-200.svg");
  }
}
.influencer-rank-78-200-number-than-2-svg {
  &::before {
    background-image: url("../../../../assets/svg/ranks/Influencer_78-200-number-than-2.svg");
  }
}
.influencer-rank-201-500-svg {
  &::before {
    background-image: url("../../../../assets/svg/ranks/Influencer_201-500.svg");
  }
}
.influencer-rank-201-500-number-than-2-svg {
  &::before {
    background-image: url("../../../../assets/svg/ranks/Influencer_201-500-number-than-2.svg");
  }
}
.influencer-rank-500-svg {
  &::before {
    background-image: url("../../../../assets/svg/ranks/Influencer_500.svg");
  }
}
.influencer-rank-500-number-than-2-svg {
  &::before {
    background-image: url("../../../../assets/svg/ranks/Influencer_500-number-than-2.svg");
  }
}

.npo-category-icon-0 {
  &::before {
    background-image: url("../../../../assets/icons/npo_category/00.png");
  }
}

.npo-category-icon-1 {
  &::before {
    background-image: url("../../../../assets/icons/npo_category/01.png");
  }
}

.npo-category-icon-2 {
  &::before {
    background-image: url("../../../../assets/icons/npo_category/02.png");
  }
}

.npo-category-icon-3 {
  &::before {
    background-image: url("../../../../assets/icons/npo_category/03.png");
  }
}

.npo-category-icon-4 {
  &::before {
    background-image: url("../../../../assets/icons/npo_category/04.png");
  }
}

.npo-category-icon-5 {
  &::before {
    background-image: url("../../../../assets/icons/npo_category/05.png");
  }
}

.npo-category-icon-6 {
  &::before {
    background-image: url("../../../../assets/icons/npo_category/06.png");
  }
}

.npo-category-icon-7 {
  &::before {
    background-image: url("../../../../assets/icons/npo_category/07.png");
  }
}

.npo-category-icon-8 {
  &::before {
    background-image: url("../../../../assets/icons/npo_category/08.png");
  }
}

.npo-category-icon-9 {
  &::before {
    background-image: url("../../../../assets/icons/npo_category/09.png");
  }
}

.npo-category-icon-10 {
  &::before {
    background-image: url("../../../../assets/icons/npo_category/10.png");
  }
}

.npo-category-icon-11 {
  &::before {
    background-image: url("../../../../assets/icons/npo_category/11.png");
  }
}

.npo-category-icon-12 {
  &::before {
    background-image: url("../../../../assets/icons/npo_category/12.png");
  }
}

.npo-category-icon-13 {
  &::before {
    background-image: url("../../../../assets/icons/npo_category/13.png");
  }
}

.npo-category-icon-14 {
  &::before {
    background-image: url("../../../../assets/icons/npo_category/14.png");
  }
}

.npo-category-icon-15 {
  &::before {
    background-image: url("../../../../assets/icons/npo_category/15.png");
  }
}

.npo-category-icon-16 {
  &::before {
    background-image: url("../../../../assets/icons/npo_category/16.png");
  }
}

.npo-category-icon-17 {
  &::before {
    background-image: url("../../../../assets/icons/npo_category/17.png");
  }
}

.npo-category-icon-18 {
  &::before {
    background-image: url("../../../../assets/icons/npo_category/18.png");
  }
}

.rank-icon {
  position: relative;
  display: block;
  line-height: 0;

  .icon-svg {
    width: 80px;
    height: 40px;

    &:before {
      left: 0;
    }
		@media screen and (max-width: 767px) {
			height: 34px;
		}
  }

  .rank-number {
    font-family: "游明朝", YuMincho, "Hiragino Mincho ProN W3", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
    font-style: normal;
    font-size: 16px;
    position: absolute;
    left: 50px;
    top: 21px;
    z-index: 1;
		@media screen and (max-width: 767px) {
			left: 45px;
			top: 19px;
		}
  }

  .rank-color-1 {
    .rank-number {
      color: #EBEBEB;
    }
  }
  .rank-color-2 {
    .rank-number {
      color: #E6CD64;
    }
  }
}

.rank-icon_number-than-2 {
	.icon-svg {
    width: 104px;
    height: 40px;

    &:before {
      left: 0;
    }
		@media screen and (max-width: 767px) {
			height: 34px;
		}
  }
	.rank-number {
		left: 50px;
		@media screen and (max-width: 425px) {
			left: 45px;
		}
	}
	.rank-number_3 {
		left: 55px;
	}
	.rank-number_4 {
		left: 55px;
		@media screen and (max-width: 425px) {
			left: 45px;
		}
	}
}
.public-project-svg {
  &::before {
    background-image: url("../../../../assets/svg/public-project-ic.svg");
  }
}
.candidacy-project-svg {
  &::before {
    background-image: url("../../../../assets/svg/candidacy-project-ic.svg");
  }
}
.specified-project-svg {
  &::before {
    background-image: url("../../../../assets/svg/specified-project-ic.svg");
  }
}
.invitation-svg {
  &::before {
    background-image: url("../../../../assets/svg/invitation-ic.svg");
  }
}
