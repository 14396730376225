.reward-page {
    margin-top: 70px;
    .reward-title {
        font-size: 62px;
        color: #cdf1ea;
        text-shadow: 2px 2px 2px #F1DEF4;
        padding-top: 20px;
    }
    .mdiv-30 {
        margin-top: -30px;
    }
    .outline-wrapper {
        font-size: 22px;
        color: #2c3331;
        font-weight: 700;
        padding: 50px 15px;
        margin-top: 10px;
        .latest-update {
            font-size: 70px;
            color: #484848;
        }
        .reward-warning {
            width: fit-content;
            margin: 0 auto;
            background: #F9F2FB;
            font-size: 22px;
            font-weight: 400;
            color: #D869DB;
            padding: 10px 12px;
            i {
                width: 30px;
                height: 30px;
                margin-right: 25px;
                margin-left: -12px;
                margin-bottom: -5px;
            }
        }
        button {
            margin-top: 50px;
        }
    }
    .description-wrap {
        margin-top: 25px;
    }
}
.reward-modal {
    color: #919191;

    .modal-body {
        max-width: 100%;
        width: 100%;
        padding-left: 80px;
        padding-right: 80px;
    }

    .modal-text-center {
        display: inline-block;
        text-align: center;
        margin-top: 15px;
        color: #484848;
		width: 100%;
    }
    .tiktok-avatar {
        margin-top: 55px;
        margin-bottom: 10px;
    }
    .modal-footer {
        width: 100%;
    }
    @media (min-width: 1300px) {
        .tiktok-info {
            .d-flex.flex-wrap {
                .flex-fill {
                    &:first-child {
                        width: calc(100% - 140px);
                    }
                    &:last-child {
                        width: 140px;
                    }
                } 
            }
        }
    }
}

@media all and (max-width: 991px) {
    .reward-page {
        .reward-title {
            font-size: 48px;
            line-height: 48px;
        }
        .outline-wrapper {
            font-size: 20px;
            .latest-update {
                font-size: 60px;
            }
            .reward-warning {
                font-size: 20px;
            }
        }
    }
}
@media all and (max-width: 767px) {
    .reward-page {
        .outline-wrapper {
            padding: 30px 15px;
            .latest-update {
                font-size: 40px;
            }
            .reward-warning {
                font-size: 14px;
            }
            button {
                margin-top: 30px;
            }
        }
    }
}
@media all and (max-width: 374px) {
    .reward-page {
        .outline-wrapper {
            .latest-update {
                font-size: 40px;
            }
        }
    }
}