.message-amazing {
  .w-inherit {
    width: inherit !important;
  }
  .label-created {
    margin-top: auto;
    @media all and (max-width: 576px) {
      text-align: end;
    }
  }
  .input-notes {
    height: 50px;
  }
  .content-status {
    @media all and (max-width: 991px) {
      padding-bottom: 20px;
    }
  }
  .input-content {
    padding: 12px 24px !important;
    line-height: 19px !important;
  }
  .title {
    font-size: 32px;
    font-weight: 700;
    color: #484848;
    @media all and (max-width: 767px) {
      font-size: 18px;
    }
  }
  .sub-title1 {
    font-size: 22px;
    font-weight: 700;
    color: #484848;
    max-width: 400px;
    @media all and (max-width: 767px) {
      font-size: 16px;
      font-weight: 600;
    }
  }
  .sub-title {
    font-size: 22px;
    font-weight: 700;
    color: #484848;
    @media all and (max-width: 767px) {
      font-size: 16px;
      display: block;
      padding-right: 10px;
    }
  }
  .reply-status-text {
    font-size: 14px;
    color: #72b6a9;
  }

  .room-status {
    border-radius: 60px;
    min-width: 166px;
    font-weight: 600;
    &.long-mess {
      min-width: 220px;
    }
    @media all and (max-width: 767px) {
      width: auto !important;
      font-size: 14px;
      min-width: unset;
    }
    &.status-0 {
      background-color: #54dbc2;
      color: #ffffff;
    }
    &.status-1 {
      background-color: #C8C8C8;
      color: #919191;
    }
    &.status-2 {
      background-color: #cdf1ea;
      color: #919191;
    }

    &.status-3 {
      background-color: #c8c8c8;
      color: #919191;
    }

    &.status-4 {
      background-color: #54dbc2;
      color: #ffffff;
    }

    &.status-5 {
      background-color: #C8C8C8;
      color: #919191;
    }

    &.status-6 {
      background-color: #54dbc2;
      color: #ffffff;
    }

    &.status-7 {
      background-color: #f1def4;
      color: #d869db;
    }

    &.status-8 {
      background-color: #c8c8c8;
      color: #919191;
    }
    &.status-9 {
      background-color: #f1def4;
      color: #d869db;
    }
  }

  .btn-redirect {
    width: 396px;
    font-size: 32px;

    @media all and (max-width: 767px) {
      width: 100%;
      font-size: 25px;
      margin-left: 15px;
      margin-right: 15px;
    }
  }
  .btn-video-url {
    padding: 9px 17px;
  }
  .line-break {
    white-space: pre-line;
    word-wrap: break-word;
  }
  .max-height-140vh {
    height: auto !important;

    @media all and (min-width: 768px) {
      max-height: 140vh;
    }
  }
  .paid-reward {
    right: 0;
    top: 0;
    padding: 5px;
    width: 200px;
    border-radius: 10px;
    background-color: #F9F2FB;
    .points {
        span {
            margin-left: 20px;
        }
    }
    .fsize-32 {
        font-size: 22px;
        font-weight: 700;
        color: #484848;
    }
  }
  .message-mobile {
    .sticky-bottom {
      padding-bottom: 15px;
    }
  }
  .user-rank-area-new {
    &.mr-25 {
      margin-right: 25px;
      @media all and (max-width: 767px) {
        margin-right: 5px;
      }
    }
  }
}

.offcanvas-header-amazing {
  padding: 0px !important;
  margin: 0px !important;
  &.h-m-80 {
    @media all and (max-width: 767px) {
      height: 80px;
    }
  }
}

.offcanvas-sub-header-amazing {
  width: 100%;
  padding: 20px 40px 20px 40px;
  @media all and (max-width: 767px) {
    padding: 8px 16px 8px 16px;
    .row-1, .row-2{
      padding-bottom: 0 !important;
    }
  }
  @media all and (max-width: 480px) {
    &.offcanvas-mobile {
      #notes {
        font-size: 13px;
        padding: 15px 10px;
      }
      .form-label {
        font-size: 13px;
      }
    }
  }
}
.offcanvas-amazing-body2 {
  padding: 0px !important;
  // overflow-y: hidden !important;
}
.offcanvas-amazing-master-body {
  padding: 0px !important;
}
.offcanvas-amazing-body {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 500px);
  min-height: 145px;
	@media all and (max-width: 768px) {
    max-height: calc(100vh - 230px);
  }
  &::-webkit-scrollbar-track {
    border-radius: 4px;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #4F4F4F;
  }
}

.talk-room-system-notice {
  background-color: #F1DEF4;
  color: #D869DB;
  padding: 5px 10px;

  @media all and (max-width: 767px) {
    font-size: 12px;
  }
}

.offcanvas-amazing-content {
  width: 100%;
  padding: 20px 40px 20px 40px;
  @media all and (max-width: 767px) {
    padding: 8px 16px 8px 16px;
  }
}

.offcanvas-amazing-status {
  width: 264px;
  &.input-status {
    .select-picker__input {
      @media all and (max-width: 767px) {
        border: 0px !important;
      }
    }
    @media all and (max-width: 767px) {
      width: 230px;
    }
  }
}

.offcanvas-amazing-footer {
}

.offcanvas-amazing-footer-form {
  padding: 20px 40px 20px 40px;
  .row-content{
    padding-right: 0 !important;
  }
  @media all and (max-width: 768px) {
    .w160.btn.btn-outline-green {
      margin-right: 7px !important;
    } 
  }
  @media all and (max-width: 767px) {
    padding: 8px 16px 8px 16px;
    #contents {
      height: 50px;
    }
    .row-content{
      padding-right: .5rem !important;
    }
  }
  .btn-point-icon {
    padding-left: 0px;
  }

  .project-status-select-picker__container.talk-influencer {
    .select-picker__input-group {
      .select-picker__input-group-value {
        transform: translate(15px, -50%);
        margin-left: 0 !important;
      }
    }
  }
}

.offcanvas-amazing {
  --bs-offcanvas-width: min(95vw, 1008px);
  @media all and (max-width: 992px) {
    width: auto !important;
  }
}

.offcanvas-size-xl {
  --bs-offcanvas-width: min(95vw, 600px) !important;
}

.offcanvas-size-xxl {
  --bs-offcanvas-width: min(95vw, 90vw) !important;
}

.offcanvas-size-md {
  /* add Responsivenes to default offcanvas */
  --bs-offcanvas-width: min(95vw, 400px) !important;
}

.offcanvas-size-sm {
  --bs-offcanvas-width: min(95vw, 250px) !important;
}

.message-my-content {
  max-width: 620px;
  border: 1px solid #54dbc2;
  padding: 10px;
  border-radius: 8px;
  position: relative;
  background-color: white;

  &::before {
    position: absolute;
    content: '';
    right: -5px;
    bottom: 16px;
    width: 10px;
    height: 10px;
    background-color: white;
    border: 1px solid #54dbc2;
    transform: rotate(45deg);
    z-index: -1;
  }

  &::after {
    position: absolute;
    content: '';
    right: -1px;
    bottom: 16px;
    width: 10px;
    height: 10px;
    background-color: white;
  }
  .label-created-influencer {
    position: absolute;
    left: 0px;
    bottom: -30px;
  }
  @media all and (max-width: 576px) {
    margin-left: auto;
    min-width: 145px;
  }
}

.message-content {
  max-width: 620px;
  position: relative;
  background: transparent;
  border-radius: 1px;
  padding: 10px 20px 10px 20px;
  @media all and (max-width: 767px) {
    max-width: calc(100% - 130px);
  }
  @media all and (max-width: 575px) {
    max-width: 100%;
  }
  .box-white,
  .box-green,
  .box-pink {
    position: absolute;
    border-radius: 8px;
    height: 100%;

    &::before {
      position: absolute;
      content: '';
      left: -5px;
      top: 16px;
      width: 10px;
      height: 10px;
      transform: rotate(45deg);
      z-index: -1;
    }
  }

  .box-white {
    width: calc(100% - 4px);
    background-color: white;
    z-index: -9;
    top: 0;
    left: 0;

    &::before {
      background-color: white;
    }
  }

  .box-green {
    width: calc(100% - 4px);
    background-color: #cdf1ea;
    z-index: -99;
    right: 8px;
    top: -4px;

    &::before {
      background-color: #cdf1ea;
    }
  }

  .box-pink {
    width: calc(100% - 4px);
    z-index: -999;
    right: 0;
    bottom: -4px;
    background-color: #f1def4;

    &::before {
      background-color: #f1def4;
    }
  }
  @media all and (max-width: 576px) {
    margin-right: auto;
  }
  .label-created-not-influencer {
    position: absolute;
    left: 0px;
    bottom: -30px;
  }
}

.message-my-content-influencer {
  max-width: 620px;
  position: relative;
  background: transparent;
  border-radius: 1px;
  padding: 10px 20px 10px 20px;

  .box-white,
  .box-green,
  .box-pink {
    position: absolute;
    border-radius: 8px;
    height: 100%;

    &::before {
      position: absolute;
      content: '';
      right: -5px;
      bottom: 16px;
      width: 10px;
      height: 10px;
      transform: rotate(45deg);
      z-index: -1;
    }
  }

  .box-white {
    width: calc(100% - 4px);
    background-color: white;
    z-index: -9;
    top: 0;
    right: 0;

    &::before {
      background-color: white;
    }
  }

  .box-green {
    width: calc(100% - 4px);
    background-color: #cdf1ea;
    z-index: -99;
    left: 8px;
    top: -4px;

    &::before {
      background-color: #cdf1ea;
    }
  }

  .box-pink {
    width: calc(100% - 4px);
    z-index: -999;
    left: 0;
    bottom: -4px;
    background-color: #f1def4;

    &::before {
      background-color: #f1def4;
    }
  }
  .label-created-influencer {
    position: absolute;
    left: 0px;
    bottom: -30px;
    color: #919191;
  }
	@media all and (max-width: 991px) {
		min-width: 145px;
	}
  @media all and (max-width: 576px) {
    margin-left: auto;
    max-width: 100%;
  }
}

.message-content-influencer {
  max-width: 620px;  
  border: 1px solid #54dbc2;
  padding: 10px;
  border-radius: 8px;
  position: relative;
  background-color: white;

  &::before {
    position: absolute;
    content: '';
    left: -5px;
    bottom: 16px;
    width: 10px;
    height: 10px;
    background-color: white;
    border: 1px solid #54dbc2;
    transform: rotate(45deg);
    z-index: -1;
  }

  &::after {
    position: absolute;
    content: '';
    left: -1px;
    bottom: 16px;
    width: 10px;
    height: 10px;
    background-color: white;
  }
  .label-created-not-influencer {
    position: absolute;
    right: 0;
    bottom: -30px;
    color: #919191;
  }
  @media all and (max-width: 576px) {
    margin-right: auto;
    max-width: 100%;
    min-width: 120px;
  }
}

.schedule-modal {
  .modal-header {
    border-bottom: 0;
  }
  .modal-body {
    padding-top: 0;
  }
  .btn-green {
    min-width: 200px;
  }
  .mobile-px-0{
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media all and (min-width: 768px) {
  .user-rank-area-new {
    &.ml-40 {
      margin-left: 40px;
    }
  }
}
@media all and (max-width: 767px) {
  .message-amazing {
    .paid-reward {
      top: 0px;
      width: 120px;
      float: left;
      .points {
        span {
          margin-left: 10px;
        }
        .point-svg {
          height: 20px;
          width: 20px;
        }
      }
      .fsize-32 {
        font-size: 16px;
      }
    }
    .user-rank-area {
      height: 30px;
      line-height: 30px;
      &.ml-40 {
        margin-left: 0px;
      }
      .icon {
        .icon-svg.user-rank-svg {
          width: 42px;
          height: 30px;
        }
      }
      .verify-color {
        padding: 2px 6px;
        line-height: 14px;
        font-size: 12px;
      }
      .teddybear-rating {
        img {
          width: 24px;
        }
      }
    }
    .user-rank-area-new {
      &.ml-40 {
        float: left;
      }
      .rank-icon {
        height: 34px;
        margin-bottom: 0;
        margin-right: 0;
        .icon-svg {
          height: 34px;
        }
        .rank-number {
          top: 18px;
					@media screen and (max-width: 425px){
						left: 40px;
					}
        }
				.rank-number_3 {
					@media screen and (max-width: 425px){
						left: 45px;
					}
        }
				.rank-number_4 {
					@media screen and (max-width: 425px){
						left: 45px;
					}
        }
      }
    }
    .message-mobile {
      padding-bottom: 30px;
    }
  }
}

@media all and (max-height: 667px) and (orientation: landscape) {
  .message-amazing {
    .max-height-140vh {
      max-height: none !important;
    }
    .message-mobile {
      .offcanvas-amazing-body {
        max-height: 250px;
      }
      .sticky-bottom {
        position: inherit !important;
      }
    }
  }
}