.page-search-influencers {
    .amazing-tabs {
        margin-bottom: 40px;
    }
    .form__wrapper {
        padding: 0 20px;
    }
    &.dont-purchase {
        .list-wrapper {
            .tab-pane {
                > .row {
                    & > .influencer-search-item {
                        .influencer-item-info {
                            & > * {
                                filter: blur(5px);
                                -webkit-filter: blur(5px);
                                user-select: none;
                                -webkit-user-select: none;
                                touch-action: none;
                                -webkit-touch-callout: none;
                            }
                            & > .item-sub-item {
                                filter: blur(0);
                                -webkit-filter: blur(0);
                                & > .list-inline, & > .paid-reward > p {
                                    filter: blur(5px);
                                    -webkit-filter: blur(5px);
                                }
                            }
                            & > div:last-child {
                                filter: blur(0);
                                -webkit-filter: blur(0);
                            }
                        }
                    }
                }
            }
        }
    }
}
.influencer-search-item {
    text-align: center;
    .influencer-item-info {
        width: 100%;
        height: 100%;
    }
    .avatar {
        span {
            right: -15px;
        }
    }
    .item-username {
        font-weight: 700;
        font-size: 18px;
        letter-spacing: 0px;
        color: #484848;
    }
    .item-nickname {
        font-size: 16px;
        font-weight: 500;
        color: #818181;
    }
    .card-galaxy {
        display: flex;
        flex-direction: column;
        height: 100%;
        .card-galaxy-body {
            flex-grow: 1;
        }
    }
    .create-project-search-influencer {
        display: flex;
        flex-direction: column;
        height: 100%;
        .card-galaxy-body {
            flex-grow: 1;
        }
    }
    .item-sub-item {
        .rank-verify {
            @media all and (min-width: 768px) {
                min-height: 32px;
            }

            .list-inline-item{
                width: fit-content;
            }
            .rank-color {
                background-color: #F4F9F8;
                border-color: #CDF1EA;
            }
            span {
                display: inline-block;
                vertical-align: middle;
            }
            .verify-color {
                border-radius: 20px;
                border: 2px solid;
                padding: 2px 10px;
                border-color: #F1DEF4;
                background-color: #F9F2FB;
                color: #E861E1;
                font-weight: bold;
                font-family: 'Noto Sans JP';
                margin-left: -10px;
                position: relative;
                line-height: 18px;
                font-size: 14px;
            }
            .icon{
                text-align: left;
                z-index: 0;
                position: relative;
                .icon-svg.user-rank-svg{
                    width: 57px;
                    height: 42px;
                }
            }
        }
        .paid-reward {
            text-align: left;
            margin-left: 15px;
            margin-right: 15px;
            .points {
                background-color: #F9F2FB; 
                span {
                    margin-left: 20px;
                }
            }
            p {
                font-size: 12px;
                color: #919199;
            }
        }
        .fsize-32 {
            font-size: 32px;
            font-weight: 700;
        }
    }
    .item-pr {
        text-align: left;
    }
}

.ranks_and_request-login {
    min-height: 80px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100% !important;
    .request-login {
        button {
            width: 190px;
        }
    }
    @media all and (max-width: 575px) {
        min-height: 98px;
        .request-login {
            display: flex;
            flex-direction: column;
            align-items: center;
            button {
                font-size: 16px;
                padding: 8.5px 10px;
            }
        }
    }
    @media all and (max-width: 475px) {
        .request-login {
            button {
                width: 180px;
            }
        } 
    }
    @media all and (max-width: 375px) {
        min-height: 112px;
        .request-login {
            button {
                width: 150px;
                font-size: 14px;
                padding: 8.5px 8px;
            }
        }
    }
}

.myproject-creation-influencer-detail {
    &.dont-purchase {
        .influencer-info__container {
            .influencer-detail-info-box {
                filter: blur(5px);
                -webkit-filter: blur(5px);
                user-select: none;
                -webkit-user-select: none;
                touch-action: none;
                -webkit-touch-callout: none;
            }
            .influencer-pr-box {
                filter: blur(5px);
                -webkit-filter: blur(5px);
                user-select: none;
                -webkit-user-select: none;
                touch-action: none;
                -webkit-touch-callout: none;
            }
            .influencer-info__flex-reverse {
                .filter-blur {
                    filter: blur(5px);
                    -webkit-filter: blur(5px);
                    user-select: none;
                    -webkit-user-select: none;
                    touch-action: none;
                    -webkit-touch-callout: none;
                }
            }
        }
        .section.recent-posts {
            > .row {
                filter: blur(5px);
                -webkit-filter: blur(5px);
                user-select: none;
                -webkit-user-select: none;
                touch-action: none;
                -webkit-touch-callout: none;
            }
        }
    }
}

@media (min-width:1501px) and (max-width: 1619px) {
    .influencer-search-item {
        .card-galaxy {
            .card-galaxy-body {
                .count_number {
                    zoom: 89%;
                }
            }
        }
    } 
}

@media (min-width:1200px) and (max-width: 1500px) {
    .influencer-search-item {
        .card-galaxy {
            .card-galaxy-body {
                zoom: 89%;
                padding-left: 15px;
                padding-right: 15px;
                .count_number {
                    zoom: 83%;
                }
            }
        }
    } 
    .create-project__search-user-form {
        padding-left: 20px;
        padding-right: 20px;
        zoom: 89%;
    }
}
@media (min-width:992px) and (max-width: 1199px) {

    .influencer-search-item {
        .card-galaxy {
            .card-galaxy-body {
                zoom: 89%;
                padding-left: 15px;
                padding-right: 15px;
                .count_number {
                    zoom: 83%;
                }
            }
        }
    } 
}
@media all and (max-width: 960px) {
    .influencer-search-item {
        .avatar {
            span {
                margin-right: 30px;
            }
        }
    } 
}

@media all and (max-width: 767px) {
    .influencer-search-item {
        .avatar {
            .rounded-circle {
                max-width: 100px;
                height: auto;
            }
            span {
                margin-right: 7px;
                img {
                    width: 30px;
                }
            }
        }
        .item-username {
            margin: 5px 0 !important;
        }
        .list-inline {
            margin-bottom: 5px;
        }
        .list-inline-item {
            padding: 0 !important;
            font-size: 10px;
        }
        .item-sub-item {
            .fsize-32 {
                font-size: 22px;
            }
            .paid-reward {
                margin: 0;
            }
        }
        .card-galaxy {
            .card-galaxy-body {
                padding-bottom: 0;
            }
        }
    } 
}

@media all and (max-width: 360px) {
    .influencer-search-item {
        .card-galaxy {
            .card-galaxy-body {
                .count_number {
                    zoom: 85%;
                }
            }
        }
    } 
}