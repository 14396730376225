.client-sidebar {
  background-color: #F4F9F8;
  position: absolute;
  left: 0;
  height: 100%;
  width: $w-sidebar;
  padding-top: 64px;

  @media (max-width: 1199px) {
    display: none;
  }

  .client-sidebar__action {
    padding: 0 32px;

    .nav-item {
      margin-bottom: 20px;
      width: 100%;
    }
  }

  .client-sidebar__navbar {
    .nav-item {
      width: 100%;

      .nav-link {
        display: flex;
        justify-self: center;
        flex-direction: row;
        font-size: 18px;
        padding: 20px 32px;
        color: #484848;
        transition: all .3s;

        .nav-icon {
          margin-right: 8px;
        }

        &:hover,
        &.active {
          background-color: #E9F5F2;
        }
        &.my-projects-list {
          position: relative;
          span {
            display: flex;
            position: absolute;
            top: 15px;
            left: 210px;
            font-size: 12px;
            width: 20px;
            height: 20px;
            background: red;
            border-radius: 50%;
            justify-content: center;
            align-items: center;
            color: #fff;
          }
        }
      }
    }
  }
}