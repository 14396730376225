.hyperlink {
    color: #54DBC2;
    font-weight: bold;
    transition: all .1s;

    &:hover {
        color: #307D6E;
    }

    &:active {
        color: #72B6A9;
    }

    &.disabled {
        color: #C8C8C8;
    }
}

.link {
    color: #484848;
    transition: all .1s;

    &:hover {
        color: black;
    }

    &:active {
        color: #CCCCCC;
    }

    &.disabled {
        color: #C8C8C8;
    }
}

@mixin base-custom-maxline {
    word-wrap: break-word;
    white-space: normal;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
}

.custom-maxline-1 {
    @include base-custom-maxline;
    -webkit-line-clamp: 1;
}

.custom-maxline-2 {
    @include base-custom-maxline;
    -webkit-line-clamp: 2;
}

.link-blank {
    color: #484848;
    transition: all .1s;
    position: relative;
    padding-right: 24px;

    &::before {
        position: absolute;
        bottom: 2px;
        right: 0;
        content: '';
        background-image: $icon-open-link;
        background-repeat: no-repeat;
        background-size: cover;
        width: 16px;
        height: 16px;
    }

    &:hover {
        color: black;
    }

    &:active {
        color: #CCCCCC;
    }

    &.disabled {
        color: #C8C8C8;
    }
}

.style-location-icon {
    position: relative;
    padding-left: 28px;
		display: block;

    &::before {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
        content: '';
        width: 20px;
        height: 24px;
        background-image: $icon-location;
        background-repeat: no-repeat;
        background-size: cover;
    }
}