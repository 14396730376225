.page-project {
    padding-bottom: 100px;

    .spinner-border {
        color: #54dbc2;
    }

    .section {
        &.filter {
            label {
                color: #484848;

                &.form-label {
                    @media all and (max-width: 991px) {
                        margin-bottom: 0px;
                        margin-top: 10px;
                    }
                }
            }

            .form-label.order {
                display: table-cell;
                vertical-align: middle !important;
                height: 50px;
            }
        }

        .project-item .card {
            color: #484848;
            border: 0;
            border-radius: 10px;
            box-shadow: -4px -4px 0px 0px rgba(84, 219, 194, 35%);
            position: relative;

            &:hover {
                .project__img {
                    transform: scale(1.2);
                }

                .title {
                    color: #54DBC2;
                }
            }

            .card-inner {
                .cover-image {
                    position: relative;
                    border-radius: 10px 10px 0 0;
                    overflow: hidden;

                    a>img {
                        overflow: hidden;
                        width: 100%;
                        height: 312px;
                        object-fit: cover;
                        transition: .3s;

                        @media all and (max-width: 575px) {
                            height: 242px;
                        }
                    }

                    .category {
                        position: absolute;
                        left: 0;
                        top: 0;
                        background-color: #fff;
                        padding: 5px 15px 5px 10px;
                        border-radius: 10px 0;
                        color: #484848;
                        font-size: 14px;
                    }

                    .favorite {
                        position: absolute;
                        top: 8px;
                        right: 8px;
                        border-radius: 50%;
                        background-color: #fff;
                        padding: 8px;
                        border: 2px solid #54dbc2;
                        cursor: pointer;

                        img {
                            margin-top: 2px;
                            margin-bottom: 1px;
                        }

                        .spinner-border {
                            margin-left: 2px;
                            margin-right: 2px;
                            width: 28px;
                            height: 28px;
                            color: #54dbc2;
                            margin-bottom: -2px;
                        }
                    }

                    .remaining {
                        height: 103px;
                        cursor: pointer;
                        pointer-events: none;
                        background: transparent linear-gradient(180deg, #24242400 0%, #24242466 100%) 0% 0% no-repeat padding-box;
                    }

                    .remaining-delivery-days {
                        position: absolute;
                        bottom: 0;
                        right: 8px;
                        color: #fff;

                        .label {
                            display: block;
                            font-size: 14px;
                        }

                        .number {
                            font-weight: bolder;
                            font-size: 32px;
                            line-height: 40px;

                            .day {
                                margin-left: 4px;
                                font-weight: normal;
                                font-size: 14px;
                            }
                        }
                    }
                }

                border-radius: 10px;
                box-shadow: 4px 4px 0px 0px rgba(200, 123, 210, 35%);

                .card-body {
                    font-family: 'Noto Sans JP';
                    font-size: 16px;
                    padding: 5px 5px 10px 5px;
                    &.npo-project {
                        background-color: #fffee2;
                    }

                    .type-info {
                        position: relative;
                        margin-bottom: 0;
                        .project-type {
                            color: #919191;
                            font-size: 13px;
                            line-height: 30px;
                            margin-right: 10px;
                        }

                        .type {
                            &.type-0 {
                                border: 2px solid #cdf1ea;
                                background: #f4f9f8;
                            }

                            &.type-1 {
                                border: 2px solid #f1def4;
                                background: #f9f2fb;
                            }

                            &.type-2 {
                                border: 2px solid #54dbc2;
                                background: #54dbc2;
                                color: #fff;
                            }

                            border-radius: 20px;
                            font-size: 13px;
                            padding: 5px 8px;
                            display: inline-block;
                        }
                    }

                    h2.title {
                        font-size: 22px;
                        font-weight: 700;
                        margin: 8px 0;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        line-height: 36px;
                        transition: .3s;

                        a {
                            color: inherit;
                            text-decoration: none;
                        }
                        @media screen and (max-width: 575px) {
                            height: auto;
                        }
                    }

                    p.company-name {
                        margin-bottom: 0;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                    }

                    .recruits-info {
                        .info {
                            width: 50%;
                            display: inline-block;
                            text-align: left;
                            margin-bottom: 5px;

                            .label {
                                width: 60%;
                                display: inline-block;
                                // font-size: 12px;
                            }

                            .number {
                                width: 36%;
                                display: inline-block;
                                text-align: center;
                                font-weight: bolder;
                                font-size: 26px;

                                .person {
                                    font-size: 16px;
                                }
                            }

                            &:first-child {
                                .label {
                                    width: 50%;
                                }

                                .number {
                                    width: 46%;
                                }
                            }
                        }

                        @media all and (max-width: 430px) {
                            .label {
                                font-size: 14px;
                            }

                            .number {
                                font-size: 20px !important;

                                .person {
                                    font-size: 13px;
                                }
                            }
                        }

                        @media all and (max-width: 375px) {
                            .label {
                                font-size: 13px;
                            }

                            .number {
                                font-size: 16px !important;

                                .person {
                                    font-size: 10px;
                                }
                            }
                        }

                        @media all and (max-width: 354px) {
                            .label {
                                font-size: 11px;
                            }

                            .number {
                                font-size: 16px !important;

                                .person {
                                    font-size: 11px !important;
                                }
                            }
                        }
                    }

                    .budget-label {
                        color: #c8c8c8;
                        font-size: 14px;
                        margin-bottom: 5px;
                    }

                    .budget {
                        &.progress {
                            height: 26px;
                            border-radius: 26px;
                            background: #c8c8c8;
                            font-size: 16px;
                            font-weight: 700;

                            &.need-number {
                                position: relative;
                                display: block;

                                span {
                                    position: absolute;
                                    top: 0;
                                    left: 10px;
                                }
                            }
                        }

                        .progress-bar {
                            background: rgb(84, 219, 194);
                            background: linear-gradient(90deg,
                                    rgba(84, 219, 194, 1) 0%,
                                    rgba(94, 191, 231, 1) 55%,
                                    rgba(216, 105, 219, 1) 100%);
                        }
                    }

                    .delivery {
                        font-size: 14px;
                        color: #919191;
                        margin-top: 7px;
                        margin-bottom: 0;
                    }
                }
            }
            .unread-messages {
                display: flex;
                position: absolute;
                width: 26px;
                height: 26px;
                background: red;
                border-radius: 50%;
                right: -10px;
                top: -10px;
                z-index: 1;
                justify-content: center;
                align-items: center;
                color: #fff;
                font-size: 16px;
                font-weight: bold;
            }
        }
				@media (max-width: 425px) {
					.section-list-project {
						margin-top: 2rem !important;
					}
				}
			}

    .group-radio-button {
        .radio-button {
            margin-left: 30px;
        }

        @media all and (max-width: 640px) {
            display: block !important;

            .order-label {
                width: 100%;
            }

            .radio-button {
                display: inline-block;
                margin-left: 0;
                margin-right: 20px;
            }
        }

        @media all and (max-width: 536px) {
            .radio-button {
                width: 47%;

                &:nth-child(2n + 1) {
                    margin-right: 0;
                }
            }
        }

        @media all and (max-width: 320px) {
            .radio-button {
                margin-right: 12px;
                width: 47%;

                &:nth-child(2n + 1) {
                    margin-right: 0;
                }
            }
        }
    }
	@media all and (max-width: 575px) {
		padding-bottom: 3rem;
	}
}

.npo-tooltip-wrapper {
    background: #FDF3E6;
    strong {
        text-decoration: underline;
    }
    @media (max-width: 767px) {
        strong {
            text-decoration: none;
        }
    }
}

.apply-project-confirm-model {
    border: 0;

    .modal-content {
        box-shadow: -3px -3px 0px 0px rgb(84 219 194 / 35%), 3px 3px 0px 0px rgb(200 123 210 / 35%);

        .modal-header {
            border-bottom: 0;
        }

        .modal-body {
            padding: 0 100px 0px 100px;

            @media all and (max-width: 575px) {
                padding: var(--bs-modal-padding);
            }
        }

        .amazing-btn.apply {
            min-width: 310px;

            @media all and (max-width: 575px) {
                min-width: 80%;
            }
        }
    }
}

#form-file-upload,
#multiple-file-upload {
    width: 100%;
    text-align: center;
    position: relative;
}

#cover_image,
#attached_files {
    display: none;
}

#label-file-upload,
#label-multiple-upload {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #f4f9f8;
    border-radius: 8px;
    background-color: #f4f9f8;
}

.upload-button {
    cursor: pointer;
    padding: 0.25rem;
    font-size: 1rem;
    border: none;
    background-color: transparent;
}

.upload-button:hover {
    text-decoration-line: underline;
}

#drag-file-element,
#drag-multiple-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.project-create-page {
    padding: 0 175px;

    .project_input_react-datepicker {
        .react-datepicker-popper-custom-1 {
            padding-top: 10px;
            inset: 0px 0px auto auto !important;
            transform: translate(0px, 50px) !important;
            z-index: 100;
        }
    }

    form {
        max-width: 896px;
        margin: 0 auto;

        .instruction-block {
            br {
                display: none;
            }
        }

        .form-group-item {
            textarea {
                resize: vertical;
                height: 200px;
            }

            #gifting_notes:disabled {
                background-color: #e9ecef;
            }

            .text-note {
                font-size: 14px;
            }
        }

		.project_title {
			.label-icon {
				color: #484848 !important;
				@media screen and (max-width: 445px) {
					margin-left: 0px;
					width: 100%;
					display: inline-block;
				}
			}
			@media screen and (max-width: 445px) {
				.d-inline-block {
					display: block !important;
				}

			}
		}

        .title-wrap {
            h3 {
                margin-top: 90px;
                font-weight: 700;
                font-size: 32px;
                margin-bottom: 35px;
                color: #484848;
            }

            label {
                margin-top: 70px;
            }
        }

        .custom-radio-shape {
            position: relative;

            textarea {
                resize: none;
                height: 200px;
            }

            .form-check.form-check-inline {
                margin-right: 0;
                margin-bottom: 0;

                &:first-child label {
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                }

                &:last-child label {
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
            }

            [type='radio']:checked,
            [type='radio']:not(:checked) {
                position: absolute;
                left: -9999px;
                width: 0;
                height: 0;
                visibility: hidden;
            }

            .form-check-input:checked+label,
            .form-check-input:not(:checked)+label {
                position: relative;
                display: inline-block;
                padding: 15px;
                width: 155px;
                line-height: 20px;
                letter-spacing: 1px;
                margin: 0 auto;
                margin-right: 5px;
                margin-bottom: 0;
                text-align: center;
                overflow: hidden;
                cursor: pointer;
                -webkit-transition: all 300ms linear;
                transition: all 300ms linear;
            }

            .form-check-input:not(:checked)+label {
                background-color: #cdf1ea;
                color: #919191;
            }

            .form-check-input:checked+label {
                background-color: #51d6bc;
                color: #fff;
                .question-circle-style {
                    color: #cdf1ea;
                }
            }

            .form-check-input:checked+label::before,
            .form-check-input:not(:checked)+label::before {
                position: absolute;
                content: '';
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 4px;
                background-color: #cdf1ea;
                z-index: -1;
            }
            &.disabled {
                .form-check-radio.type {
                    opacity: 0.7;
                }
                .form-check.form-check-inline {
                    label {
                        cursor: default;
                    }
                }
            }
        }

        .col-form-label {
            color: #919191;
            padding-top: 12px;
            padding-bottom: 12px;
        }

        .hashtag__input {
            border: none;

            &:focus {
                box-shadow: none;
            }
        }

        .hashtag__container {
            border-radius: 8px;
        }

        .divider {
            font-size: 1rem;
        }

        .drap-text {
            margin-top: 68.5px;
            margin-bottom: 35.7px;

            p {
                margin-bottom: 2rem;

                @media all and (max-width: 576px) {
                    margin-bottom: 0;
                }
            }

            button {
                font-size: 1.14rem;
                font-weight: 700;
                padding: 15px 50px;
                background-color: #54dbc2;
                margin-bottom: 2.14rem;
                border: 1px solid #54dbc2;

                &:active {
                    background-color: #54dbc2;
                    border: 1px solid #54dbc2;
                }

                .paperclip {
                    font-size: 24px;
                    transform: rotate(35deg);
                }

				@media screen and (max-width: 425px) {
					padding: 15px 30px;
				}
            }
        }

        .instruction-block {
            margin-top: 35px;
            font-size: 1.14rem;

            p:last-child {
                margin-bottom: 20px;
            }

            .text-muted {
                color: #919191 !important;
            }
        }

        .image-display-block {
            position: relative;

            .cover-image-view {
                max-height: 360px;
                margin: 20px auto;
                max-width: 100%;
            }

            .delete-ic {
                position: absolute;
                float: right;
                top: 20px;
                right: 10px;
            }
        }

        .attached-files-block {
            display: inline-flex;
            flex-flow: row wrap;
            list-style: none;
            margin-top: 15px;
            margin-bottom: 15px;

            .justify-content-between {
                position: relative;
                display: flex;
                justify-content: center;
                background-color: #f4f9f8;
                width: 120px;
                height: 120px;
            }

            .attached-file-cover {
                max-width: 120px;
                margin-right: 13px;

                .cover-image-view {
                    max-width: 100%;
                    max-height: 100%;

                    &.pdf {
                        height: 100%;
                        width: 70%;
                        margin: 0 auto;
                    }
                }

                .delete-btn {
                    position: absolute;
                    float: right;
                    top: -8px;
                    right: -10px;
                }
            }

            .attach-file-name {
                font-size: 1rem;
                text-decoration-line: underline;
                margin-bottom: 0;
                word-wrap: break-word;
            }
        }

        .submit-btn-list {
            margin-top: 65px;
            padding-bottom: 80px;

            div {
                display: flex;
                justify-content: center;
            }
        }

        .question-circle-style {
            color: #54dbc2;
            margin-left: 5px;
            width: 24px;
            height: 24px;
            bottom: 3px;
            margin-top: -10px;
        }
    }
}

.create-project__search-user-form {
    .common-btn.btn-submit-project {
        margin-top: auto;
    }
}

@media all and (min-width: 1920px) {
    .project-create-page {
        form {
            max-width: 896px;

            .instruction-block {
                br {
                    display: block;
                }
            }
        }
    }
}

@media all and (max-width: 1680px) {
    .project-create-page {
        padding: 0 115px;
    }
}

@media all and (max-width: 1540px) {
    .project-create-page {
        padding: 0 75px;

        form {
            max-width: 700px;
        }
    }
    .form-style-1.project-create-page {
        .form-label.limit-reward {
            font-size: 15px;
        }
    }
}

@media all and (max-width: 1240px) {
    .project-create-page {
        padding: 0 50px;
    }
}

@media all and (max-width: 1199px) {
    .project-create-page {
        padding: 0 100px;
    }
}

@media all and (max-width: 1100px) {
    .project-create-page {
        padding: 0 50px;
    }
}

@media all and (max-width: 991px) {
    .project-create-page {
        padding: 15px 35px;
        margin-top: 110px;

        form {
            .title-wrap {
                margin-bottom: 10px;
                margin-left: 0;
                margin-right: 0;

                h3 {
                    margin: 16px auto;
                }

                label {
                    margin-top: 0;
                }

                div {
                    &:last-child {
                        position: absolute;
                        top: -115px;
                        left: 0;
                        padding: 0;
                    }
                }
            }

            .instruction-block {
                br {
                    display: none;
                }
            }
        }
    }
}

@media all and (max-width: 810px) {
    .project-create-page {
        padding: 15px;
    }
}

@media all and (max-width: 767px) {
    .project-create-page {
        .project-create__action {
            width: 287px;
            margin-top: 24px;
            margin-bottom: 12px;
        }

        form {
            .submit-btn-list {
                margin-top: 0;
                padding-bottom: 0;
                margin-top: 40px;
            }
        }
    }
}

@media all and (max-width: 649px) {
    .project-create-page {
        .type {
            .form-check-inline {
                width: 33.333333%;

                .form-check-input:checked+label,
                .form-check-input:not(:checked)+label {
                    width: 100%;
                }
            }
        }
    }
}

@media all and (max-width: 576px) {
    .project-create-page {
        padding: 10px;

        .instruction-block {
            p:last-child {
                color: #c8c8c8 !important;
            }
        }

        form {
            .title-wrap {
                margin-bottom: 10px;

                h3 {
                    font-size: 26px;
                    margin: 16px auto;
                }

                label {
                    margin-top: 0;
                }

                div {
                    &:last-child {
                        position: absolute;
                        top: -112px;
                        padding-left: 0px;
                        padding-right: 0px;
                    }
                }
            }

            .custom-radio-shape {
                position: relative;

                .form-check-input:checked+label,
                .form-check-input:not(:checked)+label {
                    padding: 15px 5px;
                    margin-right: 3px;
                }

                .form-check.form-check-inline {
                    &:last-child {
                        label {
                            margin-right: 0;
                        }
                    }
                }
            }

            .cls-category {
                div {
                    margin-top: 28px;
                }
            }

            .area_ids {
                .form-check-inline {
                    margin-right: 7px;
                    width: auto;
                }
            }

            .gifting {
                .form-check-inline {
                    width: 50%;

                    .form-check-input:checked+label,
                    .form-check-input:not(:checked)+label {
                        width: 100%;
                    }
                }
            }

            #label-file-upload,
            #label-multiple-upload {
                background: #fff;
                border: none;

                .drap-text {
                    width: 100%;
                    margin-top: 0;
                    margin-bottom: 0;

                    .text-top {
                        display: none;
                    }

                    .divider {
                        display: none;
                    }

                    button {
                        margin-bottom: 13px;
                        width: 100%;
                        padding-top: 9px;
                        padding-bottom: 9px;
                    }

                    .text-bottom {
                        font-size: 13px;
                        color: #919191;
                    }
                }
            }

            .instruction-block {
                margin-top: 0;

                br {
                    display: none;
                }
            }

            .text-bottom {
                margin-bottom: 1rem;
            }

            .submit-btn-list {
                margin-top: 0;
            }

            .attached-files-block {
                .attached-file-cover {
                    max-width: 101px;
                    margin-right: 4px;
                    margin-bottom: 5px;

                    .justify-content-between {
                        margin-right: 0;
                        width: 101px;
                        height: 101px;
                    }
                }
            }
        }
    }
}

@media all and (max-width: 350px) {
    .project-create-page {
        form {
            .custom-radio-shape {

                .form-check-input:checked+label,
                .form-check-input:not(:checked)+label {
                    padding: 15px 0;
                }
            }
        }
    }
}

.create-project__search-user-form {
    padding-top: 48px;
    padding-bottom: 48px;
    margin-bottom: 72px;

    @media (min-width: 320px) and (max-width: 767px) {
        padding-top: 24px;
        padding-bottom: 28px;
        margin-bottom: 24px;
    }
}

// Project Detail Page
.project-detail-page {
    padding-bottom: 104px;
		padding-top: 10px;

    @media (min-width: 320px) and (max-width: 1024px) {
        padding-top: 16px;
        padding-bottom: 56px;
    }

    .project__title {
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 20px;
				color: #484848;

        @media (min-width: 320px) and (max-width: 1024px) {
            font-size: 26px;
            margin-bottom: 8px;
        }
    }

    .project__category-name {
        border: 2px solid #CDF1EA;
        border-radius: 50rem;
        padding: 4px 14px;
        margin-right: 5px;

        @media (min-width: 320px) and (max-width: 1024px) {
            font-size: 14px;
        }
    }

    .project__type-name {
        position: relative;
        padding-left: 24px;
        padding-top: 6px;
        padding-bottom: 6px;
        color: #919191;

        @media (min-width: 320px) and (max-width: 1024px) {
            font-size: 14px;
        }

        &::after {
            position: absolute;
            content: '';
            left: 0;
            top: 50%;
            width: 24px;
            height: 24px;
            background-image: $icon-project-type;
            background-repeat: no-repeat;
            transform: translate(0, -50%);
        }
    }

    .col__left {
        padding-right: calc(14px + 1.35vw);
        width: calc(100% - 396px);

        @media (min-width: 320px) and (max-width: 1024px) {
            width: 100%;
            padding-right: 12px;
        }

        .project-detail__card {
            border: none;

            .card-body {
                padding-top: 24px;
                padding-left: calc(14px + 1.35vw);
                padding-right: calc(14px + 1.35vw);

                @media (min-width: 320px) and (max-width: 1024px) {
                    padding-top: 8px;
                    padding-left: 8px;
                    padding-right: 8px;
                }
            }
        }

        .project__img-top-box {
            width: 100%;
            overflow: hidden;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            max-height: 630px;
            position: relative;

            @media (min-width: 320px) and (max-width: 1024px) {
                max-height: 242px;
            }

            .project__img {
                object-fit: cover;
                width: 100%;
                height: 100%;
                transition: .3s;

                &:hover {
                    transform: scale(1.1);
                }
            }
        }

        .project__favorite {
            position: absolute;
            right: 32px;
            bottom: 24px;
            border: 4px solid #52D7BE;
            border-radius: 50rem;
            background-color: white;
            z-index: 99;
            width: calc(100px);
            height: calc(100px);
            text-align: center;

            @media (min-width: 320px) and (max-width: 1024px) {
                width: 54px;
                height: 54px;
                right: 8px;
                bottom: 8px;
                border-width: 2px;
            }

            img {
                width: 55px;

                @media (min-width: 320px) and (max-width: 1024px) {
                    width: 26px;
                }
            }
        }

        .project-info-mobile {
            display: none;

            @media (min-width: 320px) and (max-width: 1024px) {
                display: block;
            }

            .project__day-remain {
                small {
                    font-size: 8px;
                }

                span {
                    margin-left: 2px;
                    padding-right: 2px;
                    font-size: 20px;
                }
            }

            .project__recruits_number {
                width: 48%;

                .project__label {
                    font-size: 14px;
                }

                .project__value {
                    span {
                        font-size: 19px;
                    }

                    small {
                        font-size: 9px
                    }
                }
            }
        }

        .project-info {
            position: relative;

            .project__item {
                display: flex;
                border-bottom: 1px solid #C8C8C8;
                padding: 16px calc(14px + 1.35vw);

                @media (min-width: 320px) and (max-width: 1024px) {
                    padding-left: 0;
                    padding-right: 0;
                }

                @media (min-width: 320px) and (max-width: 1024px) {
                    flex-direction: column;
                }

                &:last-child {
                    border-bottom: none;
                    padding-right: 0px;
                }

                .project__label {
                    width: calc(100px + 6.04vw);
                    color: #919191;

                    @media (min-width: 320px) and (max-width: 1024px) {
                        width: 100%;
                        margin-bottom: 5px;
                    }
                }

								.line-break {
									white-space: pre-line;
									word-wrap: break-word;
								}

                .project__value {
                    margin-bottom: 0;
                    width: calc(100% - calc(100px + 6.04vw));
                    line-height: 24px;

                    @media (min-width: 320px) and (max-width: 1024px) {
                        width: 100%;
                    }
                }
            }
        }

        .project__hashtag-list {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            .project__hashtag-item {
                padding: 5px 12px;
                margin-right: 16px;
                margin-top: 5px;
                margin-bottom: 5px;
                background: #f4f9f8;
                border: 2px solid #cdf1ea;
                border-radius: 20px;
                &:last-child {
                    margin-right: 0;
                }
							@media (max-width: 575px) {
								border-radius: 30px;
								border: 2px solid #CDF1EA;
								background-color: #F4F9F8;
								margin-bottom: 15px;
							}
            }
        }
    }

    .col__right {
        padding-left: 0;
        padding-right: 0;
        width: 396px;

        @media (min-width: 320px) and (max-width: 1024px) {
            width: 100%;
            padding-left: 15px;
            padding-right: 15px;
            margin-top: 20px;
            .card.bg-transparent{
                display: none;
            }
        }

        .project__type {
            font-size: 22px;
            color: #484848;
        }

        .project__day-remain {
            span {
                margin-right: 3px;

                &:last-child {
                    margin-right: 0;
                }
            }

            .number {
                font-size: 34px;
            }
        }

        .project-right-top__item {
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 24px;
            }

            .project__label {
                min-width: 136px;
            }

            .project__value {
                .number {
                    font-size: 30px;
                }
            }
        }

        .project-right__card {
            border: none;

            .card-body {
                padding: 36px 53px;

                .project__npo_or_company_name {
                    font-size: 18px;
                    margin-bottom: 8px;
                }

                .company__certificate {
                    color: #54DBC2;
                }

                .rating-stars {
                    margin-bottom: 10px;
                    svg {
                        width: 23px;
                        height: 22px;
                        margin-right: 8px;
                    }
                }

                .user-rank-area-new.company {
                    .rank-icon {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }

    .project__apply-btn {
        width: 396px;
        margin-bottom: 32px;
        font-size: 32px;
        text-decoration: none;
        display: inline-block;

        @media (min-width: 320px) and (max-width: 1024px) {
            &.fixed-bottom-btn {
                position: fixed;
                bottom: 40px;
                width: 287px;
                margin: auto;
                left: 0;
                right: 0;
                z-index: 1030;
            }
        }
    }
}


.project__attachment-list {
    display: grid;
    grid-template-columns: repeat(4, 150px);
    grid-template-rows: auto;
    column-gap: 24px;
    row-gap: 24px;
    justify-content: space-around;

    @media (max-width: 1439px) {
        grid-template-columns: repeat(3, 150px);
    }

    @media (max-width: 1300px) {
        grid-template-columns: repeat(2, 150px);
    }

    @media (max-width: 1040px) {
        grid-template-columns: repeat(2, 135px);
    }

    @media (max-width: 1024px) {
        grid-template-columns: repeat(4, 150px);
    }

    @media (max-width: 768px) {
        grid-template-columns: repeat(3, 150px);
    }

    @media (max-width: 575px) {
        grid-template-columns: repeat(2, 145px);
    }

    a {
        text-decoration-color: #484848;
        cursor: pointer;
    }

    .project__attachment-box {
        border-radius: 8px;
        overflow: hidden;
        height: 150px;
        margin-bottom: 8px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: .3s;

            &:hover {
                transform: scale(1.1);
            }
        }
    }

    .project__attachment-name {
        color: #484848;
    }
}

.page-project-detail {
    .project__attachment-list {
        @media (max-width: 1500px) {
            grid-template-columns: repeat(3, 150px);
        }

        @media (max-width: 575px) {
            grid-template-columns: repeat(2, 145px);
        }
    }
}

.user-rank-area.company {
    .icon {
        height: 60px;
    }
    .icon-svg.company-user-rank-svg {
        width: 82px;
        height: 38px;
    }
    .verify-color {
        margin-left: -30px;
    }
    &.list-project{
        position: absolute;
        right: 0;
        top: 0;
				@media screen and (max-width: 425px) {
					right: -11px;
				}
    }
}

.user-rank-area-new {
    &.company {
        &.list-project{
            position: absolute;
            right: 0;
            top: 0;
						@media screen and (max-width: 425px) {
							right: -11px;
						}
        }
    }
    &.has-teddybear {
        .rank-icon {
            margin-right: 25px;
        }
        .teddybear-rating {
            margin-right: 20px;
        }
        @media all and (max-width: 767px) {
            .rank-icon {
                margin-right: 5px;
            }
            .teddybear-rating {
                margin-right: 5px;
            }
        }
    }
}
// End Project Detail Page

// Project Filter
.project-filter_group {
	width: 100%;
	justify-content: space-between;
	flex-wrap: 5px;
	.filter-input {
		padding-left: 5px;
		padding-right: 5px;
		width: 24%;
		&_project-type{
			min-width: 236px;
		}
		&_category{
			min-width: 261px;
		}
		@media all and (max-width: 991px) {
			width: 49%;
		}
		@media all and (max-width: 575px) {
			width: 100%;
		}
	}
}
// End Project Filter

// Confirm Create Project
.confirm-create-project {
	.confirm-project__note {
		color: #FF0000;
	}
	.confirm-project__button {
		.button {
			display: flex;
			&__back {
				justify-content: flex-start;
			}
			&__submit {
				justify-content: flex-end;
			}
			@media screen and (min-width:991px) and (max-width: 1100px) {
				justify-content: center;
			}
			@media screen and (max-width: 786px) {
				justify-content: center;
			}
		}
	}
}

.mobile-filter {
    background-color: #F4F9F88C;

    &::before {
        content: "";
        width: 30px;
        height: 30px;
        position: absolute;
        left: 40px;
        background-image: url("../../../../assets//svg/chevron-circle-down.svg");
      }

    &::after {
        background: transparent;
    }

    &.show-form {
        &::before {
            background-image: url("../../../../assets//svg/chevron-circle-up.svg");
        }
    }
}
.modal-attach-images {
    .slick-slide {
        padding: 0 30px;
        img {
            max-height: calc(100vh - 120px);
            max-width: 100%;
        }
    }
    .slick-slider {
        .slick-list {
            .slick-track {
                display: flex;
                align-items: center;
            }
        }
        .slick-dots {
            bottom: -15px;
        }
        .slick-prev, .slick-next {
            z-index: 1;
            &::before {
                background-image: $icon-carret;
                background-repeat: no-repeat;
                background-size: 100%;
                font-size: 35px;
                color: transparent;
                display: inline-block;
            }
        }
        .slick-prev {
            left: 0px;
            &::before {
                transform: rotateZ(180deg);
            }
        }
        .slick-next {
            right: 15px;
        }
    }
}