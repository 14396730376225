.page-purchase {
    .total-fee {
        background: #F4F9F8;
        margin-top: 50px;
        margin-bottom: 50px;
        .total-fee-container {
            padding-left: calc(22.4px + 8vw);
            padding-right: calc(22.4px + 8vw);
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 3vh;
            padding-bottom: 3vh;
        }
        .fee-text {
            font-size: 22px;
            margin-right: 11vw;
        }
        .fee-value{
            font-size: 60px;
            font-weight: 700;
        }
    }
    .info {
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #C8C8C8;
				border-top: 2px solid #C8C8C8;
    }
    .pop-navigate {
        width: 288px;
        padding: 11px 14px;
        float: right;
    }
}
@media all and (max-width: 575px) {
    .page-purchase {
        .info{
            div {
                text-align: center;
            }
            .pop-navigate {
                float: unset;
            }
        }
    }
}

@media all and (min-width: 568px) and (max-width: 673px) {
	.page-purchase {
		.purchase-title {
			margin-top: 6rem  !important;
		}
	}
}