.project-status-select-picker__container {
  position: relative;

  .select-picker__input-group {
    position: relative;
    width: 100%;
    display: flex;

    .select-picker__input {
      min-height: 50px;
      display: block;
      width: 100%;
      padding: 12px 24px;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #919191;
      appearance: none;
      border-radius: 8px;
      color: transparent;
      text-shadow: 0 0 0 #212529;
      user-select: none;
      -moz-user-select: none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      -o-user-select: none;
      position: relative;
      cursor: pointer;

      &::placeholder {
        text-shadow: none;
        color: #C8C8C8;
      }

      &:focus,
      &:active {
        outline: none;
        box-shadow: none;
        border-color: #51D6BC;
      }

      &:disabled {
        border-color: #C8C8C8;
      }
    }

    .select-picker__input-group-value {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      border-radius: 50rem;
      padding: 5px 5px;
      min-width: 140px;
      text-align: center;
      font-weight: bold;
      user-select: none;
      pointer-events: none;
    }

    .select-picker__input-group-text {
      display: flex;
      align-items: center;
      padding: 15px 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #212529;
      text-align: center;
      white-space: nowrap;
      background-color: transparent;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      position: absolute;
      top: 1px;
      right: 1px;
      pointer-events: none;
      @media all and (max-width: 767px) {
        right: 30px;
      }
    }
  }

  .select-picker-option__popper {
    position: absolute;
    border: 1px solid #51D6BC;
    z-index: 999999999;
    background-color: white;
    width: 100%;
    margin-top: 4px;
    margin-bottom: 4px;
    padding: 7px 0;
    border-radius: 8px;
    max-height: 108px;
    overflow: auto;
    display: none;
    inset: 0px 0px auto auto;
    transform: translate(0, 50px);
    text-align: center;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #8B8E8D;
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #51D6BC;
    }

    &.show {
      display: block;
    }

    .select-picker-option__item {
      cursor: default;
      background-color: white;
      font-size: 1rem;
      padding-top: 3px;
      padding-bottom: 3px;
      position: relative;
      user-select: none;
      -moz-user-select: none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      -o-user-select: none;
      transition: all .2s;

      &:hover,
      &.active {
        background-color: #F4F9F8;
      }

      .select-picker-option__badge {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        padding: 5px 5px;
        border-radius: 50rem;
        display: inline-block;
        min-width: 140px;
        position: relative;

        &.active {
          &::before {
            position: absolute;
            content: '';
            top: 50%;
            transform: translate(0, -50%);
            left: -30px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='rgb(81 214 188)'  class='bi bi-check' viewBox='0 0 16 16'%3E%3Cpath d='M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-size: cover;
            display: inline-block;
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }

  &.is-invalid .select-picker__input {
    background-color: #F1DEF4;
    border-color: #D869DB;
  }
}