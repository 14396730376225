.page-project-detail {
  .navigate-wrapper {
    position: relative;
    margin-bottom: 20px;
  }

  .nav-menu-link {
    position: relative;
    font-size: 16px;
    color: #2c3331;
    font-weight: 700;
    padding-right: 40px;
    padding-left: 45px;
    transition: all 0.3s;
    width: 10%;
    text-decoration: none;

    @media (max-width: 1312px) {
      padding-right: 18px;
    }

    &::before {
      background-image: $icon-carret;
      background-repeat: no-repeat;
      background-size: 100%;
      content: '';
      position: absolute;
      left: 0;
      top: -15%;
      width: 42px;
      height: 42px;
      transform: rotate(180deg);
    }
  }

  .edit-project-link {
    color: #54DBC2;
    font-weight: bold;
    padding: 0;

    span {
      display: inline-block;
      margin-left: 20px;
      margin-right: 12px;
      text-decoration: underline;
    }

    &:after {
      content: "";
      display: inline-block;
      width: 8px;
      height: 8px;
      border-top: 1px solid;
      border-right: 1px solid;
      transform: rotate(45deg);
    }
  }
  .title {
    h2 {
      font-size: 32px;
      font-weight: 700;
      letter-spacing: 0px;
      color: #484848;

      @media all and (max-width: 767px) {
        font-size: 26px;
      }
    }
  }
  .category {
    color: #484848;
    border-radius: 20px;
    font-size: 16px;
    background: #f4f9f8;
    padding: 5px 15px;
    border: 2px solid #cdf1ea;
    display: inline-block;
    margin-right: 5px;
    margin-left: -2px;

    @media all and (max-width: 767px) {
      font-size: 14px;
      padding: 2px 10px;
    }
  }
  .project-type {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0px;
    color: #919191;

    @media all and (max-width: 767px) {
      font-size: 12px;
    }
  }
  .sub-title {
    margin: 16px 0;
    .btn-talk-room {
      padding: 12px 27px;
      position: relative;
      span {
        display: flex;
        position: absolute;
        top: -10px;
        left: -10px;
        font-size: 12px;
        font-weight: 400;
        width: 20px;
        height: 20px;
        background: red;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        color: #fff;
      }

      @media all and (max-width: 575px) {
        margin-top: 10px;
      }
    }
    .btn-export-project {
      padding: 12px 27px;
    }
    .btn-save-draft {
      padding: 12px 27px;
    }
  }
  .project-item {
    .card {
      color: #484848;
      border: 0;
      border-radius: 10px;
      box-shadow: -3px -3px 0px 0px rgb(84 219 194 / 35%);
    }
    .card-inner {
      border-radius: 10px;
      box-shadow: 3px 3px 0px 0px rgb(200 123 210 / 35%);
      .cover-image {
        position: relative;
        & > img {
          border-radius: 10px 10px 0 0;
          width: 100%;
          height: 630px;
          object-fit: cover;
          @media all and (max-width: 575px) {
            height: 242px;
          }
        }
      }
      .card-body {
        padding: 24px 36px 44px 36px;

        @media all and (max-width: 767px) {
          padding: 24px 8px;
        }

        .label-rate {
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0px;
          color: #484848;
        }
        .label-project-type {
          font-size: 22px;
          font-weight: 700;
          line-height: 36px;
          letter-spacing: 0px;
          color: #484848;
        }
        .current-recruit {
          .label {
            font-size: 16px;
            letter-spacing: 0px;
            color: #484848;
          }
          .content {
            font-size: 30px;
            font-weight: 700;
            line-height: 36px;
            letter-spacing: 0px;
            color: #484848;
            span {
              font-size: 16px;
              font-weight: 700;
              line-height: 36px;
            }
          }
        }
        .info {
          margin: 0;
          padding: 16px 30px 0px 30px;
          border-bottom: 1px solid #c8c8c8;
          color: #484848;
          &:last-child {
            border-bottom: 0;
          }
          .label {
            font-size: 16px;
            letter-spacing: 0px;
            color: #919191;

            @media all and (max-width: 767px) {
              margin-bottom: 8px;
            }
          }
          .content {
            font-size: 16px;
            letter-spacing: 0px;
            color: #4f4f4f;
            .area {
              &:not(:first-child) {
                margin-top: 8px;
              }
            }

            @media all and (max-width: 767px) {
              padding-left: 0;
              padding-right: 0;
            }
          }
          .hashtag {
            border-radius: 20px;
            font-size: 16px;
            background: #f4f9f8;
            padding: 5px 15px;
            border: 2px solid #cdf1ea;
            display: inline-block;
            margin-right: 16px;
            margin-left: -2px;

            &:last-child {
              margin-right: 0;
            }

            @media all and (max-width: 767px) {
              font-size: 14px;
              padding: 2px 10px;
            }
          }

          @media all and (max-width: 991px) {
            padding: 16px 0 0 0;
          }
        }

        .rating-stars {
          svg {
            width: 23px;
            height: 22px;
            margin-right: 8px;
          }
        }
      }
    }
  }
}
.page-project.my-projects {
  .section.title {
    padding-right: 0;
  }
  .header {
    .title-bg {
      font-weight: normal;
    }
    h3 {
      color: transparent;
      -webkit-text-stroke: 2px #cdf1ea;
      text-shadow: none;
      font-size: 62px;
      writing-mode: inherit;
      letter-spacing: 0;
      font-weight: normal;
    }
    .outline-1 {
      -webkit-text-stroke: 2px #cdf1ea;
      top: -20px;
      left: 170px;
      z-index: 3;
    }
    .outline-2 {
      -webkit-text-stroke: 2px #f1def4;
      top: -17px;
      left: 173px;
      z-index: 2;
    }
  }
  .news-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    .news {
      padding: 0 45px 0 15px;
      width: 100%;
      .news-content {
        .row:first-child .info {
          border-top-width: 2px;
        }
        .row {
          .info {
            display: flex;
            .title {
              margin-left: 0;
              padding-left: 20px;
              padding-right: 0;
            }
          }
        }
      }
      .news-content.min-height {
        min-height: 190px;
      }
    }

    .info-block.box {
      box-shadow: -4px -4px 0px 0px rgb(84 219 194 / 35%), 4px 4px 0px 0px rgb(200 123 210 / 35%);
      border-radius: 8px;
      background: #fff;
      padding: 50px 30px;
      text-align: center;
      width: 486px;
      flex-shrink: 0;
      h3 {
        font-size: 22px;
        font-family: 'Noto Sans JP';
        font-weight: bold;
      }
      .point-number {
        font-size: 70px;
        font-weight: bold;
        margin-bottom: 0;
        span {
          font-size: 22px;
          margin-left: 10px;
        }
      }
    }
    .info-block.padding-box {
      padding: 62px 30px;
    }
    .info-block.padding-box-small {
      padding: 8px 76px;
    }
  }
  @media all and (max-width: 1366px) {
    .news-box{
      .info-block.box{
        width: 300px;
        padding: 60px 30px;
        h3{
          font-size: 18px;
          margin-bottom: 20px;
        }
        .point-number{
          font-size: 60px;
        }
      }
      .info-block.padding-box {
        padding: 58px 30px;
      }
      .info-block.padding-box-small {
        padding: 8px 76px;
      }
    }
  }
  @media all and (max-width: 1024px) {
    .news-box{
      .info-block.box{
        width: 300px;
        padding: 60px 30px;
        h3{
          font-size: 18px;
          margin-bottom: 20px;
        }
        .point-number{
          font-size: 60px;
        }
      }
      .info-block.padding-box {
        padding: 58px 30px;
      }
      .info-block.padding-box-small {
        padding: 8px 76px;
      }
    }
  }
  @media all and (max-width: 768px) {
    .news-box{
      .info-block.box{
        width: 220px;
        padding: 50px 30px;
        h3{
          font-size: 14px;
        }
        .point-number{
          font-size: 40px;
        }
      }
      .info-block.padding-box {
        padding: 43px 30px;
      }
      .info-block.padding-box-small {
        padding: 8px 76px;
      }
      .news {
        .news-content.min-height {
          min-height: 110px;
        }
      }
    }
  }
  @media all and (max-width: 575px){
    .header {
      .outline-1 {
        font-size: 48px;
        -webkit-text-stroke: 2px #cdf1ea;
        top: -3px;
        left: 136px;
        z-index: 3;
      }
      .outline-2 {
        font-size: 48px;
        -webkit-text-stroke: 2px #f1def4;
        top: 0px;
        left: 139px;
        z-index: 2;
      }
    }
    .news-box{
      flex-direction: column;
      .news{
        padding: 0;
      }
      .info-block.box{
        margin-top: 40px;
        margin-bottom: -40px;
        width: 100%;
        padding: 40px 30px;
        h3{
          font-size: 22px;
        }
        .point-number{
          font-size: 70px;
        }
      }
      .info-block.space-bottom {
        margin-bottom: 0px;
      }
    }
  }
  @media all and (max-width: 320px){
    .news-box{
      .info-block.box{
        h3{
          font-size: 18px;
        }
        .point-number{
          font-size: 60px;
        }
      }
      .info-block.space-bottom {
        margin-bottom: 0px;
      }
    }
  }
}
.page-project {
  .filter {
    .radio-button {
      &.type-button {
        width: 100px;
        margin-right: 20px;
        @media all and (max-width: 567px) {
          margin-right: 10px;
        }
        @media all and (max-width: 320px) {
          width: 80px;
        }
      }
  
      label {
        position: relative;
  
        .unread-messages-total {
          display: flex;
          position: absolute;
          width: 26px;
          height: 26px;
          background: red;
          border-radius: 50%;
          right: -10px;
          top: -10px;
          z-index: 1;
          justify-content: center;
          align-items: center;
          color: #fff;
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
  }
}

.page-project.my-pages {
  .section.title {
    padding-right: 0;
  }
  .header {
    .title-bg {
      font-weight: normal;
    }
    h3 {
      color: transparent;
      -webkit-text-stroke: 2px #cdf1ea;
      text-shadow: none;
      font-size: 62px;
      writing-mode: inherit;
      letter-spacing: 0;
      font-weight: normal;
    }
    .outline-1 {
      -webkit-text-stroke: 2px #cdf1ea;
      top: -20px;
      left: 170px;
      z-index: 3;
    }
    .outline-2 {
      -webkit-text-stroke: 2px #f1def4;
      top: -17px;
      left: 173px;
      z-index: 2;
    }
  }
  .news-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    .news {
      padding: 0 45px 0 15px;
      width: 100%;
      .news-content {
        .row:first-child .info {
          border-top-width: 2px;
        }
        .row {
          .info {
            display: flex;
            .title {
              margin-left: 0;
              padding-left: 20px;
              padding-right: 0;
            }
          }
        }
      }
      .news-content.min-height {
        min-height: 190px;
      }
    }

    .info-block.box {
      box-shadow: -4px -4px 0px 0px rgb(84 219 194 / 35%), 4px 4px 0px 0px rgb(200 123 210 / 35%);
      border-radius: 8px;
      background: #fff;
      padding: 50px 30px;
      text-align: center;
      width: 486px;
      flex-shrink: 0;
      h3 {
        font-size: 22px;
        font-family: 'Noto Sans JP';
        font-weight: bold;
      }
      .point-number {
        font-size: 70px;
        font-weight: bold;
        margin-bottom: 0;
        span {
          font-size: 22px;
          margin-left: 10px;
        }
      }
    }
    .info-block.padding-box {
      padding: 62px 30px;
    }
    .info-block.padding-box-small {
      padding: 8px 76px;
    }
  }
  @media all and (max-width: 1366px) {
    .news-box{
      .info-block.box{
        width: 300px;
        padding: 60px 30px;
        h3{
          font-size: 18px;
          margin-bottom: 20px;
        }
        .point-number{
          font-size: 60px;
        }
      }
      .info-block.padding-box {
        padding: 58px 30px;
      }
      .info-block.padding-box-small {
        padding: 8px 76px;
      }
    }
  }
  @media all and (max-width: 1024px) {
    .news-box{
      .info-block.box{
        width: 300px;
        padding: 60px 30px;
        h3{
          font-size: 18px;
          margin-bottom: 20px;
        }
        .point-number{
          font-size: 60px;
        }
      }
      .info-block.padding-box {
        padding: 58px 30px;
      }
      .info-block.padding-box-small {
        padding: 8px 76px;
      }
    }
  }
  @media all and (max-width: 768px) {
    .news-box{
      flex-direction: column;
      .news{
        padding: 0;
      }
      .info-block.box{
        margin-top: 40px;
        margin-bottom: -40px;
        width: 100%;
        padding: 40px 30px;
        h3{
          font-size: 22px;
        }
        .point-number{
          font-size: 70px;
        }
      }
      .info-block.space-bottom {
        margin-bottom: 0px;
      }
    }
  }
  @media all and (max-width: 575px){
    .header {
      .outline-1 {
        font-size: 48px;
        -webkit-text-stroke: 2px #cdf1ea;
        top: -3px;
        left: 136px;
        z-index: 3;
      }
      .outline-2 {
        font-size: 48px;
        -webkit-text-stroke: 2px #f1def4;
        top: 0px;
        left: 139px;
        z-index: 2;
      }
    }
    .news-box{
      flex-direction: column;
      .news{
        padding: 0;
      }
      .info-block.box{
        margin-top: 40px;
        margin-bottom: -40px;
        width: 100%;
        padding: 40px 30px;
        h3{
          font-size: 22px;
        }
        .point-number{
          font-size: 70px;
        }
      }
      .info-block.space-bottom {
        margin-bottom: 0px;
      }
    }
		.section {
			margin-top: 2.5rem !important;
			padding-top: 0 !important;
		}
  }
  @media all and (max-width: 320px){
    .news-box{
      .info-block.box{
        h3{
          font-size: 18px;
        }
        .point-number{
          font-size: 60px;
        }
      }
      .info-block.space-bottom {
        margin-bottom: 0px;
      }
    }
  }
}
