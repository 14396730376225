.select-npo_and_company-page {
  .card-galaxy {
    .card-galaxy-body {
      padding: 25px;
      .item-title {
        font: normal normal bold 20px/20px "Noto Sans JP";
        .icon-svg {
          width: 46px;
          height: 46px;
          vertical-align: middle;
        }
      }
      .item-sub-title {
        font: normal normal normal 18px/16px "Noto Sans JP";
        color: #484848;
        font-weight: bold;
      }
      .leave_management {
        font-size: 14px;
      }
    }
  }
  @media (max-width: 767px) {
    .card-galaxy {
      .card-galaxy-body {
        padding: 10px;
        .item-title {
          font: normal normal bold 16px/16px "Noto Sans JP";
          .icon-svg {
            width: 30px;
            height: 30px;
          }
        }
        .item-sub-title {
          font: normal normal normal 16px/20px "Noto Sans JP";
        }
        .item-share {
          padding: 0;
          word-wrap: break-word;
        }
      }
    }
  }
}
