.table-responsive {
  .table> :not(caption)>*>* {
    white-space: nowrap;
  }
}

.table-reward {
  margin-top: 46px;

  .detail {
    cursor: pointer;

    .margin-right {
      padding-right: 15px;
    }
  }
}

.admin-table-custom-1 {
  border-color: #cdd6dd;
  color: #3b4043;
  font-size: 14px;
  line-height: 34px;

  thead th {
    font-weight: normal;
  }

  .border-top {
    border-top-color: #98a6b5 !important;
  }

  tr>th:first-child,
  tr>td:first-child {
    color: #707f89;
  }

  td.title-col {
    white-space: unset;

    &>p {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      height: 24px;
      margin-bottom: 0;
    }
  }

  .full-row__link {
    position: absolute;
    left: 0;    
    top: 0;
    width: 100%;
    height: 100%;
  }
}