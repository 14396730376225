.management-user-tab {
  .nav-tabs {
    --bs-nav-tabs-border-width: 0;
    padding-bottom: 20px;
    border-bottom: 4px solid #f4f9f8;
  }
  .nav-link {
    font: normal normal normal 16px/30px Source Han Sans JP;
    letter-spacing: 0px;
    color: #4f4f4f;
    padding: 0;
    margin-right: 47px;
  }
  .nav-link.active {
    position: relative;
    &:after {
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      bottom: -25px;
      height: 4px;
      background: #54dbc2;
    }
  }
}
.influencer-reward-modal {
  .info {
    .info-header {
      .avatar {
        width: 150px;
        height: 150px;
        border: 1px solid #707070;
        border-radius: 50%;
      }
      .reward-price {
        top: calc(50% - 25px);
        h2 {
          font-size: 16px;
          color: #919191;
        }
        h3 {
          font-size: 24px;
          color: #4f4f4f;
        }
      }
    }
    .info-body {
      .info-body-content {
        margin-bottom: 15px;
        font-size: 16px;
        .label {
          color: #919191;
        }
        .content {
          color: #4f4f4f;
        }
      }
    }
  }
  .info-image {
    padding-left: 34px;
    width: 100%;
    img {
      width: 100%;
    }
  }
  .form-update {
    button {
      font-size: 16px;
      line-height: 30px;
      color: #ffffff;
      padding: 10px 45px;
    }
  }
}
